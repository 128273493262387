import React from "react";
import "../components.css";
import {
  clearFuelPrice,
  setFuelPricesFilter,
  clearFuelPriceBatch,
  selectFuelPrice,
  removeFuelPriceFromSelectedBatch,
  addFuelPriceToSelectedBatch,
  addAllFuelPricesToSelectedBatch,
  deleteFuelPrices,
  patchFuelPriceInDb,
  updateFuelPricesState,
  getFuelPrices,
  clearFuelPrices
} from "../../actions";
import { connect } from "react-redux";
import { compileFuelPriceObject } from "../../apis/FuelPrices";
import _ from "lodash";
import { EDIT, NEW, DELETE } from "../../actions/types";
import { Link } from "react-router-dom";
import { Confirm } from "semantic-ui-react";
import history from "../../history";
import DisplayTable from "../displayTable";
import { isMobile } from "react-device-detect";
import { removeObjectFromArrayById, filterArrayOnConcatenatedKeys } from "../../utilities";
import { SET_FUELPRICE_FILTER_LABEL } from "../../actions/types";

class FuelPrices extends React.Component {
  state = {
    confirmDeleteOpen: false,
    fuelPriceDeleteId: null
  };

  componentDidMount() {
    if (this.props.fuelPrices.length === 0) {
      this.props.getFuelPrices({});
    }
  }

  onFuelPricesFilterChange = (filter) => {
    const {
      clearFuelPrice,
      fuelPriceSearchTerms,
      setFuelPricesFilter,
      updateFuelPricesState,
      allFuelPrices
    } = this.props;
    const _filter = filter !== undefined ? filter : fuelPriceSearchTerms.filter.label;
    if (_filter !== fuelPriceSearchTerms.filter.value) {
      clearFuelPrice();
      if (_filter !== "") {
        let _fuelPrices = [...allFuelPrices];
        const _filteredFuelPrices = [
          ...filterArrayOnConcatenatedKeys(_fuelPrices, ["l_name"], _filter)
        ];
        updateFuelPricesState(_filteredFuelPrices);
      } else {
        updateFuelPricesState([...allFuelPrices]);
      }
    }
    setFuelPricesFilter(_filter);
  };

  onSelectFuelPrice = (id) => {
    const { selectFuelPrice, fuelPrices, clearFuelPriceBatch } = this.props;
    selectFuelPrice(fuelPrices.find((fuelPrice) => fuelPrice.id === id));
    clearFuelPriceBatch();
  };

  onAddFuelPriceToBatch = (fuelPrice) => {
    if (this.props.fuelPriceBatch.find((item) => item === fuelPrice)) {
      this.props.removeFuelPriceFromSelectedBatch(fuelPrice);
    } else {
      this.props.addFuelPriceToSelectedBatch(fuelPrice);
    }
  };

  onAddAllFuelPricesToSelectedBatch = () => {
    if (this.props.fuelPriceBatch.length === this.props.fuelPrices.length) {
      this.props.clearFuelPriceBatch();
    } else {
      this.props.addAllFuelPricesToSelectedBatch(this.props.fuelPrices);
    }
  };

  onBatchDeleteFuelPrices = () => {
    const { fuelPrices, fuelPriceBatch, deleteFuelPrices, clearFuelPriceBatch, clearFuelPrice } =
      this.props;
    if (fuelPriceBatch.length > 0) {
      deleteFuelPrices(fuelPrices, fuelPriceBatch);
    }
    clearFuelPriceBatch();
    clearFuelPrice();
  };

  onDeleteFuelPrice = () => {
    const { clearFuelPrice, updateFuelPricesState } = this.props;
    let _fuelPrice = { ...this.props.selectedFuelPrice };
    //set to inactive
    _fuelPrice.active = false;
    this.props.patchFuelPriceInDb(_fuelPrice);
    clearFuelPrice();
    updateFuelPricesState(removeObjectFromArrayById(this.props.fuelPrices, _fuelPrice));
  };

  updateFuelPrice = (fuelPrice) => {
    const { patchFuelPriceInDb } = this.props;
    let _fuelPrice = compileFuelPriceObject(
      {
        name: fuelPrice.name
      },
      fuelPrice.id,
      fuelPrice.active
    );
    patchFuelPriceInDb(_fuelPrice);
  };

  //called from tables main menu to do ad-hoc save.
  onSaveChanges = () => {
    const _fuelPrice = { ...this.props.selectedFuelPrice };
    if (!_.isEmpty(_fuelPrice)) {
      this.updateFuelPrice(_fuelPrice);
    }
  };

  renderFuelPricesTable() {
    const menuItems = [
      {
        name: "New Price",
        key: NEW,
        icon: "add square",
        to: `/fuelprices/create`,
        type: Link,
        disabled: false
      }
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit Price",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId }) => {
          history.push(`/fuelprices/edit/${itemId}`);
        }
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: ({ itemId }) => {
          this.setState({
            fuelPriceDeleteId: itemId,
            confirmDeleteOpen: true
          });
        }
      }
    ];

    const tableMenuActions = [
      {
        key: DELETE,
        confirmation: 1,
        text: "Delete",
        value: DELETE,
        icon: { name: "remove", color: "red" },
        idx: 0,
        action: this.onBatchDeleteFuelPrices,
        header: "Confirm Delete"
      }
    ];

    const gridColumns = "three";
    const tableColumns = [
      {
        width: "four",
        heading: "Country",
        displayValues: [{ columnName: "country" }]
      },
      {
        width: "three",
        heading: "Fuel Type",
        displayValues: [{ columnName: "fueltypName" }]
      },
      {
        width: "three",
        heading: "Price",
        displayValues: [{ columnName: "price" }]
      }
    ];

    const primaryColumn = {
      heading: "Price ID",
      width: "three",
      displayValues: [{ columnName: "id" }]
    };

    const copyConfig = {
      active: false
    };

    //check if "model panels message must be displayed"

    return (
      <>
        <DisplayTable
          idFieldName="id"
          primaryHeading="Fuel Prices"
          items={this.props.fuelPrices}
          selectedItemId={this.props.selectedFuelPrice.id}
          menuItems={menuItems}
          tableMenuActions={tableMenuActions}
          itemMenuActions={itemMenuActions}
          showSearch={0}
          showSearchFilter={true}
          searchTerms={this.props.fuelPriceSearchTerms}
          onSelectItem={this.onSelectFuelPrice}
          onSearchTermChange={() => {
            return;
          }}
          onFilterChange={this.onFuelPricesFilterChange}
          filterAction={SET_FUELPRICE_FILTER_LABEL}
          batchItems={this.props.fuelPriceBatch}
          onAddItemToBatch={this.onAddFuelPriceToBatch}
          onAddAllItemsToBatch={this.onAddAllFuelPricesToSelectedBatch}
          primaryColumn={primaryColumn}
          gridColumns={gridColumns}
          tableColumns={tableColumns}
          loading={this.props.loadingData}
          copyItemsConfig={copyConfig}></DisplayTable>
      </>
    );
  }

  renderDeleteConfirm() {
    return (
      <Confirm
        open={this.state.confirmDeleteOpen}
        header="Item Actions"
        content={`Confirm Delete?`}
        onCancel={() => {
          this.setState({ confirmDeleteOpen: false });
        }}
        onConfirm={() => {
          this.setState({ confirmDeleteOpen: false });
          this.onDeleteFuelPrice();
        }}></Confirm>
    );
  }

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <>
        <div className={className}>
          <div>{this.renderFuelPricesTable()}</div>
          {this.renderDeleteConfirm()}
          <div className="spacer-very-wide"></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fuelPriceSearchTerms: state.fuelprices.fuelPriceSearchTerms,
    fuelPrices: state.fuelprices.fuelPrices,
    allFuelPrices: state.fuelprices.allFuelPrices,
    fuelPriceBatch: state.fuelprices.fuelPriceBatch,
    selectedFuelPrice: state.fuelprices.selectedFuelPrice,
    loadingData: state.api.loadingData
  };
};

export default connect(mapStateToProps, {
  clearFuelPrice,
  clearFuelPrices,
  setFuelPricesFilter,
  clearFuelPriceBatch,
  selectFuelPrice,
  removeFuelPriceFromSelectedBatch,
  addFuelPriceToSelectedBatch,
  addAllFuelPricesToSelectedBatch,
  deleteFuelPrices,
  patchFuelPriceInDb,
  updateFuelPricesState,
  getFuelPrices
})(FuelPrices);
