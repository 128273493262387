import React from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import SessionManager from "../session/SessionManager";
import userPool from "./AWSUserPool";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Form, Icon, Input } from "semantic-ui-react";
import {
  signIn,
  signOut,
  failSignIn,
  setUserFirstName,
  setResetUsername,
  resetSignIn,
} from "../../actions";
import history from "../../history";
import "../components.css";
import { Link } from "react-router-dom";
import { env } from "../../apis/env";

class AWSSignIn extends React.Component {
  //get redirect timeout from env variables.
  _env = env();

  //local state to handle password reset - user must input email.
  state = {
    usernameEntered: "",
    forgotPasswordClicked: false,
    requestResetSuccessMessage: "",
    requestResetFailMessage: "",
    redirectIn: this._env.REDIRECT_TIMEOUT / 1000,
    passwordVisible: false,
    passwordToggleIcon: "eye",
  };

  onUsernameInputChange = (e, data) => {
    this.setState({ usernameEntered: data, forgotPasswordClicked: false });
    this.props.resetSignIn();
  };

  onPasswordInputChange = (e, data) => {
    this.props.resetSignIn();
  };

  renderResetPasswordError() {
    if (this.state.usernameEntered === "" && this.state.forgotPasswordClicked)
      return (
        <div className="ui negative message">
          <div>Please enter your email address</div>
        </div>
      );
  }

  renderResetPasswordSuccess() {
    if (this.state.requestResetSuccessMessage)
      return (
        <div className="ui positive message">
          <div>
            A verification code was sent to{" "}
            {this.state.requestResetSuccessMessage}
          </div>
          <div>Routing in {this.state.redirectIn}</div>
        </div>
      );
  }

  renderResetPasswordFailure() {
    if (this.state.requestResetFailMessage)
      return (
        <div className="ui negative message">
          <div>{this.state.requestResetFailMessage}</div>
        </div>
      );
  }

  componentDidMount() {
    const lastLoginBy = localStorage.getItem("lastLoginUser");
    document.getElementById("emailInput").value = lastLoginBy;
    this.setState({ usernameEntered: lastLoginBy });
    //check if user logged in in browser local storage
    const authData = SessionManager.checkAuth();
    if (authData.email) {
      this.props.signIn(authData.userId, authData.email, authData.authorizor);
      this.props.setUserFirstName(authData.firstName);
      history.push("/dashboard");
    }
  }

  renderError = ({ submitFailed, error }) => {
    if (submitFailed && error) {
      return (
        <div className="ui negative message">
          <div>{error}</div>
        </div>
      );
    }
  };

  renderSignInError(error) {
    if (error) {
      return <div className="ui negative message">{error}</div>;
    }
    return "";
  }

  onSubmit = (formValues) => {
    const email = formValues.email;

    const user = new CognitoUser({
      Username: formValues.email,
      Pool: userPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: formValues.email,
      Password: formValues.password,
    });

    const setFirstName = (firstName) => {
      this.props.setUserFirstName(firstName);
      localStorage.setItem("firstName", firstName);
    };

    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        const userId = result.getIdToken().payload["cognito:username"];
        user.getUserAttributes(function (err, result) {
          if (err) {
            alert(err.message || JSON.stringify(err));
            this.props.setUserFirstName("User");
            return;
          }
          for (var i = 0; i < result.length; i++) {
            if (result[i].getName() === "name") {
              setFirstName(result[i].getValue());
            }
          }
        });

        this.props.signIn(userId, email, "COGNITO");
        SessionManager.startSession(userId, email, "COGNITO");
        history.push("/dashboard");
      },
      onFailure: (result) => {
        this.props.failSignIn(result.message);
      },
    });
  };

  forgotPasswordClick = () => {
    //send code to email and route to reset password view
    this.setState({ forgotPasswordClicked: true });
    this.setState({ requestResetFailMessage: "" });
    const timeDelay = process.env.REACT_APP_REDIRECT_TIMEOUT;

    if (this.state.usernameEntered !== "" && !!this.state.usernameEntered) {
      this.props.setResetUsername(this.state.usernameEntered);
      const userData = {
        Username: this.state.usernameEntered,
        Pool: userPool,
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          this.setState({
            requestResetSuccessMessage: data.CodeDeliveryDetails.Destination,
          });

          var timeLeft = timeDelay / 1000;
          var countDown = setInterval(() => {
            if (timeLeft <= 0) {
              clearInterval(countDown);
            }
            this.setState({ redirectIn: timeLeft }, () => {
              timeLeft -= 1;
            });
          }, 1000);
          setTimeout(() => {
            history.push('/reset');
          }, timeDelay);
        },
        onFailure: (data) => {
          console.log(data.message);
          this.setState(
            this.setState({ requestResetFailMessage: data.message })
          );
        },
      });
    } else {
      this.setState({
        requestResetFailMessage:
          "Please enter the email address you used to sign up. IMPORTANT: If you used Google to sign into the system previously, you cannot use this application to reset your password as your credentials are externally managed by Google.",
      });
    }
  };

  renderInput = ({
    input,
    type,
    meta,
    placeholder,
    id,
    autoFocus,
    text,
    icon
  }) => {
    return (
      <div>
        <Input
          type={type}
          fluid
          {...input}
          id={id}
          placeholder={placeholder}
          autoFocus={autoFocus}
          value={text}
          icon={icon}
        ></Input>
        {this.renderError(meta, id)}
      </div>
    );
  };

  onShowPasswordClick = () => {
    this.setState({
      passwordVisible: !this.state.passwordVisible,
      passwordToggleIcon:
        this.state.passwordToggleIcon === "eye" ? "eye slash" : "eye",
    });
  };

  render() {
    return (
      <div>
        <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Form.Field>
            <Field
              name="email"
              type="email"
              component={this.renderInput}
              id="emailInput"
              placeholder="Email Address"
              autoFocus
              onChange={this.onUsernameInputChange}
              text={this.props.userName}
            />
          </Form.Field>
          <Form.Field>
            <Field
              name="password"
              type={this.state.passwordVisible ? "text" : "password"}
              component={this.renderInput}
              id="passwordInput"
              placeholder="Password"
              onChange={this.onPasswordInputChange}
              icon={
                <Icon
                  name={this.state.passwordToggleIcon}
                  onClick={this.onShowPasswordClick}
                  color="blue"
                  inverted
                  bordered
                  link
                />
              }
            />
          </Form.Field>
          <Form.Field>
            <Button
              fluid
              loading={this.props.signingUp}
              primary
              icon
              labelPosition="right"
            >
              Sign In
              <Icon name="sign in"></Icon>
            </Button>
          </Form.Field>
          <Form.Field>
            <Button
              className="compact"
              type="button"
              onClick={this.forgotPasswordClick}
              icon
            >
              Forgot Password
              <Icon name="question"></Icon>
            </Button>
            <Link className="ui compact button" to="/signup" style={{display:"none"}}>
              Sign up
            </Link>
          </Form.Field>
        </Form>
        {this.renderSignInError(this.props.signinError)}
        {this.renderResetPasswordError()}
        {this.renderResetPasswordSuccess()}
        {this.renderResetPasswordFailure()}
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.email) {
    //keys of object must match field names
    errors.email = "Please enter your email address";
  }
  if (!formValues.password) {
    //keys of object must match field names
    errors.password = "Please enter your password";
  }
  return errors;
};

const mapStateToProps = (state) => {
  return {
    formActions: state.formActions,
    isSignedIn: state.auth.isSignedIn,
    signinError: state.auth.signinError,
    userName: state.auth.passwordResetUsername,
  };
};

const formWrapped = reduxForm({
  form: "AWSSignIn",
  validate: validate,
})(AWSSignIn);

export default connect(mapStateToProps, {
  signIn,
  signOut,
  failSignIn,
  setUserFirstName,
  setResetUsername,
  resetSignIn,
})(formWrapped);
