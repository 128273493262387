import React from "react";
import "../components.css";
import {
  clearEffect,
  setEffectsFilter,
  clearEffectBatch,
  selectEffect,
  removeEffectFromSelectedBatch,
  addEffectToSelectedBatch,
  addAllEffectsToSelectedBatch,
  deleteEffects,
  deleteEffect,
  patchEffectInDb,
  updateEffectsState,
  updateAllEffectsState,
  getEffects,
  clearEffects,
} from "../../actions";
import { connect } from "react-redux";
import { compileEffectObject } from "../../apis/Effects";
import _ from "lodash";
import { SAVE, EDIT, NEW, DELETE } from "../../actions/types";
import { Link } from "react-router-dom";
import { Button, Confirm } from "semantic-ui-react";
import history from "../../history";
import DisplayTable from "../displayTable";
import { isMobile } from "react-device-detect";
import { removeObjectFromArrayById, filterArray } from "../../utilities";
import { SET_EFFECT_FILTER_LABEL } from "../../actions/types";

class Effects extends React.Component {
  state = {
    confirmDeleteOpen: false,
    effectDeleteId: null,
  };

  componentDidMount() {
    if (this.props.effects.length === 0) {
      this.props.getEffects({});
    }
  }

  onEffectsFilterChange = (filter) => {
    const { clearEffect, effectSearchTerms, setEffectsFilter, updateEffectsState, allEffects } =
      this.props;

    const _newfilter = filter === "#clear#" ? "" : effectSearchTerms.filter.label;

    if (_newfilter !== effectSearchTerms.filter.value) {
      clearEffect();

      if (_newfilter !== "") {
        updateEffectsState(filterArray(allEffects, "l_name", _newfilter));
      } else {
        updateEffectsState(allEffects);
      }
    }
    setEffectsFilter(_newfilter);
  };

  onSelectEffect = (id) => {
    const { selectEffect, effects, clearEffectBatch } = this.props;
    selectEffect(effects.find((effect) => effect.id === id));
    clearEffectBatch();
  };

  onAddEffectToBatch = (effect) => {
    if (this.props.effectBatch.find((item) => item === effect)) {
      this.props.removeEffectFromSelectedBatch(effect);
    } else {
      this.props.addEffectToSelectedBatch(effect);
    }
  };

  onAddAllEffectsToSelectedBatch = () => {
    if (this.props.effectBatch.length === this.props.effects.length) {
      this.props.clearEffectBatch();
    } else {
      this.props.addAllEffectsToSelectedBatch(this.props.effects);
    }
  };

  onBatchDeleteEffects = () => {
    const { effects, effectBatch, deleteEffects, clearEffectBatch, clearEffect, allEffects } =
      this.props;
    if (effectBatch.length > 0) {
      deleteEffects(effects, effectBatch, allEffects);
    }
    clearEffectBatch();
    clearEffect();
  };

  onDeleteEffect = () => {
    const {
      clearEffect,
      updateEffectsState,
      selectedEffect,
      effects,
      allEffects,
      updateAllEffectsState,
    } = this.props;
    let _effect = { ...selectedEffect };
    //set to inactive
    _effect.active = false;
    this.updateEffect(_effect);
    clearEffect();
    updateEffectsState(removeObjectFromArrayById(effects, _effect));
    updateAllEffectsState(removeObjectFromArrayById(allEffects, _effect));
  };

  updateEffect = (effect) => {
    const { patchEffectInDb } = this.props;
    let _effect = compileEffectObject(
      {
        name: effect.name,
        stages: effect.stages,
      },
      effect.id,
      effect.active
    );
    patchEffectInDb(_effect);
  };

  //called from tables main menu to do ad-hoc save.
  onSaveChanges = () => {
    const _effect = { ...this.props.selectedEffect };
    if (!_.isEmpty(_effect)) {
      this.updateEffect(_effect);
    }
  };

  renderEffectsTable() {
    const menuItems = [
      {
        name: "Save Changes",
        key: SAVE,
        icon: "save",
        onClick: this.onSaveChanges,
        type: Button,
        disabled: this.props.effects.length === 0,
      },
      {
        name: "New Effect",
        key: NEW,
        icon: "add square",
        to: `/effects/create`,
        type: Link,
        disabled: false,
      },
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit Effect",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId }) => {
          history.push(`/effects/edit/${itemId}`);
        },
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: ({ itemId }) => {
          this.setState({ effectDeleteId: itemId, confirmDeleteOpen: true });
        },
      },
    ];

    const tableMenuActions = [
      {
        key: DELETE,
        confirmation: 1,
        text: "Delete",
        value: DELETE,
        icon: { name: "remove", color: "red" },
        idx: 0,
        action: this.onBatchDeleteEffects,
        header: "Confirm Delete",
      },
    ];

    const gridColumns = "six";
    const tableColumns = [
      {
        width: "eight",
        heading: "Applicable Stages (Activities)",
        displayValues: [{ columnName: "stagesDisplay.name" }],
      },
    ];

    const primaryColumn = {
      heading: "Effect Name",
      width: "three",
      displayValues: [{ columnName: "name" }],
    };

    const copyConfig = {
      active: false,
    };

    //check if "model panels message must be displayed"

    return (
      <>
        <DisplayTable
          idFieldName="id"
          primaryHeading="Paint Effects"
          items={this.props.effects}
          selectedItemId={this.props.selectedEffect.id}
          menuItems={menuItems}
          tableMenuActions={tableMenuActions}
          itemMenuActions={itemMenuActions}
          showSearch={0}
          showSearchFilter={true}
          searchTerms={this.props.effectSearchTerms}
          onSelectItem={this.onSelectEffect}
          onSearchTermChange={() => {
            return;
          }}
          onFilterChange={this.onEffectsFilterChange}
          filterAction={SET_EFFECT_FILTER_LABEL}
          batchItems={this.props.effectBatch}
          onAddItemToBatch={this.onAddEffectToBatch}
          onAddAllItemsToBatch={this.onAddAllEffectsToSelectedBatch}
          primaryColumn={primaryColumn}
          gridColumns={gridColumns}
          tableColumns={tableColumns}
          loading={this.props.loadingData}
          copyItemsConfig={copyConfig}
        ></DisplayTable>
      </>
    );
  }

  renderDeleteConfirm() {
    return (
      <Confirm
        open={this.state.confirmDeleteOpen}
        header="Item Actions"
        content={`Confirm Delete?`}
        onCancel={() => {
          this.setState({ confirmDeleteOpen: false });
        }}
        onConfirm={() => {
          this.setState({ confirmDeleteOpen: false });
          this.onDeleteEffect();
        }}
      ></Confirm>
    );
  }

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <>
        <div className={className}>
          <div className="ui info message">
            <div class="header">Usage Instructions</div>
            <ul class="list">
              <li>
                Used to control the paint activities that may be used for each effect type when
                capturing a paint formula.
              </li>
              <li>
                <b>
                  Only activities specified will be available for selection for a specific effect
                </b>
              </li>
            </ul>
          </div>
          <div>{this.renderEffectsTable()}</div>
          {this.renderDeleteConfirm()}
          <div className="spacer-very-wide"></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    effectSearchTerms: state.effects.effectSearchTerms,
    allEffects: state.effects.allEffects,
    effects: state.effects.effects,
    effectBatch: state.effects.effectBatch,
    selectedEffect: state.effects.selectedEffect,
    loadingData: state.api.loadingData,
  };
};

export default connect(mapStateToProps, {
  clearEffect,
  clearEffects,
  setEffectsFilter,
  clearEffectBatch,
  selectEffect,
  removeEffectFromSelectedBatch,
  addEffectToSelectedBatch,
  addAllEffectsToSelectedBatch,
  deleteEffects,
  deleteEffect,
  patchEffectInDb,
  updateEffectsState,
  updateAllEffectsState,
  getEffects,
})(Effects);
