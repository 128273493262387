import React from "react";
import "../components.css";
import {
  clearLocation,
  setLocationsFilter,
  clearLocationBatch,
  selectLocation,
  removeLocationFromSelectedBatch,
  addLocationToSelectedBatch,
  addAllLocationsToSelectedBatch,
  deleteLocations,
  patchLocationInDb,
  updateLocationsState,
  updateAllLocationsState,
  getLocations,
  clearLocations,
} from "../../actions";
import { connect } from "react-redux";
import { compileLocationObject } from "../../apis/Locations";
import _ from "lodash";
import { SAVE, EDIT, NEW, DELETE } from "../../actions/types";
import { Link } from "react-router-dom";
import { Button, Confirm } from "semantic-ui-react";
import history from "../../history";
import DisplayTable from "../displayTable";
import { isMobile } from "react-device-detect";
import {
  removeObjectFromArrayById,
  filterArray,
  arrayDelta,
} from "../../utilities";
import { SET_LOCATION_FILTER_LABEL } from "../../actions/types";

class Locations extends React.Component {
  state = {
    confirmDeleteOpen: false,
    locationDeleteId: null,
  };

  componentDidMount() {
    if (this.props.locations.length === 0) {
      this.props.getLocations({});
    }
  }

  onLocationsFilterChange = (filter) => {
    const {
      clearLocation,
      locationSearchTerms,
      setLocationsFilter,
      updateLocationsState,
      allLocations,
    } = this.props;

    const _newfilter = filter === "#clear#" ? "" : locationSearchTerms.filter.label;

    if (_newfilter !== locationSearchTerms.filter.value) {
      clearLocation();

      if (_newfilter !== "") {
        updateLocationsState(filterArray(allLocations, "l_name", _newfilter));
      } else {
        updateLocationsState(allLocations);
      }
    }
    setLocationsFilter(_newfilter);

  };

  onSelectLocation = (id) => {
    const { selectLocation, locations, clearLocationBatch } =
      this.props;
    selectLocation(
      locations.find((location) => location.id === id)
    );
    clearLocationBatch();
  };

  onAddLocationToBatch = (location) => {
    if (this.props.locationBatch.find((item) => item === location)) {
      this.props.removeLocationFromSelectedBatch(location);
    } else {
      this.props.addLocationToSelectedBatch(location);
    }
  };

  onAddAllLocationsToSelectedBatch = () => {
    if (
      this.props.locationBatch.length === this.props.locations.length
    ) {
      this.props.clearLocationBatch();
    } else {
      this.props.addAllLocationsToSelectedBatch(this.props.locations);
    }
  };

  onBatchDeleteLocations = () => {
    const {
      locations,
      locationBatch,
      deleteLocations,
      clearLocationBatch,
      clearLocation,
      updateLocationsState,
      updateAllLocationsState,
      allLocations
    } = this.props;
    if (locationBatch.length > 0) {
      deleteLocations(locationBatch);
    }
    clearLocationBatch();
    clearLocation();
    updateLocationsState(arrayDelta(locations, locationBatch));
    updateAllLocationsState(arrayDelta(allLocations, locationBatch));
  };

  onDeleteLocation = () => {
    const {locations, allLocations, clearLocation, updateLocationsState, updateAllLocationsState } = this.props;
    let _location = { ...this.props.selectedLocation };
    //set to inactive
    _location.active = false;
    this.updateLocation(_location);
    clearLocation();
    updateLocationsState(
      removeObjectFromArrayById(locations, _location)
    );
    updateAllLocationsState(removeObjectFromArrayById(allLocations, _location));
  };

  updateLocation = (location) => {
    const { patchLocationInDb } = this.props;
    let _location = compileLocationObject(
      {
        name: location.name,
      },
      location.id,
      location.active
    );
    patchLocationInDb(_location);
  };

  //called from tables main menu to do ad-hoc save.
  onSaveChanges = () => {
    const _location = { ...this.props.selectedLocation };
    if (!_.isEmpty(_location)) {
      this.updateLocation(_location);
    }
  };

  renderLocationsTable() {
    const menuItems = [
      {
        name: "Save Changes",
        key: SAVE,
        icon: "save",
        onClick: this.onSaveChanges,
        type: Button,
        disabled: this.props.locations.length === 0,
      },
      {
        name: "New",
        key: NEW,
        icon: "add square",
        to: `/locations/create`,
        type: Link,
        disabled: false,
      },
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit Location",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId }) => {
          history.push(`/locations/edit/${itemId}`);
        },
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: ({ itemId }) => {
          this.setState({
            locationDeleteId: itemId,
            confirmDeleteOpen: true,
          });
        },
      },
    ];

    const tableMenuActions = [
      {
        key: DELETE,
        confirmation: 1,
        text: "Delete",
        value: DELETE,
        icon: { name: "remove", color: "red" },
        idx: 0,
        action: this.onBatchDeleteLocations,
        header: "Confirm Delete",
      },
    ];

    const gridColumns = "one";
    const tableColumns = [];

    const primaryColumn = {
      heading: "Location Name",
      width: "ten",
      displayValues: [{ columnName: "name" }],
    };

    const copyConfig = {
      active: false,
    };

    //check if "model panels message must be displayed"

    return (
      <>
        <DisplayTable
          idFieldName="id"
          primaryHeading="Paint Code Locations"
          items={this.props.locations}
          selectedItemId={this.props.selectedLocation.id}
          menuItems={menuItems}
          tableMenuActions={tableMenuActions}
          itemMenuActions={itemMenuActions}
          showSearch={0}
          showSearchFilter={true}
          searchTerms={this.props.locationSearchTerms}
          onSelectItem={this.onSelectLocation}
          onSearchTermChange={() => {
            return;
          }}
          onFilterChange={this.onLocationsFilterChange}
          filterAction={SET_LOCATION_FILTER_LABEL}
          batchItems={this.props.locationBatch}
          onAddItemToBatch={this.onAddLocationToBatch}
          onAddAllItemsToBatch={this.onAddAllLocationsToSelectedBatch}
          primaryColumn={primaryColumn}
          gridColumns={gridColumns}
          tableColumns={tableColumns}
          loading={this.props.loadingData}
          copyItemsConfig={copyConfig}
        ></DisplayTable>
      </>
    );
  }

  renderDeleteConfirm() {
    return (
      <Confirm
        open={this.state.confirmDeleteOpen}
        header="Item Actions"
        content={`Confirm Delete?`}
        onCancel={() => {
          this.setState({ confirmDeleteOpen: false });
        }}
        onConfirm={() => {
          this.setState({ confirmDeleteOpen: false });
          this.onDeleteLocation();
        }}
      ></Confirm>
    );
  }

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <>
        <div className={className}>
          <div>{this.renderLocationsTable()}</div>
          {this.renderDeleteConfirm()}
          <div className="spacer-very-wide"></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locationSearchTerms: state.locations.locationSearchTerms,
    locations: state.locations.locations,
    allLocations: state.locations.allLocations,
    locationBatch: state.locations.locationBatch,
    selectedLocation: state.locations.selectedLocation,
    loadingData: state.api.loadingData,
  };
};

export default connect(mapStateToProps, {
  clearLocation,
  clearLocations,
  setLocationsFilter,
  clearLocationBatch,
  selectLocation,
  removeLocationFromSelectedBatch,
  addLocationToSelectedBatch,
  addAllLocationsToSelectedBatch,
  deleteLocations,
  patchLocationInDb,
  updateLocationsState,
  updateAllLocationsState,
  getLocations,
})(Locations);
