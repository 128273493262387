import React from "react";
import "../components.css";
import {
  setPayTransactionsFilter,
  selectPayTransaction,
  activateCreditsInDb,
  updatePayTransactionsState,
  getPayTransactions,
  clearPayTransactions,
  setPayTransactionSearchTerm,
  clearPayTransaction,
  getAccountUsers,
  getPopSignedUrlAndUpload,
  resetPopUploadAction,
  getSettings,
  getPopSignedURL
} from "../../actions";
import { connect } from "react-redux";
import { ALLOCATE_PAYMENT, DOWNLOAD_POP } from "../../actions/types";
import DisplayTable from "../displayTable";
import { isMobile } from "react-device-detect";
import { filterArrayOnConcatenatedKeys } from "../../utilities";
import { SET_PAYMENT_TRANSACTION_FILTER_LABEL } from "../../actions/types";
import UploadPOPActivateCreditsForm from "./UploadPOPActivateCreditsForm";
import { toastr } from "react-redux-toastr";
import _ from "lodash";
import { Message, Icon } from "semantic-ui-react";

class PayTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showFileUploadModal: false, companyName: null };
  }

  componentDidMount() {
    if (this.props.accountUsers.length === 0) {
      this.props.getAccountUsers({ owneronly: true, returnemailonly: false });
    }
    if (_.isEmpty(this.props.settings)) {
      this.props.getSettings({});
    }
  }

  onPayTransactionsFilterChange = filter => {
    const {
      clearPayTransactions,
      payTransactionSearchTerms,
      setPayTransactionsFilter,
      updatePayTransactionsState,
      allPayTransactions
    } = this.props;
    const _filter = filter !== undefined ? filter : payTransactionSearchTerms.filter.label;
    if (_filter !== payTransactionSearchTerms.filter.value) {
      clearPayTransactions();
      if (_filter !== "") {
        let _payTransactions = [...allPayTransactions];
        const _filteredPayTransactions = [...filterArrayOnConcatenatedKeys(_payTransactions, ["email"], _filter)];
        updatePayTransactionsState(_filteredPayTransactions);
      } else {
        updatePayTransactionsState([...allPayTransactions]);
      }
    }
    setPayTransactionsFilter(_filter);
  };

  onSelectPayTransaction = id => {
    const { selectPayTransaction, payTransactions } = this.props;
    selectPayTransaction(payTransactions.find(payTransaction => payTransaction.id === id));
  };

  updatePayTransaction = tx => {
    this.setState({ showFileUploadModal: true });
  };

  downloadTxPop = async tx => {
    if (tx.popfilename) {
      const _fileType = tx.popfilename.split(".")[1];
      let _signedUrl = await getPopSignedURL(tx.popfilename, _fileType, "getObject");
      try {
        window.open(_signedUrl.uploadUrl, "_blank");
      } catch (e) {
        toastr.error("Error", `Could not dowload document. ${e.message}`);
      }
    } else {
      toastr.error("No pop file uploaded yet for this transaction");
    }
  };

  onFileUploadModalSubmit = async file => {
    const { payTransactions } = this.props;
    const _fileExtension = file.type.split("/")[1]; //e.g. get pdf from application/pdf
    const _fileName = `${this.props.selectedPayTransaction.id}.${_fileExtension}`;
    const _validdays =
      this.props.settings.app.find(setting => {
        return setting.key === "sign_up_free_weeks";
      }).value * 7;
    await this.props.getPopSignedUrlAndUpload(
      _fileName,
      file,
      this.props.selectedPayTransaction,
      _validdays,
      payTransactions
    );
  };

  onFileUploadModalCancel = () => {
    this.setState({ showFileUploadModal: false });
    this.props.resetPopUploadAction();
  };

  onTonersSearchTermChange = term => {
    const { setPayTransactionSearchTerm, clearPayTransaction, getPayTransactions } = this.props;
    if (term.value !== "") {
      setPayTransactionSearchTerm(term);
      clearPayTransaction();
      if (term.value) {
        getPayTransactions(term.value);
      }
      this.setState({
        companyName: this.props.accountUsers.find(user => {
          return user.email === term.value;
        }).company
      });
    }
  };

  selectOptionsFromData = data => {
    if (data) {
      return data.map(item => {
        return {
          value: item.email,
          label: item.email
        };
      });
    }
  };

  renderPayTransactionsTable() {
    const menuItems = [];

    const itemMenuActions = [
      {
        key: ALLOCATE_PAYMENT,
        text: "Allocate Payment",
        value: ALLOCATE_PAYMENT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: () => {
          this.updatePayTransaction(this.props.selectedPayTransaction);
        }
      },
      {
        key: DOWNLOAD_POP,
        text: "Download POP",
        value: DOWNLOAD_POP,
        icon: { name: "file pdf", color: "red" },
        idx: 1,
        executeAction: () => {
          this.downloadTxPop(this.props.selectedPayTransaction);
        }
      }
    ];

    const tableMenuActions = [];

    const gridColumns = "one";
    const tableColumns = [
      {
        width: "three",
        heading: "Email",
        displayValues: [{ columnName: "email" }]
      },
      {
        width: "two",
        heading: "Create Date",
        displayValues: [{ columnName: "CREATEDATE" }]
      },
      {
        width: "one",
        heading: "Credits",
        displayValues: [{ columnName: "CREDITS" }]
      },
      {
        width: "one",
        heading: "Balance",
        displayValues: [{ columnName: "BALANCE" }]
      },
      {
        width: "two",
        heading: "Auth Code",
        displayValues: [{ columnName: "AUTH_CODE" }]
      }
    ];

    const primaryColumn = {
      heading: "Tx Reference",
      width: "two",
      displayValues: [{ columnName: "id" }]
    };

    const copyConfig = {
      active: false
    };

    //check if "model panels message must be displayed"

    const _head = this.state.companyName ? `Payments for ${this.state.companyName}` : "Client Payments";
    return (
      <DisplayTable
        idFieldName="id"
        primaryHeading={_head}
        items={this.props.payTransactions}
        selectedItemId={this.props.selectPayTransaction.id}
        menuItems={menuItems}
        tableMenuActions={tableMenuActions}
        itemMenuActions={itemMenuActions}
        showSearch={1}
        showSearchFilter={false}
        searchBarOptions={this.selectOptionsFromData(this.props.accountUsers)}
        searchTerms={this.props.payTransactionSearchTerms}
        onSelectItem={this.onSelectPayTransaction}
        onSearchTermChange={this.onTonersSearchTermChange}
        onFilterChange={this.onPayTransactionsFilterChange}
        filterAction={SET_PAYMENT_TRANSACTION_FILTER_LABEL}
        batchItems={[]}
        hideBatchCheckBox={true}
        onAddItemToBatch={() => {
          return;
        }}
        onAddAllItemsToBatch={() => {
          return;
        }}
        primaryColumn={primaryColumn}
        gridColumns={gridColumns}
        tableColumns={tableColumns}
        loading={this.props.loadingData}
        copyItemsConfig={copyConfig}
      />
    );
  }

  renderUploadForm = () => {
    if (this.state.showFileUploadModal) {
      return (
        <UploadPOPActivateCreditsForm
          onFileUploadModalSubmit={this.onFileUploadModalSubmit}
          onFileUploadModalCancel={this.onFileUploadModalCancel}
        />
      );
    }
  };

  renderFileUploadNotice = () => {
    return (
      <Message icon positive>
        <Icon name="info circle" />
        <Message.Content>
          <Message.Header>Important</Message.Header>
          <p>Kindly note that the system permits only one attachment per transaction.</p>
        </Message.Content>
      </Message>
    );
  };

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <div>
        <div>
          {this.renderUploadForm()}
        </div>
        <div className={className}>
          <div>
            {this.renderPayTransactionsTable()}
          </div>
          <div className="spacer-very-wide" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    payTransactionSearchTerms: state.payTransactions.payTransactionSearchTerms,
    payTransactions: state.payTransactions.list,
    allPayTransactions: state.payTransactions.all,
    selectedPayTransaction: state.payTransactions.selected,
    loadingData: state.api.loadingData,
    accountUsers: state.accountusers.items,
    settings: state.settings.combined
  };
};

export default connect(mapStateToProps, {
  setPayTransactionsFilter,
  selectPayTransaction,
  activateCreditsInDb,
  updatePayTransactionsState,
  getPayTransactions,
  clearPayTransactions,
  setPayTransactionSearchTerm,
  clearPayTransaction,
  getAccountUsers,
  getPopSignedUrlAndUpload,
  resetPopUploadAction,
  getSettings,
  getPopSignedURL
})(PayTransactions);
