import React from "react";
import "../components.css";
import {
  getSettings,
  setPaintColorSearchTerm,
  setPaintColorsFilter,
  getPaintColors,
  getManufacturers,
  getSuppliers,
  getPaintSystems,
  selectPaintColor,
  clearPaintColor,
  selectColorToner,
  clearColorToner,
  addPaintColorToSelectedBatch,
  removePaintColorFromSelectedBatch,
  clearPaintColorBatch,
  addColorTonerToSelectedBatch,
  removeColorTonerFromSelectedBatch,
  clearColorTonerBatch,
  setSelectedColorToners,
  addAllColorTonersToSelectedBatch,
  addAllPaintColorsToSelectedBatch,
  deletePaintColors,
  patchPaintColorInDb,
  setColorTonerSearchTerm,
  setColorTonerFilter,
  updatePaintColorsState,
  updateAllPaintColorsState,
  selectEffect,
  clearEffectBatch,
  getEffects,
} from "../../actions";
import { NEW, EDIT, DELETE } from "../../actions/types";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import {
  selectOptionsFromData,
  arrayDelta,
  filterArray,
  replaceArrayObjectByKey,
  filterArrayOnConcatenatedKeys,
  removeObjectFromArrayById,
} from "../../utilities";
import { Confirm } from "semantic-ui-react";
import DisplayTable from "../displayTable";
import _ from "lodash";
import history from "../../history";
import { Link } from "react-router-dom";
import { compileDataForSave } from "../../apis/PaintColors";
import { SET_PAINTCOLOR_FILTER_LABEL, SET_COLOR_TONER_FILTER_LABEL } from "../../actions/types";

class PaintColors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDeleteOpen: false,
    };
  }

  componentDidMount() {
    const { getManufacturers, getEffects, getSuppliers, getPaintSystems } = this.props;
    getManufacturers({});
    getSuppliers({});
    getPaintSystems();
    getEffects({});
    if (_.isEmpty(this.props.settings)) {
      this.props.getSettings({});
    }
  }

  onPaintColorsSearchTermChange = (term) => {
    const { setPaintColorSearchTerm, clearPaintColor, getPaintColors, paintColorSearchTerms } =
      this.props;

    if (term.value !== "") {
      setPaintColorSearchTerm(term);
      clearPaintColor();
      if (term.value) {
        getPaintColors({
          manufacturerid: term.value,
          name: paintColorSearchTerms.filter.value.toLowerCase(),
        });
      }
    }
  };

  onPaintColorsFilterChange = (filter) => {
    const {
      clearPaintColor,
      paintColorSearchTerms,
      setPaintColorsFilter,
      updatePaintColorsState,
      allPaintColors,
    } = this.props;

    const _newfilter = filter === "#clear#" ? "" : paintColorSearchTerms.filter.label;

    if (_newfilter !== paintColorSearchTerms.filter.value) {
      clearPaintColor();

      if (_newfilter !== "") {
        updatePaintColorsState(
          filterArrayOnConcatenatedKeys(allPaintColors, ["l_name", "supplierName"], _newfilter)
        );
      } else {
        updatePaintColorsState(allPaintColors);
      }
    }
    setPaintColorsFilter(_newfilter);
  };

  onColorTonerFilterChange = (filter) => {
    const {
      selectedPaintColor,
      setColorTonerFilter,
      clearColorToner,
      colorTonerSearchTerms,
      setSelectedColorToners,
    } = this.props;

    const _newfilter = filter === "#clear#" ? "" : colorTonerSearchTerms.filter.label;

    if (_newfilter !== colorTonerSearchTerms.filter.value) {
      clearColorToner();
      setSelectedColorToners(filterArray(selectedPaintColor.allToners, "tonerName", _newfilter));
    }

    setColorTonerFilter(_newfilter);
  };

  selectEffect = (id) => {
    const { selectEffect, effects, clearEffectBatch } = this.props;
    selectEffect(effects.find((effect) => effect.id === id));
    clearEffectBatch();
  };

  //passed to Paint Colors List component
  onSelectPaintColor = (id) => {
    const { clearColorToner, selectPaintColor, paintColors, clearPaintColorBatch } = this.props;
    clearColorToner();
    const _color = paintColors.find((paintColor) => paintColor.id === id);
    selectPaintColor(_color);
    clearPaintColorBatch();
  };

  onAddPaintColorToBatch = (paintColor) => {
    if (this.props.paintColorBatch.find((item) => item === paintColor)) {
      this.props.removePaintColorFromSelectedBatch(paintColor);
    } else {
      this.props.addPaintColorToSelectedBatch(paintColor);
    }
  };

  onAddColorTonerToBatch = (panel) => {
    if (this.props.colorTonerBatch.find((colorToner) => colorToner === panel)) {
      this.props.removeColorTonerFromSelectedBatch(panel);
    } else {
      this.props.addColorTonerToSelectedBatch(panel);
    }
  };

  onAddAllPaintColorsToSelectedBatch = () => {
    if (this.props.paintColorBatch.length === this.props.paintColors.length) {
      this.props.clearPaintColorBatch();
    } else {
      this.props.addAllPaintColorsToSelectedBatch(this.props.paintColors);
    }
  };

  onAddAllColorTonersToSelectedBatch = () => {
    if (this.props.selectedPaintColor.toners.length === this.props.colorTonerBatch.length) {
      this.props.clearColorTonerBatch();
    } else {
      this.props.addAllColorTonersToSelectedBatch(this.props.selectedPaintColor.toners);
    }
  };

  onBatchDeletePaintColors = () => {
    const {
      paintColors,
      paintColorBatch,
      deletePaintColors,
      clearPaintColorBatch,
      clearPaintColor,
      allPaintColors,
      updatePaintColorsState,
      updateAllPaintColorsState,
    } = this.props;

    if (paintColorBatch.length > 0) {
      deletePaintColors(paintColorBatch);
    }
    clearPaintColorBatch();
    clearPaintColor();
    updatePaintColorsState(arrayDelta(paintColors, paintColorBatch));
    updateAllPaintColorsState(arrayDelta(allPaintColors, paintColorBatch));
  };

  updatePaintColor = (paintColor) => {
    const { patchPaintColorInDb, selectPaintColor, updatePaintColorsState, updateAllPaintColorsState } = this.props;
    let _paintColor = compileDataForSave(
      {
        code: paintColor.code,
        colorgroupId: paintColor.colorgroupId,
        effectId: paintColor.effectId,
        supplierId: paintColor.supplierId,
        colorName: paintColor.name,
        manufacturerId: paintColor.manufacturerId,
        systemId: paintColor.systemId,
        testplateQty: paintColor.testplateQty,
        reworkFactor: paintColor.reworkFactor,
        toners: paintColor.allToners,
      },
      { id: paintColor.id, state: paintColor.active }
    );

    patchPaintColorInDb(_paintColor);
    selectPaintColor(paintColor);
    updatePaintColorsState(replaceArrayObjectByKey(this.props.paintColors, "id", paintColor));
    updateAllPaintColorsState(replaceArrayObjectByKey(this.props.allPaintColors, "id", paintColor));
   
  };

  //called from tables main menu to do ad-hoc save.
  onSaveChanges = () => {
    const _paintColor = { ...this.props.selectedPaintColor };
    if (!_.isEmpty(_paintColor)) {
      this.updatePaintColor(_paintColor);
    }
  };

  onBatchDeleteColorToners = () => {
    const {paintColors, allPaintColors, clearColorTonerBatch, selectedPaintColor, colorTonerBatch, updatePaintColorsState, updateAllPaintColorsState } = this.props;
    if (colorTonerBatch.length > 0) {
      let _paintColor = { ...selectedPaintColor };
      let _toners = arrayDelta(selectedPaintColor.toners, colorTonerBatch);
      let _allToners = arrayDelta(selectedPaintColor.allToners, colorTonerBatch);
      _paintColor.toners = _toners;
      _paintColor.allToners = _allToners;
      this.updatePaintColor(_paintColor);
      //find the selected color in paintColors set and update state
      let _color = paintColors.find((color) => {
        return color.id === _paintColor.id
      });
      
      //make sure the full set of toners is set on color object
      _color.toners = _allToners;

      clearColorTonerBatch();
      updatePaintColorsState(replaceArrayObjectByKey(paintColors, "id", _color));
      updateAllPaintColorsState(replaceArrayObjectByKey(allPaintColors, "id", _color));
    }
  };

  onDeleteColorToner = () => {
    const { clearColorTonerBatch, selectedPaintColor, selectedColorToner } = this.props;
    const colorTonerBatch = [];
    colorTonerBatch.push(selectedColorToner);
    if (colorTonerBatch.length > 0) {
      let _paintColor = { ...selectedPaintColor };
      let _toners = arrayDelta(selectedPaintColor.toners, colorTonerBatch);
      let _allToners = arrayDelta(selectedPaintColor.allToners, colorTonerBatch);
      _paintColor.toners = _toners;
      _paintColor.allToners = _allToners;
      this.updatePaintColor(_paintColor);
      clearColorTonerBatch();
    }
  };

  //passed to PanelSizesList component
  onSelectColorToner = (id, stageId) => {
    this.props.selectColorToner(
      this.props.selectedPaintColor.toners.find((toner) => {
        return toner.id === id && toner.stageId === stageId;
      })
    );
  };

  renderPaintColorsTable() {
    const menuItems = [
      
      {
        name: "New Color",
        key: NEW,
        icon: "add square",
        to: `/paintcolors/create/${this.props.paintColorSearchTerms.term.value}`,
        type: Link,
        disabled: this.props.paintColorSearchTerms.term.value === "",
      },
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit Paint Color",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId }) => {
          history.push(`/paintcolors/edit/${itemId}`);
        },
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: () => {
          this.setState({ confirmDeleteOpen: true });
        },
      },
    ];

    const tableMenuActions = [
      {
        key: DELETE,
        confirmation: 1,
        text: "Delete",
        value: DELETE,
        icon: { name: "remove", color: "red" },
        idx: 0,
        action: this.onBatchDeletePaintColors,
        header: "Confirm Delete",
      },
    ];

    const gridColumns = "three";
    const tableColumns = [
      {
        width: "one",
        heading: "Code",
        displayValues: [{ columnName: "code" }],
      },
      {
        width: "two",
        heading: "Supplier",
        displayValues: [{ columnName: "supplierName" }],
      },
      {
        width: "one",
        heading: "Cost",
        displayValues: [{ columnName: "cost" }],
      },
      {
        width: "one",
        heading: "Weight",
        displayValues: [{ columnName: "weight" }],
      },
      {
        width: "one",
        heading: "Effect",
        displayValues: [{ columnName: "effectName" }],
      },
      {
        width: "two",
        heading: "System",
        displayValues: [{ columnName: "systemName" }],
      },
    ];

    const primaryColumn = {
      heading: "Color Name",
      width: "five",
      displayValues: [{ columnName: "name" }],
    };

    //check if "paintColor panels message must be displayed"

    const displaySelectPaintColorMessage =
      this.props.paintColors.length > 0 && !this.props.selectedPaintColor.id ? "" : "none";
    return (
      <>
        <DisplayTable
          idFieldName="id"
          primaryHeading="Paint Formulas"
          items={this.props.paintColors}
          selectedItemId={this.props.selectedPaintColor.id}
          menuItems={menuItems}
          tableMenuActions={tableMenuActions}
          itemMenuActions={itemMenuActions}
          showSearch={1}
          showSearchFilter={true}
          searchTerms={this.props.paintColorSearchTerms}
          onSelectItem={this.onSelectPaintColor}
          onSearchTermChange={this.onPaintColorsSearchTermChange}
          onFilterChange={this.onPaintColorsFilterChange}
          filterAction={SET_PAINTCOLOR_FILTER_LABEL}
          searchBarOptions={selectOptionsFromData(this.props.manufacturers)}
          searchBarPlaceholder="Select Manufacturer"
          batchItems={this.props.paintColorBatch}
          onAddItemToBatch={this.onAddPaintColorToBatch}
          onAddAllItemsToBatch={this.onAddAllPaintColorsToSelectedBatch}
          primaryColumn={primaryColumn}
          gridColumns={gridColumns}
          tableColumns={tableColumns}
          loading={this.props.loadingData}
          copyItemsConfig={{ active: false }}
        ></DisplayTable>
        <div className=" margin-bottom-small">
          <div
            style={({ textAlign: "center" }, { display: displaySelectPaintColorMessage })}
            className="ui positive message"
          >
            <i className="big cog icon"></i>
            Select a paint color to display the color toners.
          </div>
        </div>
      </>
    );
  }

  renderColorTonersTable() {
    const menuItems = [
      
      {
        name: "Add Toner",
        key: NEW,
        icon: "add square",
        to: `/paintcolors/toners/create/${this.props.selectedPaintColor.id}`,
        type: Link,
        disabled: false,
      },
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit Item",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId, masterId, masterId2 }) => {
          history.push(`/paintcolors/toners/edit/${masterId}/${masterId2}/${itemId}`);
        },
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: this.onDeleteColorToner,
        header: "Confirm Delete",
      },
    ];

    const tableMenuActions = [
      {
        key: DELETE,
        confirmation: 1,
        text: "Delete",
        value: DELETE,
        icon: { name: "remove", color: "red" },
        idx: 0,
        action: this.onBatchDeleteColorToners,
        header: "Confirm Delete",
      },
    ];

    const gridColumns = "three";
    const tableColumns = [
      {
        width: "two",
        heading: "Stage",
        displayValues: [{ columnName: "stageName" }],
      },
      {
        width: "two",
        heading: "Code",
        displayValues: [{ columnName: "code" }],
      },
      {
        width: "two",
        heading: "Weight",
        displayValues: [{ columnName: "weight" }],
      },
      {
        width: "two",
        heading: "Cost",
        displayValues: [{ columnName: "cost" }],
      },
    ];

    const primaryColumn = {
      heading: "Toner Name",
      width: "four",
      displayValues: [{ columnName: "tonerName" }],
    };

    if (this.props.selectedPaintColor.id) {
      return (
        <>
          <DisplayTable
            idFieldName="id"
            subIdFieldName="stageId"
            primaryHeading="Color Toners"
            items={this.props.selectedPaintColor.toners}
            selectedItemId={this.props.selectedColorToner.id}
            masterId={this.props.selectedPaintColor.id}
            masterId2={this.props.selectedColorToner.stageId}
            menuItems={menuItems}
            tableMenuActions={tableMenuActions}
            itemMenuActions={itemMenuActions}
            showSearch={0}
            showSearchFilter={true}
            searchTerms={this.props.colorTonerSearchTerms}
            onSelectItem={this.onSelectColorToner}
            onSearchTermChange={() => {
              return;
            }}
            onFilterChange={this.onColorTonerFilterChange}
            filterAction={SET_COLOR_TONER_FILTER_LABEL}
            batchItems={this.props.colorTonerBatch}
            onAddItemToBatch={this.onAddColorTonerToBatch}
            onAddAllItemsToBatch={this.onAddAllColorTonersToSelectedBatch}
            primaryColumn={primaryColumn}
            gridColumns={gridColumns}
            tableColumns={tableColumns}
            loading={this.props.loadingData}
            copyItemsConfig={{ active: false }}
          ></DisplayTable>
        </>
      );
    }
  }

  renderPaintTonerTotalsHeader() {
    return (
      <div className="margin-bottom-small">
        <h3>Toner Totals</h3>
      </div>
    );
  }

  renderPaintTonerTotals() {
    let _stages = [];
    let _stageToners = [];
    let _stageTotals = [];
    let _paintMarkUp = 0;
    if (this.props.selectedPaintColor.toners) {
      if (this.props.settings.app) {
        _paintMarkUp = parseInt(
          this.props.settings.app.find((setting) => {
            return setting.key === "paint_margin";
          }).value
        );
      }

      this.props.selectedPaintColor.toners.forEach((toner) => {
        _stages.push(toner.stageId);
      });
      const _stagesUniq = _.uniq(_stages);
      _stagesUniq.forEach((stageId) => {
        _stageToners = filterArray(this.props.selectedPaintColor.toners, "stageId", stageId);
        let _stageTotalRec = { name: "", cost: 0.0, weight: 0.0, incomplete: false };
        let _cost = 0.0;
        let _weight = 0.0;
        let _incomplete = false;
        _stageToners.forEach((toner) => {
          _cost += toner.cost ? toner.cost : 0;
          if (_incomplete === false) {
            _incomplete = !toner.cost;
          }
          _weight += toner.weight;
        });
        _stageTotalRec.weight = _weight;
        _stageTotalRec.cost = _cost;
        _stageTotalRec.name = _stageToners[0].stageName;
        _stageTotalRec.incomplete = _incomplete;
        _stageTotals.push(_stageTotalRec);
      });
      return _stageTotals.map((total) => {
        return (
          <div className="margin-bottom-small">
            <b>{total.name}:</b> Toner Cost Including Markup ={" "}
            <b>
              {isNaN((total.cost * (1 + 100 / _paintMarkUp)).toFixed(2))
                ? "N/A"
                : (total.cost * (1 + 100 / _paintMarkUp)).toFixed(2)}{" "}
              {total.incomplete === true ? " (prices incomplete)" : ""}
            </b>{" "}
            | Total Weight (g) = <b>{total.weight.toFixed(2)}</b>
          </div>
        );
      });
    }
  }

  onDeleteColor = () => {
    const {
      clearPaintColor,
      updatePaintColorsState,
      updateAllPaintColorsState,
      paintColors,
      allPaintColors,
    } = this.props;
    let _color = { ...this.props.selectedPaintColor };
    //set to inactive
    _color.active = false;
    this.updatePaintColor(_color);
    clearPaintColor();
    updatePaintColorsState(removeObjectFromArrayById(paintColors, _color));
    updateAllPaintColorsState(removeObjectFromArrayById(allPaintColors, _color));
  };

  renderDeleteConfirm() {
    return (
      <Confirm
        open={this.state.confirmDeleteOpen}
        header="Item Actions"
        content={`Confirm Delete?`}
        onCancel={() => {
          this.setState({ confirmDeleteOpen: false });
        }}
        onConfirm={() => {
          this.setState({ confirmDeleteOpen: false });
          this.onDeleteColor();
        }}
      ></Confirm>
    );
  }

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <>
        <div className={className}>
          <div className="ui info message">
            <div class="header">Usage Instructions</div>
            <ul class="list">
              <li>Used to compile paint formulas.</li>
              <li>
                The color record must be created first which specifies the name, code, supplier and
                effect. Once the color record is captured, all toners requiring to mix 1kg of that
                specific paint color must be captured.
              </li>
              <li>
                Only toners captured under the paint / toners menu option of this application,
                having a calculated Cost Per Gram, will be available for selection when capturing
                the toners for a specific formula.
              </li>
              <li>
                <b>
                  The cost for each toner and complete formula is dynamic and re-calculated whenever
                  a formula is added or retrieved.
                </b>{" "}
                This is deliberate since the Cost Per Gram for any toner record can change at any
                point.
              </li>
            </ul>
          </div>
          <div>{this.renderPaintColorsTable()}</div>
          <div>{this.renderColorTonersTable()}</div>
          <div>{this.renderDeleteConfirm()}</div>
          <div>{this.renderPaintTonerTotalsHeader()}</div>
          <div>{this.renderPaintTonerTotals()}</div>
          <div className="spacer-very-wide"></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paintColors: state.paintcolors.paintColors,
    bulkCopyDone: state.paintcolors.bulkCopyDone,
    bodytypes: state.bodytypes.bodytypes,
    manufacturers: state.manufacturers.manufacturers,
    loadingData: state.api.loadingData,
    selectedPaintColor: state.paintcolors.selectedPaintColor,
    selectedColorToner: state.paintcolors.selectedColorToner,
    paintColorBatch: state.paintcolors.paintColorBatch,
    colorTonerBatch: state.paintcolors.colorTonerBatch,
    paintColorSearchTerms: state.paintcolors.paintColorSearchTerms,
    colorTonerSearchTerms: state.paintcolors.colorTonerSearchTerms,
    newPaintColor: state.paintcolors.newPaintColor,
    allPaintColors: state.paintcolors.allPaintColors,
    effects: state.effects.effects,
    settings: state.settings.combined,
  };
};

export default connect(mapStateToProps, {
  setPaintColorSearchTerm,
  getSuppliers,
  getPaintSystems,
  setColorTonerFilter,
  setPaintColorsFilter,
  getPaintColors,
  updatePaintColorsState,
  updateAllPaintColorsState,
  getManufacturers,
  selectPaintColor,
  clearPaintColor,
  selectColorToner,
  clearColorToner,
  addPaintColorToSelectedBatch,
  addAllPaintColorsToSelectedBatch,
  removePaintColorFromSelectedBatch,
  clearPaintColorBatch,
  addColorTonerToSelectedBatch,
  addAllColorTonersToSelectedBatch,
  removeColorTonerFromSelectedBatch,
  clearColorTonerBatch,
  setSelectedColorToners,
  deletePaintColors,
  patchPaintColorInDb,
  setColorTonerSearchTerm,
  selectEffect,
  clearEffectBatch,
  getEffects,
  getSettings,
})(PaintColors);
