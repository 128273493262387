import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Grid,
  Menu,
  Segment,
  Sidebar,
  Icon,
  Popup,
  Divider,
  Header,
  Dropdown
} from "semantic-ui-react";
import { signIn, signOut, setUserFirstName } from "../actions";
import SessionManager from "./session/SessionManager";
import {
  MANUFACTURERS,
  BODYTYPES,
  PARTS,
  MODELS,
  VEHICLES,
  PAINT,
  STAGES,
  REPAIRS,
  CONSUMABLES,
  ACTIVITY_CONSUMABLES,
  TONERS,
  LABOUR_ADJUSTMENTS,
  PAINTFORMULAS,
  SETUPCOSTS,
  COLORGROUPS,
  EFFECTS,
  REFERENCEPANEL,
  FUELPRICES,
  PRIMERPRICES,
  SORs,
  LOCATIONS,
  CLIENTS,
  ALLOCATE_PAYMENT
} from "./menuItemTypes";
import history from "../history";
import "./components.css";

class Mainmenu extends React.Component {
  state = { visible: false };

  componentDidMount() {
    if (!this.props.isSignedIn) {
      const authData = SessionManager.checkAuth();
      if (authData.email) {
        this.props.signIn(authData.userId, authData.email);
        this.props.setUserFirstName(authData.firstName);
      }
    }
  }

  renderUserPopUpMenu = () => {
    return (
      <Menu vertical className="pointer" style={{ textAlign: "center" }}>
        <Menu.Item style={{ fontSize: "0.7em", textAlign: "center" }} header>
          <Icon name="amazon" color="green" />
          {this.props.email}
        </Menu.Item>
        <Menu.Item name="signout" onClick={() => this.onSignOutClick()}>
          <Icon name="arrow alternate circle right" color="blue" />
          Sign Out
        </Menu.Item>
      </Menu>
    );
  };

  signOut() {
    this.props.signOut();
    SessionManager.terminateSession();
    history.push("/");
  }

  onSignOutClick() {
    this.signOut();
  }

  //Menu items to be rendered for each route or "view"
  menuItems = [
    {
      name: VEHICLES,
      icon: "add square",
      subitems: [
        { name: BODYTYPES, to: "/bodytypes" },
        { name: MANUFACTURERS, to: "/manufacturers" },
        { name: PARTS, to: "/parts" },
        { name: MODELS, to: "/models" },
        { name: LOCATIONS, to: "/locations" }
      ]
    },
    {
      name: PAINT,
      icon: "list alternate outline",
      subitems: [
        { name: STAGES, to: "/stages" },
        { name: TONERS, to: "/toners" },
        { name: PAINTFORMULAS, to: "/paintcolors" },
        { name: COLORGROUPS, to: "/colorgroups" },
        { name: EFFECTS, to: "/effects" },
        { name: PRIMERPRICES, to: "/primerprices" }
      ]
    },
    {
      name: REPAIRS,
      icon: "list alternate outline",
      to: "/dashboard",
      subitems: [
        { name: CONSUMABLES, to: "/consumables" },
        { name: ACTIVITY_CONSUMABLES, to: "/activityconsumables" },
        { name: LABOUR_ADJUSTMENTS, to: "/labouradjustments" },
        { name: SETUPCOSTS, to: "/setupcosts" },
        { name: REFERENCEPANEL, to: "/referencepanel" },
        { name: FUELPRICES, to: "/fuelprices" },
        { name: SORs, to: "/sors" }
      ]
    },
    {
      name: CLIENTS,
      icon: "list alternate outline",
      subitems: [
        { name: ALLOCATE_PAYMENT, to: "/payments" },
      ]
    }
  ];

  constructSubMenu = (subitems) => {
    const submenu = subitems.map((item) => {
      return (
        <Dropdown.Item as={Link} key={item.name} to={item.to}>
          {item.name}
        </Dropdown.Item>
      );
    });
    return <Dropdown.Menu>{submenu}</Dropdown.Menu>;
  };

  constructMenuItems = () => {
    const menu = this.menuItems.map((item) => {
      return (
        <>
          <Dropdown item text={item.name} key={item.name}>
            {this.constructSubMenu(item.subitems)}
          </Dropdown>
        </>
      );
    });

    return <>{menu}</>;
  };

  renderUserMenu = (icon_size) => {
    const popupstyle = {
      borderRadius: 10,
      padding: "0",
      borderStyle: "none",
      boxShadow: "none"
    };

    const nameTagStyle = {
      marginTop: "1em",
      marginRight: "0.5em",
      fontWeight: 300,
      color: "blue"
    };

    var itemStyle = null;
    if (icon_size === "huge") {
      itemStyle = {
        marginTop: "1em"
      };
    }
    return (
      <Menu.Menu position="right">
        <Menu.Item style={itemStyle}>
          <Header as="h5" style={nameTagStyle}>
            Logged in as {this.props.firstName}{" "}
          </Header>
          <Popup
            style={popupstyle}
            trigger={<Icon name="user" color="green" size={icon_size} circular inverted />}
            on="hover"
            hoverable
            basic
            content={this.renderUserPopUpMenu()}
            position="bottom left"
          />
        </Menu.Item>
        <Divider></Divider>
      </Menu.Menu>
    );
  };

  state = { activeItem: VEHICLES };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  renderMenu = (isSignedIn) => {
    if (isSignedIn) {
      return (
        <div>
          <>
            <Grid>
              <Grid.Row only="computer">
                <Grid.Column>
                  <Menu borderless secondary>
                    {this.constructMenuItems("large")}
                    {this.renderUserMenu("large")}
                  </Menu>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row only="mobile tablet">
                <Grid.Column>
                  <Menu>
                    <Menu.Item
                      icon="bars"
                      onClick={() => this.setState({ visible: !this.state.visible })}></Menu.Item>
                  </Menu>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Sidebar.Pushable as={Segment} basic>
              <Sidebar
                as={Menu}
                animation="overlay"
                icon="labeled"
                vertical
                visible={this.state.visible}>
                {this.renderUserMenu("big")}
                {this.constructMenuItems("tiny")}
              </Sidebar>
              <Sidebar.Pusher>{this.props.contentView}</Sidebar.Pusher>
            </Sidebar.Pushable>
          </>
        </div>
      );
    } else {
      return <div className="ui error message">You must be logged in to view this content</div>;
    }
  };

  render() {
    return this.renderMenu(this.props.isSignedIn);
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    authorizor: state.auth.authorizor,
    firstName: state.auth.firstName,
    email: state.auth.email
  };
};

export default connect(mapStateToProps, {
  signIn,
  signOut,
  setUserFirstName
})(Mainmenu);
