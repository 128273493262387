export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const BUSY_SAVING = "BUSY_SAVING";
export const SET_USER_FIRSTNAME = "SET_USER_FIRSTNAME";
export const GET_USER_SETTINGS = "GET_USER_SETTINGS";
export const FAIL_SIGN_IN = "FAIL_SIGN_IN";
export const RESET_SIGN_IN = "RESET_SIGN_IN";
export const SET_RESET_USERNAME = "SET_RESET_USERNAME";
export const SAVED_RECORD = "SAVED_RECORD";
export const SAVE_FAILED = "SAVE_FAILED";
export const CANCEL_SAVE = "CANCEL_SAVE";
//models & model panels
export const GET_MODELS = "GET_MODELS";
export const CLONE_MODELS = "CLONE_MODELS";
export const UPDATE_MODELS = "UPDATE_MODELS";
export const SELECT_MODEL = "SELECT_MODEL";
export const SET_MODEL_SEARCH_TERM = "SET_MODEL_SEARCH_TERM";
export const SET_MODEL_FILTER = "SET_MODEL_FILTER";
export const SET_MODEL_FILTER_LABEL = "SET_MODEL_FILTER_LABEL";
export const SET_MODEL_PANEL_FILTER = "SET_MODEL_PANEL_FILTER";
export const SET_MODEL_PANEL_FILTER_LABEL = "SET_MODEL_PANEL_FILTER_LABEL";
export const SET_MODEL_PANEL_SEARCH_TERM = "SET_MODEL_PANEL_SEARCH_TERM";
export const SET_MODEL_PARTS = "SET_MODEL_PARTS";
export const SET_MODEL_PANEL_INCLUDE_EXCLUDE =
  "SET_MODEL_PANEL_INCLUDE_EXCLUDE";
export const ADD_MODEL_TO_BATCH = "ADD_MODEL_TO_BATCH";
export const ADD_ALL_MODELS_TO_BATCH = "ADD_ALL_MODELS_TO_BATCH";
export const ADD_MODEL_PANEL_TO_BATCH = "ADD_MODEL_PANEL_TO_BATCH";
export const ADD_ALL_MODEL_PANELS_TO_BATCH = "ADD_ALL_MODEL_PANELS_TO_BATCH";
export const REMOVE_MODEL_FROM_BATCH = "REMOVE_MODEL_FROM_BATCH";
export const REMOVE_MODEL_PANEL_FROM_BATCH = "REMOVE_MODEL_PANEL_FROM_BATCH";
export const CLEAR_MODEL_BATCH = "CLEAR_MODEL_BATCH";
export const CLEAR_MODEL_PANEL_BATCH = "CLEAR_MODEL_PANEL_BATCH";
export const SET_LAST_MODEL_KEY = "SET_LAST_MODEL_KEY";
export const CLEAR_MODEL = "CLEAR_MODEL";
export const ARCHIVE_MODEL = "ARCHIVE_MODEL";
export const SELECT_MODEL_PANEL = "SELECT_MODEL_PANEL";
export const CLEAR_MODEL_PANEL = "CLEAR_MODEL_PANEL";
export const UPDATE_ALL_MODELS = "UPDATE_ALL_MODELS";
export const SIGNED_UP = "SIGNED_UP";
export const SIGN_UP = "SIGN_UP";
export const FAIL_SIGNUP = "FAIL_SIGNUP";

//body types
export const GET_BODYTYPES = "GET_BODYTYPES";
export const SELECT_BODYTYPE = "SELECT_BODYTYPE";
export const CLEAR_BODYTYPE = "CLEAR_BODYTYPE";
export const GET_BODYTYPE = "GET_BODYTYPE";
export const GET_BODYSUBTYPES = "GET_BODYSUBTYPES";
export const ARCHIVE_BODYTYPE = "ARCHIVE_BODYTYPE";

export const LOADING_DATA = "LOADING_DATA";
export const DATA_LOADED = "DATA_LOADED";
export const GET_PARTS = "GET_PARTS";
export const NEW = "New";
export const EDIT = "Edit";
export const DELETE = "Delete";
export const SAVE = "Save";
export const ALLOCATE_PAYMENT = "Allocate Payment"
export const DOWNLOAD_POP = "Download POP"

//Parts
export const SET_PART_FILTER = "SET_PART_FILTER";
export const SET_PART_FILTER_LABEL = "SET_PART_FILTER_LABEL";
export const CLEAR_PART = "CLEAR_PART";
export const CLEAR_PARTS = "CLEAR_PARTS";
export const SELECT_PART = "SELECT_PART";
export const CLEAR_PART_BATCH = "CLEAR_PART_BATCH";
export const REMOVE_PART_FROM_BATCH = "REMOVE_PART_FROM_BATCH";
export const ADD_PART_TO_BATCH = "ADD_PART_TO_BATCH";
export const ADD_ALL_PARTS_TO_BATCH = "ADD_ALL_PARTS_TO_BATCH";
export const UPDATE_PARTS = "UPDATE_PARTS";
export const UPDATE_ALL_PARTS = "UPDATE_ALL_PARTS";
export const DELETE_PART = "DELETE_PART";
export const CLONE_PARTS = "CLONE_PARTS";

//Manufacturers
export const GET_MANUFACTURERS = "GET_MANUFACTURERS";
export const CLONE_MANUFACTURERS = "CLONE_MANUFACTURERS";
export const GET_MANUFACTURER = "GET_MANUFACTURER";
export const SET_MANUFACTURER_FILTER = "SET_MANUFACTURER_FILTER";
export const SET_MANUFACTURER_FILTER_LABEL = "SET_MANUFACTURER_FILTER_LABEL";
export const CLEAR_MANUFACTURER = "CLEAR_MANUFACTURER";
export const CLEAR_MANUFACTURERS = "CLEAR_MANUFACTURERS";
export const SELECT_MANUFACTURER = "SELECT_MANUFACTURER";
export const CLEAR_MANUFACTURER_BATCH = "CLEAR_MANUFACTURER_BATCH";
export const REMOVE_MANUFACTURER_FROM_BATCH = "REMOVE_MANUFACTURER_FROM_BATCH";
export const ADD_MANUFACTURER_TO_BATCH = "ADD_MANUFACTURER_TO_BATCH";
export const ADD_ALL_MANUFACTURERS_TO_BATCH = "ADD_ALL_MANUFACTURERS_TO_BATCH";
export const UPDATE_MANUFACTURERS = "UPDATE_MANUFACTURERS";
export const UPDATE_ALL_MANUFACTURERS = "UPDATE_ALL_MANUFACTURERS";
export const DELETE_MANUFACTURER = "DELETE_MANUFACTURER";

//Paint Code Locations
export const GET_LOCATIONS = "GET_LOCATIONS";
export const CLONE_LOCATIONS = "CLONE_LOCATIONS";
export const GET_LOCATION = "GET_LOCATION";
export const SET_LOCATION_FILTER = "SET_LOCATION_FILTER";
export const SET_LOCATION_FILTER_LABEL = "SET_LOCATION_FILTER_LABEL";
export const CLEAR_LOCATION = "CLEAR_LOCATION";
export const CLEAR_LOCATIONS = "CLEAR_LOCATIONS";
export const SELECT_LOCATION = "SELECT_LOCATION";
export const CLEAR_LOCATION_BATCH = "CLEAR_LOCATION_BATCH";
export const REMOVE_LOCATION_FROM_BATCH = "REMOVE_LOCATION_FROM_BATCH";
export const ADD_LOCATION_TO_BATCH = "ADD_LOCATION_TO_BATCH";
export const ADD_ALL_LOCATIONS_TO_BATCH = "ADD_ALL_LOCATIONS_TO_BATCH";
export const UPDATE_LOCATIONS = "UPDATE_LOCATIONS";
export const UPDATE_ALL_LOCATIONS = "UPDATE_ALL_LOCATIONS";
export const DELETE_LOCATION = "DELETE_LOCATION";

//Toners
export const GET_SUPPLIERS = "GET_SUPPLIERS";
export const SELECT_SUPPLIER = "SELECT_SUPPLIER";
export const GET_SYSTEMS = "GET_SYSTEMS";
export const GET_TONERS = "GET_TONERS";
export const GET_TONER = "GET_TONER";
export const SET_TONER_FILTER = "SET_TONER_FILTER";
export const SET_TONER_FILTER_LABEL = "SET_TONER_FILTER_LABEL";
export const CLEAR_TONER = "CLEAR_TONER";
export const CLEAR_TONERS = "CLEAR_TONERS";
export const SELECT_TONER = "SELECT_TONER";
export const SELECT_TONERS = "SELECT_TONERS"; //only needed for data driven record set
export const CLEAR_TONER_BATCH = "CLEAR_TONER_BATCH";
export const REMOVE_TONER_FROM_BATCH = "REMOVE_TONER_FROM_BATCH";
export const ADD_TONER_TO_BATCH = "ADD_TONER_TO_BATCH";
export const ADD_ALL_TONERS_TO_BATCH = "ADD_ALL_TONERS_TO_BATCH";
export const UPDATE_TONERS = "UPDATE_TONERS";
export const DELETE_TONER = "DELETE_TONER";
export const SET_TONER_SEARCH_TERM = "SET_TONER_SEARCH_TERM";
export const GET_TONER_CONTAINER_METRICS = "GET_TONER_CONTAINER_METRICS";
export const SET_TONER_CALCULATED_VOL = "SET_TONER_CALCULATED_VOL";
export const UPDATE_ALL_TONERS = "UPDATE_ALL_TONERS"
export const CLONE_TONERS = "CLONE_TONERS"

//paintcolors & color toners
export const GET_PAINTCOLORS = "GET_PAINTCOLORS";
export const CLONE_PAINTCOLORS = "CLONE_PAINTCOLORS";
export const UPDATE_PAINTCOLORS = "UPDATE_PAINTCOLORS";
export const UPDATE_ALL_PAINTCOLORS = "UPDATE_ALL_PAINTCOLORS";
export const SELECT_PAINTCOLOR = "SELECT_PAINTCOLOR";
export const SET_PAINTCOLOR_SEARCH_TERM = "SET_PAINTCOLOR_SEARCH_TERM";
export const SET_PAINTCOLOR_FILTER = "SET_PAINTCOLOR_FILTER";
export const SET_PAINTCOLOR_FILTER_LABEL = "SET_PAINTCOLOR_FILTER_LABEL";
export const ADD_PAINTCOLOR_TO_BATCH = "ADD_PAINTCOLOR_TO_BATCH";
export const ADD_ALL_PAINTCOLORS_TO_BATCH = "ADD_ALL_PAINTCOLORS_TO_BATCH";
export const REMOVE_PAINTCOLOR_FROM_BATCH = "REMOVE_PAINTCOLOR_FROM_BATCH";
export const CLEAR_PAINTCOLOR_BATCH = "CLEAR_PAINTCOLOR_BATCH";
export const SET_LAST_PAINTCOLOR_KEY = "SET_LAST_PAINTCOLOR_KEY";
export const CLEAR_PAINTCOLOR = "CLEAR_PAINTCOLOR";
export const ARCHIVE_PAINTCOLOR = "ARCHIVE_PAINTCOLOR";
//color toners
export const SET_COLOR_TONER_FILTER = "SET_COLOR_TONER_FILTER";
export const SET_COLOR_TONER_FILTER_LABEL = "SET_COLOR_TONER_FILTER_LABEL";
export const SET_COLOR_TONER_SEARCH_TERM = "SET_COLOR_TONER_SEARCH_TERM";
export const SET_COLOR_TONERS = "SET_COLOR_TONERS";
export const ADD_COLOR_TONER_TO_BATCH = "ADD_COLOR_TONER_TO_BATCH";
export const ADD_ALL_COLOR_TONERS_TO_BATCH = "ADD_ALL_COLOR_TONERS_TO_BATCH";
export const REMOVE_COLOR_TONER_FROM_BATCH = "REMOVE_COLOR_TONER_FROM_BATCH";
export const CLEAR_COLOR_TONER_BATCH = "CLEAR_COLOR_TONER_BATCH";
export const SELECT_COLOR_TONER = "SELECT_COLOR_TONER";
export const CLEAR_COLOR_TONER = "CLEAR_COLOR_TONER";
//Consumables
export const GET_CONSUMABLES = "GET_CONSUMABLES";
export const GET_CONSUMABLE = "GET_CONSUMABLE";
export const SET_CONSUMABLE_FILTER = "SET_CONSUMABLE_FILTER";
export const SET_CONSUMABLE_FILTER_LABEL = "SET_CONSUMABLE_FILTER_LABEL";
export const CLEAR_CONSUMABLE = "CLEAR_CONSUMABLE";
export const CLEAR_CONSUMABLES = "CLEAR_CONSUMABLES";
export const SELECT_CONSUMABLE = "SELECT_CONSUMABLE";
export const CLEAR_CONSUMABLE_BATCH = "CLEAR_CONSUMABLE_BATCH";
export const REMOVE_CONSUMABLE_FROM_BATCH = "REMOVE_CONSUMABLE_FROM_BATCH";
export const ADD_CONSUMABLE_TO_BATCH = "ADD_CONSUMABLE_TO_BATCH";
export const ADD_ALL_CONSUMABLES_TO_BATCH = "ADD_ALL_CONSUMABLES_TO_BATCH";
export const UPDATE_CONSUMABLES = "UPDATE_CONSUMABLES";
export const UPDATE_ALL_CONSUMABLES = "UPDATE_ALL_CONSUMABLES";
export const DELETE_CONSUMABLE = "DELETE_CONSUMABLE";
export const CLONE_CONSUMABLES = "CLONE_CONSUMABLES";

//Activity Consumables
export const GET_ACTIVITY_CONSUMABLES = "GET_ACTIVITY_CONSUMABLES";
export const GET_ACTIVITY_CONSUMABLE = "GET_ACTIVITY_CONSUMABLE";
export const SET_ACTIVITY_CONSUMABLE_FILTER = "SET_ACTIVITY_CONSUMABLE_FILTER";
export const SET_ACTIVITY_CONSUMABLE_FILTER_LABEL =
  "SET_ACTIVITY_CONSUMABLE_FILTER_LABEL";
export const CLEAR_ACTIVITY_CONSUMABLE = "CLEAR_ACTIVITY_CONSUMABLE";
export const CLEAR_ACTIVITY_CONSUMABLES = "CLEAR_ACTIVITY_CONSUMABLES";
export const SELECT_ACTIVITY_CONSUMABLE = "SELECT_ACTIVITY_CONSUMABLE";
export const CLEAR_ACTIVITY_CONSUMABLE_BATCH =
  "CLEAR_ACTIVITY_CONSUMABLE_BATCH";
export const REMOVE_ACTIVITY_CONSUMABLE_FROM_BATCH =
  "REMOVE_ACTIVITY_CONSUMABLE_FROM_BATCH";
export const ADD_ACTIVITY_CONSUMABLE_TO_BATCH =
  "ADD_ACTIVITY_CONSUMABLE_TO_BATCH";
export const ADD_ALL_ACTIVITY_CONSUMABLES_TO_BATCH =
  "ADD_ALL_ACTIVITY_CONSUMABLES_TO_BATCH";
export const UPDATE_ACTIVITY_CONSUMABLES = "UPDATE_ACTIVITY_CONSUMABLES";
export const DELETE_ACTIVITY_CONSUMABLE = "DELETE_ACTIVITY_CONSUMABLE";
export const CLONE_ACTIVITY_CONSUMABLES = "CLONE_ACTIVITY_CONSUMABLES";

//Labour Adjustment
export const GET_LABOUR_ADJUSTMENTS = "GET_LABOUR_ADJUSTMENTS";
export const GET_LABOUR_ADJUSTMENT = "GET_LABOUR_ADJUSTMENT";
export const SET_LABOUR_ADJUSTMENT_FILTER = "SET_LABOUR_ADJUSTMENT_FILTER";
export const SET_LABOUR_ADJUSTMENT_FILTER_LABEL =
  "SET_LABOUR_ADJUSTMENT_FILTER_LABEL";
export const CLEAR_LABOUR_ADJUSTMENT = "CLEAR_LABOUR_ADJUSTMENT";
export const CLEAR_LABOUR_ADJUSTMENTS = "CLEAR_LABOUR_ADJUSTMENTS";
export const SELECT_LABOUR_ADJUSTMENT = "SELECT_LABOUR_ADJUSTMENT";
export const CLEAR_LABOUR_ADJUSTMENT_BATCH = "CLEAR_LABOUR_ADJUSTMENT_BATCH";
export const REMOVE_LABOUR_ADJUSTMENT_FROM_BATCH =
  "REMOVE_LABOUR_ADJUSTMENT_FROM_BATCH";
export const ADD_LABOUR_ADJUSTMENT_TO_BATCH = "ADD_LABOUR_ADJUSTMENT_TO_BATCH";
export const ADD_ALL_LABOUR_ADJUSTMENTS_TO_BATCH =
  "ADD_ALL_LABOUR_ADJUSTMENTS_TO_BATCH";
export const UPDATE_LABOUR_ADJUSTMENTS = "UPDATE_LABOUR_ADJUSTMENTS";
export const DELETE_LABOUR_ADJUSTMENT = "DELETE_LABOUR_ADJUSTMENT";
export const CLONE_LABOUR_ADJUSTMENTS = "CLONE_LABOUR_ADJUSTMENTS";

//Scope of Repair
export const GET_SORS = "GET_SORS";
export const GET_SOR = "GET_SOR";
export const SET_SOR_FILTER = "SET_SOR_FILTER";
export const SET_SOR_FILTER_LABEL = "SET_SOR_FILTER_LABEL";
export const CLEAR_SOR = "CLEAR_SOR";
export const CLEAR_SORS = "CLEAR_SORS";
export const SELECT_SOR = "SELECT_SOR";
export const CLEAR_SOR_BATCH = "CLEAR_SOR_BATCH";
export const REMOVE_SOR_FROM_BATCH = "REMOVE_SOR_FROM_BATCH";
export const ADD_SOR_TO_BATCH = "ADD_SOR_TO_BATCH";
export const ADD_ALL_SORS_TO_BATCH = "ADD_ALL_SORS_TO_BATCH";
export const UPDATE_SORS = "UPDATE_SORS";
export const UPDATE_ALL_SORS = "UPDATE_ALL_SORS";
export const DELETE_SOR = "DELETE_SOR";
export const CLONE_SORS = "CLONE_SORS";

//Stages
export const GET_STAGES = "GET_STAGES";
export const GET_STAGE = "GET_STAGE";
export const SET_STAGE_FILTER = "SET_STAGE_FILTER";
export const SET_STAGE_FILTER_LABEL = "SET_STAGE_FILTER_LABEL";
export const CLEAR_STAGE = "CLEAR_STAGE";
export const CLEAR_STAGES = "CLEAR_STAGES";
export const SELECT_STAGE = "SELECT_STAGE";
export const CLEAR_STAGE_BATCH = "CLEAR_STAGE_BATCH";
export const REMOVE_STAGE_FROM_BATCH = "REMOVE_STAGE_FROM_BATCH";
export const ADD_STAGE_TO_BATCH = "ADD_STAGE_TO_BATCH";
export const ADD_ALL_STAGES_TO_BATCH = "ADD_ALL_STAGES_TO_BATCH";
export const UPDATE_STAGES = "UPDATE_STAGES";
export const DELETE_STAGE = "DELETE_STAGE";

//Color Groups
export const GET_COLORGROUPS = "GET_COLORGROUPS";
export const SELECT_COLORGROUP = "SELECT_COLORGROUP";
export const CLEAR_COLORGROUP = "CLEAR_COLORGROUP";
export const GET_COLORGROUP = "GET_COLORGROUP";
export const SET_COLORGROUP_FILTER = "SET_COLORGROUP_FILTER";
export const SET_COLORGROUP_FILTER_LABEL = "SET_COLORGROUP_FILTER_LABEL";
export const CLEAR_COLORGROUPS = "CLEAR_COLORGROUPS";
export const CLEAR_COLORGROUP_BATCH = "CLEAR_COLORGROUP_BATCH";
export const REMOVE_COLORGROUP_FROM_BATCH = "REMOVE_COLORGROUP_FROM_BATCH";
export const ADD_COLORGROUP_TO_BATCH = "ADD_COLORGROUP_TO_BATCH";
export const UPDATE_COLORGROUPS = "UPDATE_COLORGROUPS";
export const DELETE_COLORGROUP = "DELETE_COLORGROUP";
export const ADD_ALL_COLORGROUPS_TO_BATCH = "ADD_ALL_COLORGROUPS_TO_BATCH";
export const CLONE_COLORGROUPS = "CLONE_COLORGROUPS";
export const UPDATE_ALL_COLORGROUPS = "UPDATE_ALL_COLORGROUPS";

//Fuel Prices
export const GET_FUELPRICES = "GET_FUELPRICES";
export const SELECT_FUELPRICE = "SELECT_FUELPRICE";
export const CLEAR_FUELPRICE = "CLEAR_FUELPRICE";
export const GET_FUELPRICE = "GET_FUELPRICE";
export const SET_FUELPRICE_FILTER = "SET_FUELPRICE_FILTER";
export const SET_FUELPRICE_FILTER_LABEL = "SET_FUELPRICE_FILTER_LABEL";
export const CLEAR_FUELPRICES = "CLEAR_FUELPRICES";
export const CLEAR_FUELPRICE_BATCH = "CLEAR_FUELPRICE_BATCH";
export const REMOVE_FUELPRICE_FROM_BATCH = "REMOVE_FUELPRICE_FROM_BATCH";
export const ADD_FUELPRICE_TO_BATCH = "ADD_FUELPRICE_TO_BATCH";
export const UPDATE_FUELPRICES = "UPDATE_FUELPRICES";
export const DELETE_FUELPRICE = "DELETE_FUELPRICE";
export const ADD_ALL_FUELPRICES_TO_BATCH = "ADD_ALL_FUELPRICES_TO_BATCH";
export const CLONE_FUELPRICES = "CLONE_FUELPRICES";

//Fuel Types

export const GET_FUELTYPES = "GET_FUELTYPES";

//Primer prices
export const GET_PRIMERPRICES = "GET_PRIMERPRICES";
export const SELECT_PRIMERPRICE = "SELECT_PRIMERPRICE";
export const CLEAR_PRIMERPRICE = "CLEAR_PRIMERPRICE";
export const GET_PRIMERPRICE = "GET_PRIMERPRICE";
export const SET_PRIMERPRICE_FILTER = "SET_PRIMERPRICE_FILTER";
export const SET_PRIMERPRICE_FILTER_LABEL = "SET_PRIMERPRICE_FILTER_LABEL";
export const CLEAR_PRIMERPRICES = "CLEAR_PRIMERPRICES";
export const CLEAR_PRIMERPRICE_BATCH = "CLEAR_PRIMERPRICE_BATCH";
export const REMOVE_PRIMERPRICE_FROM_BATCH = "REMOVE_PRIMERPRICE_FROM_BATCH";
export const ADD_PRIMERPRICE_TO_BATCH = "ADD_PRIMERPRICE_TO_BATCH";
export const UPDATE_PRIMERPRICES = "UPDATE_PRIMERPRICES";
export const DELETE_PRIMERPRICE = "DELETE_PRIMERPRICE";
export const ADD_ALL_PRIMERPRICES_TO_BATCH = "ADD_ALL_PRIMERPRICES_TO_BATCH";
export const CLONE_PRIMERPRICES = "CLONE_PRIMERPRICES";

//effect & effect stages
export const SET_EFFECT_FILTER = "SET_EFFECT_FILTER";
export const SET_EFFECT_FILTER_LABEL = "SET_EFFECT_FILTER_LABEL";
export const CLEAR_EFFECT = "CLEAR_EFFECT";
export const CLEAR_EFFECTS = "CLEAR_EFFECTS";
export const SELECT_EFFECT = "SELECT_EFFECT";
export const CLEAR_EFFECT_BATCH = "CLEAR_EFFECT_BATCH";
export const REMOVE_EFFECT_FROM_BATCH = "REMOVE_EFFECT_FROM_BATCH";
export const ADD_EFFECT_TO_BATCH = "ADD_EFFECT_TO_BATCH";
export const ADD_ALL_EFFECTS_TO_BATCH = "ADD_ALL_EFFECTS_TO_BATCH";
export const UPDATE_EFFECTS = "UPDATE_EFFECTS";
export const UPDATE_ALL_EFFECTS = "UPDATE_ALL_EFFECTS";
export const DELETE_EFFECT = "DELETE_EFFECT";
export const CLONE_EFFECTS = "CLONE_EFFECTS";
export const GET_EFFECTS = "GET_EFFECTS";
export const UPDATE_EFFECT = "UPDATE_EFFECT";
export const SET_LAST_EFFECT_KEY = "SET_LAST_EFFECT_KEY";
export const ARCHIVE_EFFECT = "ARCHIVE_EFFECT";

//setup costs
export const GET_SETUPCOSTS = "GET_SETUPCOSTS";
export const CLONE_SETUPCOSTS = "CLONE_SETUPCOSTS";
export const GET_SETUPCOST = "GET_SETUPCOST";
export const SET_SETUPCOST_FILTER = "SET_SETUPCOST_FILTER";
export const SET_SETUPCOST_FILTER_LABEL = "SET_SETUPCOST_FILTER_LABEL";
export const CLEAR_SETUPCOST = "CLEAR_SETUPCOST";
export const CLEAR_SETUPCOSTS = "CLEAR_SETUPCOSTS";
export const SELECT_SETUPCOST = "SELECT_SETUPCOST";
export const CLEAR_SETUPCOST_BATCH = "CLEAR_SETUPCOST_BATCH";
export const REMOVE_SETUPCOST_FROM_BATCH = "REMOVE_SETUPCOST_FROM_BATCH";
export const ADD_SETUPCOST_TO_BATCH = "ADD_SETUPCOST_TO_BATCH";
export const ADD_ALL_SETUPCOSTS_TO_BATCH = "ADD_ALL_SETUPCOSTS_TO_BATCH";
export const UPDATE_SETUPCOSTS = "UPDATE_SETUPCOSTS";
export const UPDATE_ALL_SETUPCOSTS = "UPDATE_ALL_SETUPCOSTS";
export const DELETE_SETUPCOST = "DELETE_SETUPCOST";

//ReferencePanel
export const GET_REFERENCEPANEL = "GET_REFERENCEPANEL";
export const GET_REFERENCEQUANTITIES = "GET_REFERENCEQUANTITIES";
export const UPDATE_ALL_REFERENCEQUANTITIES = "UPDATE_ALL_REFERENCEQUANTITIES";
export const SELECT_REFERENCEQUANTITY = "SELECT_REFERENCEQUANTITY";
export const CLEAR_REFERENCEQUANTITY = "CLEAR_REFERENCEQUANTITY";
export const ADD_REFERENCEQUANTITY_TO_BATCH = "ADD_REFERENCEQUANTITY_TO_BATCH";
export const ADD_ALL_REFERENCEQUANTITIES_TO_BATCH = "ADD_ALL_REFERENCEQUANTITIES_TO_BATCH";
export const REMOVE_REFERENCEQUANTITY_FROM_BATCH = "REMOVE_REFERENCEQUANTITY_FROM_BATCH";
export const CLEAR_REFERENCEQUANTITY_BATCH = "CLEAR_REFERENCEQUANTITY_BATCH";
export const ARCHIVE_REFERENCEQUANTITY = "ARCHIVE_REFERENCEQUANTITY";
export const SET_REFERENCEQUANTITY_SEARCH_TERM = "SET_REFERENCEQUANTITY_SEARCH_TERM";
export const SET_REFERENCEQUANTITY_FILTER = "SET_REFERENCEQUANTITY_FILTER";
export const SET_REFERENCEQUANTITY_FILTER_LABEL = "SET_REFERENCEQUANTITY_FILTER_LABEL";
export const CLONE_REFERENCEQUANTITIES = "CLONE_REFERENCEQUANTITIES";

//settings
export const GET_SETTINGS = "GET_SETTINGS";

//payment transactions
export const GET_PAYMENT_TRANSACTIONS = "GET_PAYMENT_TRANSACTIONS";
export const SELECT_PAYMENT_TRANSACTION = "SELECT_PAYMENT_TRANSACTION";
export const ARCHIVE_PAYMENT_TRANSACTION = "ARCHIVE_PAYMENT_TRANSACTION";
export const CLEAR_PAYMENT_TRANSACTIONS = "CLEAR_PAYMENT_TRANSACTIONS";
export const CLEAR_PAYMENT_TRANSACTION = "CLEAR_PAYMENT_TRANSACTION";
export const SET_PAYMENT_TRANSACTION_FILTER_LABEL = "SET_PAYMENT_TRANSACTION_FILTER_LABEL";
export const SET_PAYMENT_TRANSACTION_FILTER = "SET_PAYMENT_TRANSACTION_FILTER";
export const CLONE_PAYMENT_TRANSACTIONS = "CLONE_PAYMENT_TRANSACTIONS";
export const SET_PAYMENT_TRANSACTION_SEARCH_TERM = "SET_PAYMENT_TRANSACTION_SEARCH_TERM";

//paintwizz account users
export const SELECT_ACCOUNT_USERS = "SELECT_ACCOUNT_USERS";