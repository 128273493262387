import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Input, Message, MessageContent, MessageHeader, Segment, Icon } from "semantic-ui-react";
import Select from "react-select";
import "../components.css";
import ReactDOM from "react-dom";

class UploadPOPActivateCreditsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { fileToUpload: "" };
  }

  renderError({ submitFailed, error }) {
    if (submitFailed && error) {
      return (
        <div className="ui error message">
          <div className="header">
            {error}
          </div>
        </div>
      );
    }
  }

  renderResult = () => {
    if (this.props.busySaving) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          Saving..
        </div>
      );
    } else if (this.props.saveError) {
      return (
        <div className="ui error message" style={{ marginTop: "1em" }}>
          {this.props.saveError}
        </div>
      );
    } else if (this.props.successMessage) {
      return (
        <div className="ui success message" style={{ marginTop: "1em" }}>
          {this.props.successMessage}
        </div>
      );
    }
  };

  renderInput = ({ input, id, style, meta, placeholder, disabled, label, autoFocus }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="formlabel">
          {label}
        </div>
        <Input
          {...input}
          id={id}
          style={style}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          ref={
            autoFocus
              ? input => {
                  this.focusInput = input;
                }
              : input
          }
        />
        {this.renderError(meta, id)}
      </div>
    );
  };

  getFileToUpload = ev => {
    this.setState({ fileToUpload: ev.target.files[0] });
  };

  handleChangeStatus = ({ meta, remove }, status) => {
    console.log(status, meta);
  };

  renderFileUpload = ({ id, style, meta }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className}>
        <div className="formlabel">Upload Proof of Payment</div>
        <div className="file-upload">
          <input
            style={style}
            type="file"
            id="file-input"
            accept=".png, .pdf, .jpg, .jpeg, .pdf"
            onChange={ev => this.getFileToUpload(ev)}
            required
          />
        </div>
        {this.renderError(meta, id)}
      </div>
    );
  };

  renderSelect = ({ input, id, options, meta, style, search, placeholder, isMulti, label }) => {
    const className = `field ${meta.error && meta.submitFailed ? "error" : ""}`;
    return (
      <div className={className} style={{ marginBottom: "1em" }}>
        <div className="formlabel">
          {label}
        </div>

        <Select
          {...input}
          fluid
          isMulti={isMulti}
          selection
          isSearchable={search}
          style={style}
          options={options}
          onChange={value => input.onChange(value)}
          onBlur={() => input.onBlur()}
          placeholder={placeholder}
        />
        {this.renderError(meta, id)}
      </div>
    );
  };

  onSubmit = () => {
    this.props.onFileUploadModalSubmit(this.state.fileToUpload);
  };

  displayUploadOperationStatus = () => {
    const { busySaving, saveSuccess, saveError } = this.props.apiStatus;
    if (busySaving) {
      return (
        <Message icon positive>
          <Icon name="circle notched" loading />
          <MessageContent>
            <MessageHeader>Working...</MessageHeader>
            <p>Uploading attachment to server</p>
          </MessageContent>
        </Message>
      );
    } else if (saveSuccess) {
      return (
        <Message icon positive>
          <Icon name="check circle outline" />
          <MessageContent>
            <MessageHeader>Done</MessageHeader>
            <p>
              {saveSuccess}
            </p>
          </MessageContent>
        </Message>
      );
    } else if (saveError) {
      return (
        <Message icon negative>
          <Icon name="cancel" />
          <MessageContent>
            <MessageHeader>Failed</MessageHeader>
            <p>
              {saveError}
            </p>
          </MessageContent>
        </Message>
      );
    }
  };

  renderNotice = () => {
    return (
      <div className="margin-bottom-small">
        <Message icon positive>
          <Icon name='info circle'></Icon>
          <MessageContent>
            <MessageHeader>Important</MessageHeader>
            <p>Kindly note that the system permits only one document per transaction.</p>
          </MessageContent>
        </Message>
      </div>
    )
  }

  render() {
    const { saveSuccess } = this.props.apiStatus;
    const _CancelButtonText = saveSuccess ? "Close" : "Cancel";
    return ReactDOM.createPortal(
      <div className="ui dimmer page visible active">
        <div className="ui standard modal visible active">
          <Segment padded>
            {this.renderNotice()}
            <div className="content margin-bottom-small">
              {this.renderResult()}
            </div>
            <form className="ui form error" onSubmit={this.props.handleSubmit(this.onSubmit)}>
              <Field name="pop" id="pop" label="Attach POP" component={this.renderFileUpload} />

              <div className="spacer-very-wide" />
              <div style={{ textAlign: "right" }}>
                <Button.Group>
                  <Button color="black" type="button" onClick={this.props.onFileUploadModalCancel}>
                    {_CancelButtonText}
                  </Button>
                  <Button.Or />

                  <Button type="Submit">Upload</Button>
                </Button.Group>
              </div>
            </form>
            <div className="margin-top-medium">
              {this.displayUploadOperationStatus()}
            </div>
          </Segment>
        </div>
      </div>,
      document.querySelector("#modal")
    );
  }
}

const mapStateToProps = state => {
  //if editing set the default values

  return {
    selectedPayTransaction: state.payTransactions.selected,
    apiStatus: state.api
  };
};

const validate = formValues => {
  const errors = {};
  return errors;
};

const formWrapped = reduxForm({
  form: "UploadPOPActivateCreditsForm",
  enableReinitialize: false,
  validate: validate
})(UploadPOPActivateCreditsForm);

export default connect(mapStateToProps, null)(formWrapped);
