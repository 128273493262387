import Axios from "axios";
import { env } from "./env";

export const Users = ({ email, owneronly, returnemailonly }) => {
  const _env = env();
  const baseURL = _env.CLIENT_API_URL;
  const key = _env.CLIENT_API_KEY;

  if (email) {
    return Axios.create({
      baseURL: `${baseURL}/v1/users/${email}`,
      headers: { "x-api-key": key }
    });
  } else if (owneronly) {
    if (returnemailonly) {
      return Axios.create({
        baseURL: `${baseURL}/v1/users?owneronly=1&returnemailonly=1`,
        headers: { "x-api-key": key }
      });
    } else {
      return Axios.create({
        baseURL: `${baseURL}/v1/users?owneronly=1`,
        headers: { "x-api-key": key }
      });
    }
  } else {
    return Axios.create({
      baseURL: `${baseURL}/v1/users`,
      headers: { "x-api-key": key }
    });
  }
};
