import React from "react";
import "../components.css";
import {
  clearPrimerPrice,
  setPrimerPricesFilter,
  clearPrimerPriceBatch,
  selectPrimerPrice,
  removePrimerPriceFromSelectedBatch,
  addPrimerPriceToSelectedBatch,
  addAllPrimerPricesToSelectedBatch,
  deletePrimerPrices,
  patchPrimerPriceInDb,
  updatePrimerPricesState,
  getPrimerPrices,
  clearPrimerPrices
} from "../../actions";
import { connect } from "react-redux";
import { compilePrimerPriceObject } from "../../apis/PrimerPrices";
import _ from "lodash";
import { EDIT, NEW, DELETE } from "../../actions/types";
import { Link } from "react-router-dom";
import { Confirm } from "semantic-ui-react";
import history from "../../history";
import DisplayTable from "../displayTable";
import { isMobile } from "react-device-detect";
import { removeObjectFromArrayById, filterArray } from "../../utilities";
import { SET_PRIMERPRICE_FILTER_LABEL } from "../../actions/types";

class PrimerPrices extends React.Component {
  state = {
    confirmDeleteOpen: false,
    primerPriceDeleteId: null
  };

  componentDidMount() {
    if (this.props.primerPrices.length === 0) {
      this.props.getPrimerPrices({});
    }
  }

  onPrimerPricesFilterChange = (filter) => {
    const {
      clearPrimerPrice,
      primerPriceSearchTerms,
      setPrimerPricesFilter,
      updatePrimerPricesState,
      allPrimerPrices
    } = this.props;

    const _newfilter = filter === "#clear#" ? "" : primerPriceSearchTerms.filter.label;

    if (_newfilter !== primerPriceSearchTerms.filter.value) {
      clearPrimerPrice();

      if (_newfilter !== "") {
        updatePrimerPricesState(filterArray(allPrimerPrices, "l_name", _newfilter));
      } else {
        updatePrimerPricesState(allPrimerPrices);
      }
    }
    setPrimerPricesFilter(_newfilter);
  };

  onSelectPrimerPrice = (id) => {
    const { selectPrimerPrice, primerPrices, clearPrimerPriceBatch } = this.props;
    selectPrimerPrice(primerPrices.find((primerPrice) => primerPrice.id === id));
    clearPrimerPriceBatch();
  };

  onAddPrimerPriceToBatch = (primerPrice) => {
    if (this.props.primerPriceBatch.find((item) => item === primerPrice)) {
      this.props.removePrimerPriceFromSelectedBatch(primerPrice);
    } else {
      this.props.addPrimerPriceToSelectedBatch(primerPrice);
    }
  };

  onAddAllPrimerPricesToSelectedBatch = () => {
    if (this.props.primerPriceBatch.length === this.props.primerPrices.length) {
      this.props.clearPrimerPriceBatch();
    } else {
      this.props.addAllPrimerPricesToSelectedBatch(this.props.primerPrices);
    }
  };

  onBatchDeletePrimerPrices = () => {
    const { primerPrices, primerPriceBatch, deletePrimerPrices, clearPrimerPriceBatch, clearPrimerPrice } =
      this.props;
    if (primerPriceBatch.length > 0) {
      deletePrimerPrices(primerPrices, primerPriceBatch);
    }
    clearPrimerPriceBatch();
    clearPrimerPrice();
  };

  onDeletePrimerPrice = () => {
    const { clearPrimerPrice, updatePrimerPricesState } = this.props;
    let _primerPrice = { ...this.props.selectedPrimerPrice };
    //set to inactive
    _primerPrice.active = false;
    this.props.patchPrimerPriceInDb(_primerPrice);
    clearPrimerPrice();
    updatePrimerPricesState(removeObjectFromArrayById(this.props.primerPrices, _primerPrice));
  };

  updatePrimerPrice = (primerPrice) => {
    const { patchPrimerPriceInDb } = this.props;
    let _primerPrice = compilePrimerPriceObject(
      {
        name: primerPrice.name
      },
      primerPrice.id,
      primerPrice.active
    );
    patchPrimerPriceInDb(_primerPrice);
  };

  //called from tables main menu to do ad-hoc save.
  onSaveChanges = () => {
    const _primerPrice = { ...this.props.selectedPrimerPrice };
    if (!_.isEmpty(_primerPrice)) {
      this.updatePrimerPrice(_primerPrice);
    }
  };

  renderPrimerPricesTable() {
    const menuItems = [
      {
        name: "New Price",
        key: NEW,
        icon: "add square",
        to: `/primerprices/create`,
        type: Link,
        disabled: false
      }
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit Price",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId }) => {
          history.push(`/primerprices/edit/${itemId}`);
        }
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: ({ itemId }) => {
          this.setState({
            primerPriceDeleteId: itemId,
            confirmDeleteOpen: true
          });
        }
      }
    ];

    const tableMenuActions = [
      {
        key: DELETE,
        confirmation: 1,
        text: "Delete",
        value: DELETE,
        icon: { name: "remove", color: "red" },
        idx: 0,
        action: this.onBatchDeletePrimerPrices,
        header: "Confirm Delete"
      }
    ];

    const gridColumns = "four";
    const tableColumns = [
      {
        width: "three",
        heading: "Stage",
        displayValues: [{ columnName: "stageName" }]
      },
      {
        width: "two",
        heading: "Supplier",
        displayValues: [{ columnName: "supplierName" }]
      },
      {
        width: "two",
        heading: "System",
        displayValues: [{ columnName: "systemName" }]
      },
      {
        width: "two",
        heading: "Price",
        displayValues: [{ columnName: "price" }]
      }
    ];

    const primaryColumn = {
      heading: "Description",
      width: "four",
      displayValues: [{ columnName: "name" }]
    };

    const copyConfig = {
      active: false
    };

    //check if "model panels message must be displayed"

    return (
      <>
        <DisplayTable
          idFieldName="id"
          primaryHeading="Primer Prices"
          items={this.props.primerPrices}
          selectedItemId={this.props.selectedPrimerPrice.id}
          menuItems={menuItems}
          tableMenuActions={tableMenuActions}
          itemMenuActions={itemMenuActions}
          showSearch={0}
          showSearchFilter={true}
          searchTerms={this.props.primerPriceSearchTerms}
          onSelectItem={this.onSelectPrimerPrice}
          onSearchTermChange={() => {
            return;
          }}
          onFilterChange={this.onPrimerPricesFilterChange}
          filterAction={SET_PRIMERPRICE_FILTER_LABEL}
          batchItems={this.props.primerPriceBatch}
          onAddItemToBatch={this.onAddPrimerPriceToBatch}
          onAddAllItemsToBatch={this.onAddAllPrimerPricesToSelectedBatch}
          primaryColumn={primaryColumn}
          gridColumns={gridColumns}
          tableColumns={tableColumns}
          loading={this.props.loadingData}
          copyItemsConfig={copyConfig}></DisplayTable>
      </>
    );
  }

  renderDeleteConfirm() {
    return (
      <Confirm
        open={this.state.confirmDeleteOpen}
        header="Item Actions"
        content={`Confirm Delete?`}
        onCancel={() => {
          this.setState({ confirmDeleteOpen: false });
        }}
        onConfirm={() => {
          this.setState({ confirmDeleteOpen: false });
          this.onDeletePrimerPrice();
        }}></Confirm>
    );
  }

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <>
        <div className={className}>
          <div>{this.renderPrimerPricesTable()}</div>
          {this.renderDeleteConfirm()}
          <div className="spacer-very-wide"></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    primerPriceSearchTerms: state.primerprices.primerPriceSearchTerms,
    primerPrices: state.primerprices.primerPrices,
    allPrimerPrices: state.primerprices.allPrimerPrices,
    primerPriceBatch: state.primerprices.primerPriceBatch,
    selectedPrimerPrice: state.primerprices.selectedPrimerPrice,
    loadingData: state.api.loadingData
  };
};

export default connect(mapStateToProps, {
  clearPrimerPrice,
  clearPrimerPrices,
  setPrimerPricesFilter,
  clearPrimerPriceBatch,
  selectPrimerPrice,
  removePrimerPriceFromSelectedBatch,
  addPrimerPriceToSelectedBatch,
  addAllPrimerPricesToSelectedBatch,
  deletePrimerPrices,
  patchPrimerPriceInDb,
  updatePrimerPricesState,
  getPrimerPrices
})(PrimerPrices);
