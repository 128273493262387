import React from "react";
import history from "../history";
import { Router, Route } from "react-router-dom";
import dashboardView from "../views/dashboardView";
import ResetPassword from "./authentication/ResetPassword";
import "./components.css";
import { env } from "../apis/env";
import SignIn from "./authentication/SignIn";
import modelsView from "../views/modelsView";
import partsView from "../views/partsView";
import referencePanelView from "../views/referencePanelView";
import tonersView from "../views/tonersView";
import bodyTypesView from "../views/bodyTypesView";
import stagesView from "../views/stagesView";
import consumablesView from "../views/consumablesView";
import editConsumableView from "../views/editConsumableView";
import createConsumableView from "../views/createConsumableView";
import activityConsumablesView from "../views/activityConsumablesView";
import editActivityConsumableView from "../views/editActivityConsumableView";
import createActivityConsumableView from "../views/createActivityConsumableView";
import labourAdjustmentsView from "../views/labourAdjustmentsView";
import editLabourAdjustmentView from "../views/editLabourAdjustmentView";
import createLabourAdjustmentView from "../views/createLabourAdjustmentView";
import setupCostsView from "../views/setupCostsView";
import createSetupCostView from "../views/createSetupCostView";
import editSetupCostView from "../views/editSetupCostView";
import createModelView from "../views/createModelView";
import createModelPanelView from "../views/createModelPanelView";
import editModelView from "../views/editModelView";
import editModelPanelView from "../views/editModelPanelView";
import createPartView from "../views/createPartView";
import editPartView from "../views/editPartView";
import manufacturersView from "../views/manufacturersView";
import createManufacturerView from "../views/createManufacturerView";
import editManufacturerView from "../views/editManufacturerView";
import locationsView from "../views/locationsView";
import createLocationView from "../views/createLocationView";
import editLocationView from "../views/editLocationView";
import createTonerView from "../views/createTonerView";
import editTonerView from "../views/editTonerView";
import ScrollToTop from "./ScrollToTop";
import editPaintColorView from "../views/editPaintColorView";
import paintColorsView from "../views/paintColorsView";
import createPaintColorView from "../views/createPaintColorView";
import createColorTonerView from "../views/createColorTonerView";
import editColorTonerView from "../views/editColorTonerView";
import colorGroupsView from "../views/colorGroupsView";
import fuelPricesView from "../views/fuelPricesView";
import createFuelPriceView from "../views/createFuelPriceView";
import editFuelPriceView from "../views/editFuelPriceView";
import primerPricesView from "../views/primerPricesView";
import createPrimerPriceView from "../views/createPrimerPriceView";
import editPrimerPriceView from "../views/editPrimerPriceView";
import createColorGroupView from "../views/createColorGroupView";
import editColorGroupView from "../views/editColorGroupView";
import createEffectView from "../views/createEffectView";
import editEffectView from "../views/editEffectView";
import effectsView from "../views/effectsView";
import createSORView from "../views/createSORView";
import editSORView from "../views/editSORView";
import sorsView from "../views/sorsView";
import createRefPanelQtyView from "../views/createRefPanelQtyView";
import editRefPanelQtyView from "../views/editRefPanelQtyView";
import payTransactionsView from "../views/payTransactionsView";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

class App extends React.Component {
  _env = env();

  componentDidMount() {
    document.body.style.backgroundColor = "#f4f6f8";
  }

  renderFooter() {
    const versionNumber = `Version ${this._env.VERSION}`;
    return (
      <div
        className="ui tiny green segment"
        style={{ fontWeight: "bold", marginTop: "2em" }}
      >
        <div className="ui stackable two column grid">
          <div className="eight wide column">{versionNumber}</div>
          <div
            className="eight wide column"
            style={{ textAlign: "right" }}
          >
            Designed & Developed by{" "}
            <a
              href="https://leadrocketdigital.com"
              target="_blank"
              rel="noreferrer"
            >
              Lead Rocket Digital
            </a>{" "}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ margin: "20px 20px 20px 20px" }}>
        <div className="margin-top-medium">
          <Router history={history}>
            <ScrollToTop />
            <Route
              path="/dashboard"
              component={dashboardView}
            />
            <Route
              path="/reset"
              exact
              component={ResetPassword}
            />
            <Route
              path="/"
              exact
              component={SignIn}
            />
            <Route
              path="/models"
              exact
              component={modelsView}
            />
            <Route
              path="/models/create/:manufacturerId"
              exact
              component={createModelView}
            />
            <Route
              path="/models/edit/:modelId"
              exact
              component={editModelView}
            />
            <Route
              path="/models/panels/create/:modelId"
              exact
              component={createModelPanelView}
            ></Route>
            <Route
              path="/models/panels/edit/:modelId/:panelId"
              exact
              component={editModelPanelView}
            ></Route>

            <Route
              path="/paintcolors"
              exact
              component={paintColorsView}
            />
            <Route
              path="/paintcolors/create/:manufacturerId"
              exact
              component={createPaintColorView}
            />
            <Route
              path="/paintcolors/edit/:paintcolorId"
              exact
              component={editPaintColorView}
            />
            <Route
              path="/paintcolors/toners/create/:paintcolorId"
              exact
              component={createColorTonerView}
            ></Route>
            <Route
              path="/paintcolors/toners/edit/:paintcolorId/:stageId/:colortonerId"
              exact
              component={editColorTonerView}
            ></Route>

            <Route
              path="/parts"
              exact
              component={partsView}
            ></Route>
            <Route
              path="/parts/create"
              exact
              component={createPartView}
            ></Route>
            <Route
              path="/parts/edit/:partId"
              exact
              component={editPartView}
            ></Route>
            <Route
              path="/manufacturers"
              exact
              component={manufacturersView}
            ></Route>
            <Route
              path="/manufacturers/create"
              exact
              component={createManufacturerView}
            ></Route>
            <Route
              path="/manufacturers/edit/:manufacturerId"
              exact
              component={editManufacturerView}
            ></Route>
            <Route
              path="/locations"
              exact
              component={locationsView}
            ></Route>
            <Route
              path="/locations/create"
              exact
              component={createLocationView}
            ></Route>
            <Route
              path="/locations/edit/:locationId"
              exact
              component={editLocationView}
            ></Route>

            <Route
              path="/toners"
              exact
              component={tonersView}
            ></Route>
            <Route
              path="/toners/create/:supplierId"
              exact
              component={createTonerView}
            ></Route>
            <Route
              path="/toners/edit/:skey"
              exact
              component={editTonerView}
            ></Route>
            <Route
              path="/bodytypes"
              exact
              component={bodyTypesView}
            ></Route>
            <Route
              path="/stages"
              exact
              component={stagesView}
            ></Route>
            <Route
              path="/consumables"
              exact
              component={consumablesView}
            ></Route>
            <Route
              path="/consumables/edit/:consumableId"
              exact
              component={editConsumableView}
            ></Route>
            <Route
              path="/consumables/create"
              exact
              component={createConsumableView}
            ></Route>
            <Route
              path="/activityconsumables"
              exact
              component={activityConsumablesView}
            ></Route>
            <Route
              path="/activityconsumables/edit/:activityConsumableId"
              exact
              component={editActivityConsumableView}
            ></Route>
            <Route
              path="/activityconsumables/create"
              exact
              component={createActivityConsumableView}
            ></Route>
            <Route
              path="/labouradjustments"
              exact
              component={labourAdjustmentsView}
            ></Route>
            <Route
              path="/labouradjustments/edit/:adjustmentId"
              exact
              component={editLabourAdjustmentView}
            ></Route>
            <Route
              path="/labouradjustments/create"
              exact
              component={createLabourAdjustmentView}
            ></Route>
            <Route
              path="/setupcosts"
              exact
              component={setupCostsView}
            ></Route>
            <Route
              path="/setupcosts/create"
              exact
              component={createSetupCostView}
            ></Route>
            <Route
              path="/setupcosts/edit/:setupcostId"
              exact
              component={editSetupCostView}
            ></Route>
            <Route
              path="/colorgroups"
              exact
              component={colorGroupsView}
            ></Route>
            <Route
              path="/colorgroups/create"
              exact
              component={createColorGroupView}
            ></Route>
            <Route
              path="/colorgroups/edit/:colorgroupId"
              exact
              component={editColorGroupView}
            ></Route>

            <Route
              path="/effects"
              exact
              component={effectsView}
            ></Route>
            <Route
              path="/effects/create"
              exact
              component={createEffectView}
            ></Route>
            <Route
              path="/effects/edit/:effectId"
              exact
              component={editEffectView}
            ></Route>
            <Route
              path="/sors"
              exact
              component={sorsView}
            ></Route>
            <Route
              path="/sors/create"
              exact
              component={createSORView}
            ></Route>
            <Route
              path="/sors/edit/:sorId"
              exact
              component={editSORView}
            ></Route>
            <Route
              path="/referencePanel"
              exact
              component={referencePanelView}
            ></Route>
            <Route
              path="/refpanelquantities/create"
              exact
              component={createRefPanelQtyView}
            ></Route>
            <Route
              path="/refpanelquantities/edit/:itemId"
              exact
              component={editRefPanelQtyView}
            ></Route>
            <Route
              path="/fuelprices"
              exact
              component={fuelPricesView}
            ></Route>
            <Route
              path="/fuelprices/create"
              exact
              component={createFuelPriceView}
            ></Route>
            <Route
              path="/fuelprices/edit/:fuelpriceId"
              exact
              component={editFuelPriceView}
            ></Route>
            <Route
              path="/primerprices"
              exact
              component={primerPricesView}
            ></Route>
            <Route
              path="/primerprices/create"
              exact
              component={createPrimerPriceView}
            ></Route>
            <Route
              path="/primerprices/edit/:primerpriceId"
              exact
              component={editPrimerPriceView}
            ></Route>
            <Route
              path="/payments"
              exact
              component={payTransactionsView}
            ></Route>
          </Router>
          {this.renderFooter()}
          <div className="spacer-small"></div>
        </div>
      </div>
    );
  }
}
export default App;
