import { React, useState, useEffect } from "react";
import { Input } from "semantic-ui-react";
import { useDispatch } from "react-redux";

const SearchBarFilter = ({
  onFilterChange,
  searchTerms,
  filterAction,
  showSearchFilter
}) => {
  const [filter, setFilterString] = useState("");
  const filterDisplayStyle = showSearchFilter ? "inline" : "none";

  //create dispatch method const to be able to update the redux store with filter set
  const dispatch = useDispatch();

  
  useEffect(() => {
    const timeId = setTimeout(() => {
      onFilterChange();
    }, 800);

    return () => {
      clearTimeout(timeId);
    };
    // eslint-disable-next-line
  }, [filter]);

  const clearFilter = () => {
    dispatch({ type: filterAction, payload: "" });
    onFilterChange("#clear#");
  };

  return (
    
    <div className="eight wide column" style={{ display: filterDisplayStyle }}>
      <Input
        action={{
          icon: { name: "delete", color: "red" },
          onClick: () => clearFilter(),
        }}
        fluid
        placeholder="Filter by name..."
        icon="search"
        iconPosition="left"
        className="ui-search"
        onChange={(e) => {
          //dispatch filter label value to Redux store.
          dispatch({ type: filterAction, payload: e.target.value });
          setFilterString(e.target.value);
        }}
        value={searchTerms.filter.label}
      />
    </div>
  );
};

export default SearchBarFilter;
