import React from "react";
import "../components.css";
import {
  setModelSearchTerm,
  setModelsFilter,
  getModels,
  getManufacturers,
  getBodyTypes,
  getLocations,
  selectModel,
  clearModel,
  selectModelPanel,
  clearModelPanel,
  addModelToSelectedBatch,
  removeModelFromSelectedBatch,
  clearModelBatch,
  addModelPanelToSelectedBatch,
  removeModelPanelFromSelectedBatch,
  clearModelPanelBatch,
  setSelectedModelParts,
  addAllModelPanelsToSelectedBatch,
  addAllModelsToSelectedBatch,
  deleteModels,
  patchModelInDb,
  toggleModelPanelIncludeExclude,
  setModelPanelSearchTerm,
  setModelPanelFilter,
  updateModelsState,
  updateAllModelsState,
} from "../../actions";
import { NEW, EDIT, DELETE } from "../../actions/types";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import {
  selectOptionsFromData,
  arrayDelta,
  filterArray,
  replaceArrayObjectByKey,
  removeObjectFromArrayById,
} from "../../utilities";
import { Confirm, Button } from "semantic-ui-react";
import DisplayTable from "../displayTable";
import _ from "lodash";
import history from "../../history";
import { Link } from "react-router-dom";
import { compileDataForSave } from "../../apis/Models";
import { SET_MODEL_FILTER_LABEL, SET_MODEL_PANEL_FILTER_LABEL, SAVE } from "../../actions/types";
import { toastr } from "react-redux-toastr";

class Models extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDeleteOpen: false,
      confirmOpen: false,
      copyFromModelId: null,
      saveInfoMessageVisible: false,
    };
  }

  componentDidMount() {
    const { getManufacturers, getBodyTypes, getLocations } = this.props;
    getManufacturers({});
    getBodyTypes({ all: 1 });
    getLocations({});
  }

  onModelsSearchTermChange = (term) => {
    const { setModelSearchTerm, clearModel, getModels, modelSearchTerms } = this.props;
    if (term.value !== "") {
      setModelSearchTerm(term);
      clearModel();
      if (term.value) {
        getModels({
          manufacturerid: term.value,
          name: modelSearchTerms.filter.value.toLowerCase(),
        });
      }
    }
  };

  onModelsFilterChange = (filter) => {
    const { clearModel, modelSearchTerms, setModelsFilter, updateModelsState, allModels } =
      this.props;
    const _newfilter = filter === "#clear#" ? "" : modelSearchTerms.filter.label;

    if (_newfilter !== modelSearchTerms.filter.value) {
      clearModel();

      if (_newfilter !== "") {
        updateModelsState(filterArray(allModels, "l_name", _newfilter));
      } else {
        updateModelsState(allModels);
      }
    }
    setModelsFilter(_newfilter);
  };

  onModelPanelFilterChange = (filter) => {
    const {
      selectedModel,
      setModelPanelFilter,
      clearModelPanel,
      modelPanelSearchTerms,
      setSelectedModelParts,
    } = this.props;
    const _newfilter = filter === "#clear#" ? "" : modelPanelSearchTerms.filter.label;

    if (_newfilter !== modelPanelSearchTerms.filter.value) {
      clearModelPanel();
      setSelectedModelParts(filterArray(selectedModel.allParts, "name", _newfilter));
    }

    setModelPanelFilter(_newfilter);
  };

  onModelPanelSearchTermChange = (term, filter) => {
    const {
      setModelPanelSearchTerm,
      clearModelPanel,
      modelPanelSearchTerms,
      setSelectedModelParts,
      selectedModel,
    } = this.props;
    if (filter) {
      setModelPanelSearchTerm(term);
    }
    clearModelPanel();
    if (modelPanelSearchTerms.filter.value) {
      setSelectedModelParts(filterArray(selectedModel.parts, "name", filter));
    }
  };

  //passed to ModelsList component
  onSelectModel = (id) => {
    const { clearModelPanel, selectModel, models, clearModelBatch } = this.props;
    clearModelPanel();
    selectModel(models.find((model) => model.id === id));
    clearModelBatch();
    this.setState({ copyFromModelId: null });
  };

  onAddModelToBatch = (model) => {
    if (this.props.modelBatch.find((item) => item === model)) {
      this.props.removeModelFromSelectedBatch(model);
    } else {
      this.props.addModelToSelectedBatch(model);
    }
  };

  onAddModelPanelToBatch = (panel) => {
    if (this.props.modelPanelBatch.find((modelPanel) => modelPanel === panel)) {
      this.props.removeModelPanelFromSelectedBatch(panel);
    } else {
      this.props.addModelPanelToSelectedBatch(panel);
    }
  };

  onAddAllModelsToSelectedBatch = () => {
    if (this.props.modelBatch.length === this.props.models.length) {
      this.props.clearModelBatch();
    } else {
      this.props.addAllModelsToSelectedBatch(this.props.models);
    }
  };

  onAddAllModelPanelsToSelectedBatch = () => {
    if (this.props.selectedModel.parts.length === this.props.modelPanelBatch.length) {
      this.props.clearModelPanelBatch();
    } else {
      this.props.addAllModelPanelsToSelectedBatch(this.props.selectedModel.parts);
    }
  };

  onBatchDeleteModels = () => {
    const {
      models,
      modelBatch,
      deleteModels,
      clearModelBatch,
      clearModel,
      updateModelsState,
      updateAllModelsState,
      allModels
    } = this.props;
    if (modelBatch.length > 0) {
      deleteModels(modelBatch);
    }
    clearModelBatch();
    clearModel();
    updateModelsState(arrayDelta(models, modelBatch));
    updateAllModelsState(arrayDelta(allModels, modelBatch));
  };

  onDeleteModel = () => {
    const { clearModel, updateModelsState, updateAllModelsState } = this.props;
    let _model = { ...this.props.selectedModel };
    //set to inactive
    _model.active = false;
    this.updateModel(_model);
    clearModel();
    updateModelsState(removeObjectFromArrayById(this.props.models, _model));
    updateAllModelsState(removeObjectFromArrayById(this.props.allModels, _model));
  };

  //utility update method - used from external components like views
  static _updateModel = (model) => {
    let _model = compileDataForSave(
      {
        manufacturerid: model.manufacturerId,
        modelname: model.name,
        bodytype: { value: model.body.typeId },
        subtype: { value: model.body.subtypeId },
        size: { value: model.body.size },
        year: { value: model.year },
        yearto: { value: model.yearto },
        paintcodelocation: { value: model.paintcodelocationId },
        parts: model.allParts,
      },
      { id: model.id, state: model.active }
    );
    patchModelInDb(_model);
  };

  //same as above but instance method
  updateModel = (model) => {
    const { patchModelInDb, selectModel, updateModelsState, updateAllModelsState } = this.props;
    let _model = compileDataForSave(
      {
        manufacturerid: model.manufacturerId,
        modelname: model.name,
        bodytype: { value: model.body.typeId },
        subtype: { value: model.body.subtypeId },
        size: { value: model.body.size },
        year: { value: model.year },
        yearto: { value: model.yearto },
        paintcodelocation: { value: model.paintcodelocationId },
        parts: model.allParts,
      },
      { id: model.id, state: model.active }
    );
    patchModelInDb(_model);
    //update state
    selectModel(model);
    updateModelsState(replaceArrayObjectByKey(this.props.models, "id", model));
    updateAllModelsState(replaceArrayObjectByKey(this.props.allModels, "id", model));
  };

  onBatchDeleteModelPanels = () => {
    const { clearModelPanelBatch, selectedModel, modelPanelBatch, models, allModels, updateModelsState, updateAllModelsState } = this.props;
    if (modelPanelBatch.length > 0) {
      let _model = { ...selectedModel };
      let _parts = arrayDelta(selectedModel.parts, modelPanelBatch);
      let _allParts = arrayDelta(selectedModel.allParts, modelPanelBatch);
      _model.parts = _parts;
      _model.allParts = _allParts;
      this.updateModel(_model);

      //find the selected model in models set and update state
      let _mod = models.find((model) => {
        return model.id === _model.id
      });
      
      //make sure the full set of toners is set on color object
      _mod.parts = _allParts;

      clearModelPanelBatch();
      updateModelsState(replaceArrayObjectByKey(models, "id", _mod));
      updateAllModelsState(replaceArrayObjectByKey(allModels, "id", _mod));
    }
  };

  onDeleteModelPanel = () => {
    const { clearModelPanelBatch, selectedModel, selectedModelPanel } = this.props;
    const modelPanelBatch = [];
    modelPanelBatch.push(selectedModelPanel);
    if (modelPanelBatch.length > 0) {
      let _model = { ...selectedModel };
      let _panels = arrayDelta(selectedModel.parts, modelPanelBatch);
      let _allPanels = arrayDelta(selectedModel.allParts, modelPanelBatch);
      _model.parts = _panels;
      _model.allParts = _allPanels;
      this.updateModel(_model);
      clearModelPanelBatch();
    }
  };

  //passed to PanelSizesList component
  onSelectModelPanel = (id) => {
    this.props.selectModelPanel(this.props.selectedModel.parts.find((part) => part.id === id));
  };

  onCopyFromModelConfirm = (selectedId) => {
    let _model = { ...this.props.selectedModel };
    _model.parts = [..._.find(this.props.allModels, { id: selectedId }).parts];
    _model.allParts = [..._.find(this.props.allModels, { id: selectedId }).parts];
    this.updateModel(_model);
  };

  onCopyFromModelSelectChange = (e, { value }) => {
    this.setState({ copyFromModelId: value });
  };

  getModelsWithParts = () => {
    //return all models with parts
    let modelsWithParts = [];
    if (this.props.allModels.length > 0) {
      modelsWithParts = this.props.allModels.filter((model) => model.parts.length > 0);
    }
    return modelsWithParts;
  };

  renderModelsTable() {
    const menuItems = [
      {
        name: "New Model",
        key: NEW,
        icon: "add square",
        to: `/models/create/${this.props.modelSearchTerms.term.value}`,
        type: Link,
        disabled: this.props.modelSearchTerms.term.value === "",
      },
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit Model",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId }) => {
          history.push(`/models/edit/${itemId}`);
        },
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: () => {
          this.setState({ confirmDeleteOpen: true });
        },
      },
    ];

    const tableMenuActions = [
      {
        key: DELETE,
        confirmation: 1,
        text: "Delete",
        value: DELETE,
        icon: { name: "remove", color: "red" },
        idx: 0,
        action: this.onBatchDeleteModels,
        header: "Confirm Delete",
      },
    ];

    const gridColumns = "four";
    const tableColumns = [
      {
        width: "two",
        heading: "Introduced",
        displayValues: [{ columnName: "year" }],
      },
      {
        width: "two",
        heading: "Discontinued",
        displayValues: [{ columnName: "yearto" }],
      },
      {
        width: "four",
        heading: "Vehicle Type",
        displayValues: [
          { columnName: "body.size" },
          { columnName: "body.subtypeName" },
          { columnName: "body.typeName" },
        ],
      },
    ];

    const primaryColumn = {
      heading: "Model Name",
      width: "five",
      displayValues: [{ columnName: "name" }],
    };

    const copyItemsFromExistingModel = {
      active: false,
    };

    //check if "model panels message must be displayed"

    const displaySelectModelMessage =
      this.props.models.length > 0 && !this.props.selectedModel.id ? "" : "none";
    return (
      <>
        <DisplayTable
          idFieldName="id"
          primaryHeading="Models"
          items={this.props.models}
          selectedItemId={this.props.selectedModel.id}
          menuItems={menuItems}
          tableMenuActions={tableMenuActions}
          itemMenuActions={itemMenuActions}
          showSearch={1}
          showSearchFilter={true}
          searchTerms={this.props.modelSearchTerms}
          onSelectItem={this.onSelectModel}
          onSearchTermChange={this.onModelsSearchTermChange}
          onFilterChange={this.onModelsFilterChange}
          filterAction={SET_MODEL_FILTER_LABEL}
          searchBarOptions={selectOptionsFromData(this.props.manufacturers)}
          searchBarPlaceholder="Select Manufacturer"
          batchItems={this.props.modelBatch}
          onAddItemToBatch={this.onAddModelToBatch}
          onAddAllItemsToBatch={this.onAddAllModelsToSelectedBatch}
          primaryColumn={primaryColumn}
          gridColumns={gridColumns}
          tableColumns={tableColumns}
          loading={this.props.loadingData}
          copyItemsConfig={copyItemsFromExistingModel}
        ></DisplayTable>
        <div
          style={({ textAlign: "center" }, { display: displaySelectModelMessage })}
          className="ui positive message"
        >
          <i className="big cog icon"></i>
          Select a model to display the model parts.
        </div>
      </>
    );
  }

  onSaveChanges = () => {
    try {
      const _model = { ...this.props.selectedModel };
      if (!_.isEmpty(_model)) {
        this.updateModel(_model);
      }
      this.setState({ saveInfoMessageVisible: false });
      toastr.success("Success", "Model and panels saved.", {
        icon: "success",
      });
    } catch (error) {
      toastr.error("Error", error);
    }
  };

  renderModelPanelsTable() {
    const menuItems = [
      {
        name: "Save Changes",
        key: SAVE,
        icon: "save",
        onClick: this.onSaveChanges,
        type: Button,
        disabled: this.props.selectedModel.parts
          ? this.props.selectedModel.parts.length === 0
          : true,
      },
      {
        name: "New Panel",
        key: NEW,
        icon: "add square",
        to: `/models/panels/create/${this.props.selectedModel.id}`,
        type: Link,
        disabled: false,
      },
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit Item",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId, masterId }) => {
          history.push(`/models/panels/edit/${masterId}/${itemId}`);
        },
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: this.onDeleteModelPanel,
        header: "Confirm Delete",
      },
    ];

    const tableMenuActions = [
      {
        key: DELETE,
        confirmation: 1,
        text: "Delete",
        value: DELETE,
        icon: { name: "remove", color: "red" },
        idx: 0,
        action: this.onBatchDeleteModelPanels,
      },
    ];

    const gridColumns = "five";
    const tableColumns = [
      {
        width: "two",
        heading: "Exclude?",
        displayValues: [{ columnName: "exclude" }],
        interActiveCheckBox: {
          checkedValue: "true",
          uncheckedValue: "false",
          onClick: this.togglePartIncludeExclude,
        },
        type: "Boolean",
      },
      {
        width: "two",
        heading: "Variant Of",
        displayValues: [{ columnName: "variantname" }],
      },
      {
        width: "two",
        heading: "Replaced By",
        displayValues: [{ columnName: "supersedename" }],
      },
      {
        width: "two",
        heading: "Size (SQ DM)",
        displayValues: [{ columnName: "area" }],
      },
    ];

    const primaryColumn = {
      heading: "Part Name",
      width: "four",
      displayValues: [{ columnName: "name" }],
    };

    const copyItemsFromExistingParts = {
      active: true,
      sourceData: this.getModelsWithParts(),
      action: this.onCopyFromModelSelectChange,
      onConfirm: this.onCopyFromModelConfirm,
    };

    if (this.props.selectedModel.id) {
      return (
        <>
          <DisplayTable
            idFieldName="id"
            primaryHeading="Panel Sizes"
            items={this.props.selectedModel.parts}
            selectedItemId={this.props.selectedModelPanel.id}
            masterId={this.props.selectedModel.id}
            menuItems={menuItems}
            tableMenuActions={tableMenuActions}
            itemMenuActions={itemMenuActions}
            showSearch={0}
            showSearchFilter={true}
            searchTerms={this.props.modelPanelSearchTerms}
            onSelectItem={this.onSelectModelPanel}
            onSearchTermChange={() => {
              return;
            }}
            onFilterChange={this.onModelPanelFilterChange}
            filterAction={SET_MODEL_PANEL_FILTER_LABEL}
            batchItems={this.props.modelPanelBatch}
            onAddItemToBatch={this.onAddModelPanelToBatch}
            onAddAllItemsToBatch={this.onAddAllModelPanelsToSelectedBatch}
            primaryColumn={primaryColumn}
            gridColumns={gridColumns}
            tableColumns={tableColumns}
            loading={this.props.loadingData}
            copyItemsConfig={copyItemsFromExistingParts}
            saveInfoMessageVisible={this.state.saveInfoMessageVisible}
          ></DisplayTable>
        </>
      );
    }
  }

  togglePartIncludeExclude = (id) => {
    const { selectedModel } = this.props;
    let _model = { ...selectedModel };
    let _parts = [...selectedModel.parts];
    let _allParts = [...selectedModel.allParts];
    //get the part with id from the list of parts of the currently selected model
    //and clone
    let _part = {
      ..._.find(_parts, (part) => {
        return part.id === id;
      }),
    };
    //toggle include/exclude field
    _part.exclude = _part.exclude === false ? true : false;
    //Replace part in parts list
    _parts = replaceArrayObjectByKey(_parts, "id", _part);
    _allParts = replaceArrayObjectByKey(_allParts, "id", _part);
    _model.parts = _parts;
    _model.allParts = _allParts;
    this.setState({ saveInfoMessageVisible: true }); //to show message to user to remember to save info
    this.updateModel(_model);
  };

  renderDeleteConfirm() {
    return (
      <Confirm
        open={this.state.confirmDeleteOpen}
        header="Item Actions"
        content={`Confirm Delete?`}
        onCancel={() => {
          this.setState({ confirmDeleteOpen: false });
        }}
        onConfirm={() => {
          this.setState({ confirmDeleteOpen: false });
          this.onDeleteModel();
        }}
      ></Confirm>
    );
  }

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <>
        <div className={className}>
          <div>{this.renderModelsTable()}</div>
          {this.renderDeleteConfirm()}
          <div>{this.renderModelPanelsTable()}</div>
          <div className="spacer-very-wide"></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    models: state.models.models,
    bulkCopyDone: state.models.bulkCopyDone,
    bodytypes: state.bodytypes.bodytypes,
    manufacturers: state.manufacturers.manufacturers,
    loadingData: state.api.loadingData,
    selectedModel: state.models.selectedModel,
    selectedModelPanel: state.models.selectedModelPanel,
    modelBatch: state.models.modelBatch,
    modelPanelBatch: state.models.modelPanelBatch,
    modelSearchTerms: state.models.modelSearchTerms,
    modelPanelSearchTerms: state.models.modelPanelSearchTerms,
    newModel: state.models.newModel,
    allModels: state.models.allModels,
  };
};

export default connect(mapStateToProps, {
  setModelSearchTerm,
  setModelPanelFilter,
  setModelsFilter,
  getModels,
  updateModelsState,
  updateAllModelsState,
  getManufacturers,
  selectModel,
  clearModel,
  selectModelPanel,
  clearModelPanel,
  addModelToSelectedBatch,
  addAllModelsToSelectedBatch,
  removeModelFromSelectedBatch,
  clearModelBatch,
  addModelPanelToSelectedBatch,
  addAllModelPanelsToSelectedBatch,
  removeModelPanelFromSelectedBatch,
  clearModelPanelBatch,
  setSelectedModelParts,
  deleteModels,
  patchModelInDb,
  toggleModelPanelIncludeExclude,
  setModelPanelSearchTerm,
  getBodyTypes,
  getLocations,
})(Models);
