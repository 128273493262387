export const VEHICLES = "Vehicles";
export const PARTS = "Parts";
export const MODELS = "Models";
export const BODYTYPES = "Body Types";
export const MANUFACTURERS = "Manufacturers";
export const LOCATIONS = "Paint Code Locations";
export const TONERS = "Toners";
export const ACTIVITY_CONSUMABLES = "Activity Consumables";
export const LABOUR_ADJUSTMENTS = "Labour Adjustments";
export const PAINT = "Paint";
export const STAGES = "Stages";
export const REPAIRS = "Repairs";
export const CONSUMABLES = "Consumables";
export const PAINTFORMULAS = "Formulas";
export const SETUPCOSTS = "Setup Costs";
export const COLORGROUPS = "Color Groups";
export const EFFECTS = "Effects";
export const SORs = "Scopes of Repair";
export const REFERENCEPANEL = "Reference Panel";
export const FUELPRICES = "Fuel Prices"; 
export const PRIMERPRICES = "Primer Prices"; 
export const CLIENTS = "Clients";
export const ALLOCATE_PAYMENT = "Manage Payments";