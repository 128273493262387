import React from "react";
import "../components.css";
import {
  clearColorGroup,
  setColorGroupsFilter,
  clearColorGroupBatch,
  selectColorGroup,
  removeColorGroupFromSelectedBatch,
  addColorGroupToSelectedBatch,
  addAllColorGroupsToSelectedBatch,
  deleteColorGroups,
  patchColorGroupInDb,
  updateColorGroupsState,
  getColorGroups,
  clearColorGroups,
} from "../../actions";
import { connect } from "react-redux";
import { compileColorGroupObject } from "../../apis/ColorGroups";
import _ from "lodash";
import { SAVE, EDIT, NEW, DELETE } from "../../actions/types";
import { Link } from "react-router-dom";
import { Button, Confirm } from "semantic-ui-react";
import history from "../../history";
import DisplayTable from "../displayTable";
import { isMobile } from "react-device-detect";
import {
  removeObjectFromArrayById,
  filterArrayOnConcatenatedKeys,
} from "../../utilities";
import { SET_COLORGROUP_FILTER_LABEL } from "../../actions/types";

class ColorGroups extends React.Component {
  state = {
    confirmDeleteOpen: false,
    colorGroupDeleteId: null,
  };

  componentDidMount() {
    if (this.props.colorGroups.length === 0) {
      this.props.getColorGroups({});
    }
  }

  onColorGroupsFilterChange = (filter) => {
    const {
      clearColorGroup,
      colorGroupSearchTerms,
      setColorGroupsFilter,
      updateColorGroupsState,
      allColorGroups,
    } = this.props;
    const _filter =
      filter !== undefined ? filter : colorGroupSearchTerms.filter.label;
    if (_filter !== colorGroupSearchTerms.filter.value) {
      clearColorGroup();
      if (_filter !== "") {
        let _colorGroups = [...allColorGroups];
        const _filteredColorGroups = [
          ...filterArrayOnConcatenatedKeys(_colorGroups, ["l_name"], _filter),
        ];
        updateColorGroupsState(_filteredColorGroups);
      } else {
        updateColorGroupsState([...allColorGroups]);
      }
    }
    setColorGroupsFilter(_filter);
  };

  onSelectColorGroup = (id) => {
    const { selectColorGroup, colorGroups, clearColorGroupBatch } = this.props;
    selectColorGroup(colorGroups.find((colorGroup) => colorGroup.id === id));
    clearColorGroupBatch();
  };

  onAddColorGroupToBatch = (colorGroup) => {
    if (this.props.colorGroupBatch.find((item) => item === colorGroup)) {
      this.props.removeColorGroupFromSelectedBatch(colorGroup);
    } else {
      this.props.addColorGroupToSelectedBatch(colorGroup);
    }
  };

  onAddAllColorGroupsToSelectedBatch = () => {
    if (this.props.colorGroupBatch.length === this.props.colorGroups.length) {
      this.props.clearColorGroupBatch();
    } else {
      this.props.addAllColorGroupsToSelectedBatch(this.props.colorGroups);
    }
  };

  onBatchDeleteColorGroups = () => {
    const {
      colorGroups,
      colorGroupBatch,
      deleteColorGroups,
      clearColorGroupBatch,
      clearColorGroup,
    } = this.props;
    if (colorGroupBatch.length > 0) {
      deleteColorGroups(colorGroups, colorGroupBatch);
    }
    clearColorGroupBatch();
    clearColorGroup();
  };

  onDeleteColorGroup = () => {
    const { clearColorGroup, updateColorGroupsState } = this.props;
    let _colorGroup = { ...this.props.selectedColorGroup };
    //set to inactive
    _colorGroup.active = false;
    this.updateColorGroup(_colorGroup);
    clearColorGroup();
    updateColorGroupsState(
      removeObjectFromArrayById(this.props.colorGroups, _colorGroup)
    );
  };

  updateColorGroup = (colorGroup) => {
    const { patchColorGroupInDb } = this.props;
    let _colorGroup = compileColorGroupObject(
      {
        name: colorGroup.name,
      },
      colorGroup.id,
      colorGroup.active
    );
    patchColorGroupInDb(_colorGroup);
  };

  //called from tables main menu to do ad-hoc save.
  onSaveChanges = () => {
    const _colorGroup = { ...this.props.selectedColorGroup };
    if (!_.isEmpty(_colorGroup)) {
      this.updateColorGroup(_colorGroup);
    }
  };

  renderColorGroupsTable() {
    const menuItems = [
      {
        name: "Save Changes",
        key: SAVE,
        icon: "save",
        onClick: this.onSaveChanges,
        type: Button,
        disabled: this.props.colorGroups.length === 0,
      },
      {
        name: "New",
        key: NEW,
        icon: "add square",
        to: `/colorgroups/create`,
        type: Link,
        disabled: false,
      },
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit Group",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId }) => {
          history.push(`/colorgroups/edit/${itemId}`);
        },
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: ({ itemId }) => {
          this.setState({
            colorGroupDeleteId: itemId,
            confirmDeleteOpen: true,
          });
        },
      },
    ];

    const tableMenuActions = [
      {
        key: DELETE,
        confirmation: 1,
        text: "Delete",
        value: DELETE,
        icon: { name: "remove", color: "red" },
        idx: 0,
        action: this.onBatchDeleteColorGroups,
        header: "Confirm Delete",
      },
    ];

    const gridColumns = "one";
    const tableColumns = [];

    const primaryColumn = {
      heading: "Group Name",
      width: "four",
      displayValues: [{ columnName: "name" }],
    };

    const copyConfig = {
      active: false,
    };

    //check if "model panels message must be displayed"

    return (
      <>
        <DisplayTable
          idFieldName="id"
          primaryHeading="Paint Color Groups"
          items={this.props.colorGroups}
          selectedItemId={this.props.selectedColorGroup.id}
          menuItems={menuItems}
          tableMenuActions={tableMenuActions}
          itemMenuActions={itemMenuActions}
          showSearch={0}
          showSearchFilter={true}
          searchTerms={this.props.colorGroupSearchTerms}
          onSelectItem={this.onSelectColorGroup}
          onSearchTermChange={() => {
            return;
          }}
          onFilterChange={this.onColorGroupsFilterChange}
          filterAction={SET_COLORGROUP_FILTER_LABEL}
          batchItems={this.props.colorGroupBatch}
          onAddItemToBatch={this.onAddColorGroupToBatch}
          onAddAllItemsToBatch={this.onAddAllColorGroupsToSelectedBatch}
          primaryColumn={primaryColumn}
          gridColumns={gridColumns}
          tableColumns={tableColumns}
          loading={this.props.loadingData}
          copyItemsConfig={copyConfig}
        ></DisplayTable>
      </>
    );
  }

  renderDeleteConfirm() {
    return (
      <Confirm
        open={this.state.confirmDeleteOpen}
        header="Item Actions"
        content={`Confirm Delete?`}
        onCancel={() => {
          this.setState({ confirmDeleteOpen: false });
        }}
        onConfirm={() => {
          this.setState({ confirmDeleteOpen: false });
          this.onDeleteColorGroup();
        }}
      ></Confirm>
    );
  }

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <>
        <div className={className}>
          <div>{this.renderColorGroupsTable()}</div>
          {this.renderDeleteConfirm()}
          <div className="spacer-very-wide"></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    colorGroupSearchTerms: state.colorgroups.colorGroupSearchTerms,
    colorGroups: state.colorgroups.colorGroups,
    allColorGroups: state.colorgroups.allColorGroups,
    colorGroupBatch: state.colorgroups.colorGroupBatch,
    selectedColorGroup: state.colorgroups.selectedColorGroup,
    loadingData: state.api.loadingData,
  };
};

export default connect(mapStateToProps, {
  clearColorGroup,
  clearColorGroups,
  setColorGroupsFilter,
  clearColorGroupBatch,
  selectColorGroup,
  removeColorGroupFromSelectedBatch,
  addColorGroupToSelectedBatch,
  addAllColorGroupsToSelectedBatch,
  deleteColorGroups,
  patchColorGroupInDb,
  updateColorGroupsState,
  getColorGroups,
})(ColorGroups);
