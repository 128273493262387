import Axios from "axios";
import { env } from "./env";

export const Consumables = ({ all, id, name, consumableIds }) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;

  if (all) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/consumables?all=1`,
      headers: { "x-api-key": key },
    });
  } else if (id) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/consumables/${id}`,
      headers: { "x-api-key": key },
    });
  } else if (name) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/consumables?name=${name}`,
      headers: { "x-api-key": key },
    });
  } else if (consumableIds) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/consumables?ids=${consumableIds}`,
      headers: { "x-api-key": key },
    });
  }

  return Axios.create({
    baseURL: `${baseURL}/repairs/consumables`,
    headers: { "x-api-key": key },
  });
};

export const compileDataForSave = (
  { consumablename, price, unit },
  id,
  state
) => {
  const consumable = {};
  consumable.id = id;
  consumable.name = consumablename;
  consumable.l_name = consumablename.toLowerCase();
  consumable.price = parseFloat(price);
  consumable.unit = unit.value ? unit.value : unit;
  consumable.active = state;
  return consumable;
};

