import React from "react";
import "../components.css";
import {
  clearSetupCost,
  setSetupCostsFilter,
  clearSetupCostBatch,
  selectSetupCost,
  removeSetupCostFromSelectedBatch,
  addSetupCostToSelectedBatch,
  addAllSetupCostsToSelectedBatch,
  deleteSetupCosts,
  patchSetupCostInDb,
  updateSetupCostsState,
  getSetupCosts,
  clearSetupCosts,
} from "../../actions";
import { connect } from "react-redux";
import { compileSetupCostObject } from "../../apis/SetupCosts";
import _ from "lodash";
import { SAVE, EDIT, NEW, DELETE } from "../../actions/types";
import { Link } from "react-router-dom";
import { Button, Confirm } from "semantic-ui-react";
import history from "../../history";
import DisplayTable from "../displayTable";
import { isMobile } from "react-device-detect";
import {
  removeObjectFromArrayById,
  filterArrayOnConcatenatedKeys,
} from "../../utilities";
import { SET_SETUPCOST_FILTER_LABEL } from "../../actions/types";

class SetupCosts extends React.Component {
  state = {
    confirmDeleteOpen: false,
    setupCostDeleteId: null,
  };

  componentDidMount() {
    if (this.props.setupCosts.length === 0) {
      this.props.getSetupCosts({});
    }
  }

  onSetupCostsFilterChange = (filter) => {
    const {
      clearSetupCost,
      setupCostSearchTerms,
      setSetupCostsFilter,
      updateSetupCostsState,
      allsetupCosts,
    } = this.props;
    const _filter =
      filter !== undefined ? filter : setupCostSearchTerms.filter.label;
    if (_filter !== setupCostSearchTerms.filter.value) {
      clearSetupCost();
      if (_filter !== "") {
        let _setupCosts = [...allsetupCosts];
        const _filteredSetupCosts = [
          ...filterArrayOnConcatenatedKeys(_setupCosts, ["l_name"], _filter),
        ];
        updateSetupCostsState(_filteredSetupCosts);
      } else {
        updateSetupCostsState([...allsetupCosts]);
      }
    }
    setSetupCostsFilter(_filter);
  };

  onSelectSetupCost = (id) => {
    const { selectSetupCost, setupCosts, clearSetupCostBatch } = this.props;
    selectSetupCost(setupCosts.find((setupCost) => setupCost.id === id));
    clearSetupCostBatch();
  };

  onAddSetupCostToBatch = (setupCost) => {
    if (this.props.setupCostBatch.find((item) => item === setupCost)) {
      this.props.removeSetupCostFromSelectedBatch(setupCost);
    } else {
      this.props.addSetupCostToSelectedBatch(setupCost);
    }
  };

  onAddAllSetupCostsToSelectedBatch = () => {
    if (this.props.setupCostBatch.length === this.props.setupCosts.length) {
      this.props.clearSetupCostBatch();
    } else {
      this.props.addAllSetupCostsToSelectedBatch(this.props.setupCosts);
    }
  };

  onBatchDeleteSetupCosts = () => {
    const {
      setupCosts,
      setupCostBatch,
      deleteSetupCosts,
      clearSetupCostBatch,
      clearSetupCost,
    } = this.props;
    if (setupCostBatch.length > 0) {
      deleteSetupCosts(setupCosts, setupCostBatch);
    }
    clearSetupCostBatch();
    clearSetupCost();
  };

  onDeleteSetupCost = () => {
    const { clearSetupCost, updateSetupCostsState } = this.props;
    let _setupCost = { ...this.props.selectedSetupCost };
    //set to inactive
    _setupCost.active = false;
    this.updateSetupCost(_setupCost);
    clearSetupCost();
    updateSetupCostsState(
      removeObjectFromArrayById(this.props.setupCosts, _setupCost)
    );
  };

  updateSetupCost = (setupCost) => {
    const { patchSetupCostInDb } = this.props;
    let _setupCost = compileSetupCostObject(
      {
        name: setupCost.name,
        labour: setupCost.labour,
      },
      setupCost.id,
      setupCost.active
    );
    patchSetupCostInDb(_setupCost);
  };

  //called from tables main menu to do ad-hoc save.
  onSaveChanges = () => {
    const _setupCost = { ...this.props.selectedSetupCost };
    if (!_.isEmpty(_setupCost)) {
      this.updateSetupCost(_setupCost);
    }
  };

  renderSetupCostsTable() {
    const menuItems = [
      {
        name: "Save Changes",
        key: SAVE,
        icon: "save",
        onClick: this.onSaveChanges,
        type: Button,
        disabled: this.props.setupCosts.length === 0,
      },
      {
        name: "New",
        key: NEW,
        icon: "add square",
        to: `/setupcosts/create`,
        type: Link,
        disabled: false,
      },
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit Cost",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId }) => {
          history.push(`/setupcosts/edit/${itemId}`);
        },
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: ({ itemId }) => {
          this.setState({
            setupCostDeleteId: itemId,
            confirmDeleteOpen: true,
          });
        },
      },
    ];

    const tableMenuActions = [
      {
        key: DELETE,
        confirmation: 1,
        text: "Delete",
        value: DELETE,
        icon: { name: "remove", color: "red" },
        idx: 0,
        action: this.onBatchDeleteSetupCosts,
        header: "Confirm Delete",
      },
    ];

    const gridColumns = "one";
    const tableColumns = [
      {
        width: "four",
        heading: "Labour Units",
        displayValues: [{ columnName: "labour" }],
      },
    ];

    const primaryColumn = {
      heading: "Setup Cost Name",
      width: "four",
      displayValues: [{ columnName: "name" }],
    };

    const copyConfig = {
      active: false,
    };

    //check if "model panels message must be displayed"

    return (
      <>
        <DisplayTable
          idFieldName="id"
          primaryHeading="Repair Setup Costs"
          items={this.props.setupCosts}
          selectedItemId={this.props.selectedSetupCost.id}
          menuItems={menuItems}
          tableMenuActions={tableMenuActions}
          itemMenuActions={itemMenuActions}
          showSearch={0}
          showSearchFilter={true}
          searchTerms={this.props.setupCostSearchTerms}
          onSelectItem={this.onSelectSetupCost}
          onSearchTermChange={() => {
            return;
          }}
          onFilterChange={this.onSetupCostsFilterChange}
          filterAction={SET_SETUPCOST_FILTER_LABEL}
          batchItems={this.props.setupCostBatch}
          onAddItemToBatch={this.onAddSetupCostToBatch}
          onAddAllItemsToBatch={this.onAddAllSetupCostsToSelectedBatch}
          primaryColumn={primaryColumn}
          gridColumns={gridColumns}
          tableColumns={tableColumns}
          loading={this.props.loadingData}
          copyItemsConfig={copyConfig}
        ></DisplayTable>
      </>
    );
  }

  renderDeleteConfirm() {
    return (
      <Confirm
        open={this.state.confirmDeleteOpen}
        header="Item Actions"
        content={`Confirm Delete?`}
        onCancel={() => {
          this.setState({ confirmDeleteOpen: false });
        }}
        onConfirm={() => {
          this.setState({ confirmDeleteOpen: false });
          this.onDeleteSetupCost();
        }}
      ></Confirm>
    );
  }

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <>
        <div className={className}>
        <div className="ui info message">
            <div class="header">Usage Instructions</div>
            <ul class="list">
              <li>Used to specify the setup costs for a repair job.</li>
              <li>
                Each of the labour times specified will only be applied to a repair job once.
              </li>
            </ul>
          </div>
          <div>{this.renderSetupCostsTable()}</div>
          {this.renderDeleteConfirm()}
          <div className="spacer-very-wide"></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    setupCostSearchTerms: state.setupcosts.setupCostSearchTerms,
    setupCosts: state.setupcosts.setupCosts,
    allsetupCosts: state.setupcosts.allsetupCosts,
    setupCostBatch: state.setupcosts.setupCostBatch,
    selectedSetupCost: state.setupcosts.selectedSetupCost,
    loadingData: state.api.loadingData,
  };
};

export default connect(mapStateToProps, {
  clearSetupCost,
  clearSetupCosts,
  setSetupCostsFilter,
  clearSetupCostBatch,
  selectSetupCost,
  removeSetupCostFromSelectedBatch,
  addSetupCostToSelectedBatch,
  addAllSetupCostsToSelectedBatch,
  deleteSetupCosts,
  patchSetupCostInDb,
  updateSetupCostsState,
  getSetupCosts,
})(SetupCosts);
