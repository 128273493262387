import Axios from "axios";
import { env } from "./env";

export const PaymentTransactions = (email) => {
  const _env = env();
  const baseURL = _env.CLIENT_API_URL;
  const key = _env.CLIENT_API_KEY;

  return Axios.create({
    baseURL: `${baseURL}/v1/payments?email=${email}&current=1`,
    headers: { "x-api-key": key }
  });
};

export const ActivateCredits = (id) => {
  const _env = env();
  const baseURL = _env.CLIENT_API_URL;
  const key = _env.CLIENT_API_KEY;

  return Axios.create({
    baseURL: `${baseURL}/v1/activatecredits/${id}`,
    
    headers: { "x-api-key": key }
  });
}

export const SignedURL = () => {
  const _env = env();
  const baseURL = _env.CLIENT_API_URL;
  const key = _env.CLIENT_API_KEY;

  return Axios.create({
    baseURL: `${baseURL}/v1/pops/getsignedurl`,
    headers: { "x-api-key": key }
  });
}
