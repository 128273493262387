import {
  SIGN_IN,
  SET_USER_FIRSTNAME,
  SIGN_OUT,
  FAIL_SIGN_IN,
  SIGNED_UP,
  SIGN_UP,
  FAIL_SIGNUP,
  SET_RESET_USERNAME,
  RESET_SIGN_IN,
  BUSY_SAVING,
  SAVED_RECORD,
  SAVE_FAILED,
  CANCEL_SAVE,
  GET_MODELS,
  CLONE_MODELS,
  GET_BODYTYPES,
  GET_MANUFACTURERS,
  CLONE_MANUFACTURERS,
  LOADING_DATA,
  DATA_LOADED,
  CLEAR_MODEL,
  SELECT_MODEL,
  GET_BODYSUBTYPES,
  CLEAR_MODEL_PANEL,
  SELECT_MODEL_PANEL,
  GET_PARTS,
  SELECT_MANUFACTURER,
  ADD_MODEL_TO_BATCH,
  REMOVE_MODEL_FROM_BATCH,
  CLEAR_MODEL_BATCH,
  SET_MODEL_PARTS,
  ADD_MODEL_PANEL_TO_BATCH,
  REMOVE_MODEL_PANEL_FROM_BATCH,
  CLEAR_MODEL_PANEL_BATCH,
  ADD_ALL_MODELS_TO_BATCH,
  ADD_ALL_MODEL_PANELS_TO_BATCH,
  SET_MODEL_SEARCH_TERM,
  SET_MODEL_PANEL_FILTER,
  SET_MODEL_PANEL_SEARCH_TERM,
  SET_MODEL_PANEL_INCLUDE_EXCLUDE,
  UPDATE_MODELS,
  SET_MODEL_FILTER,
  SET_MODEL_FILTER_LABEL,
  SELECT_PART,
  CLEAR_PART,
  CLEAR_PART_BATCH,
  SET_PART_FILTER,
  REMOVE_PART_FROM_BATCH,
  ADD_PART_TO_BATCH,
  ADD_ALL_PARTS_TO_BATCH,
  CLEAR_PARTS,
  DELETE_PART,
  UPDATE_PARTS,
  UPDATE_ALL_PARTS,
  CLONE_PARTS,
  SET_PART_FILTER_LABEL,
  CLEAR_MANUFACTURER,
  SET_MANUFACTURER_FILTER,
  CLEAR_MANUFACTURER_BATCH,
  REMOVE_MANUFACTURER_FROM_BATCH,
  ADD_MANUFACTURER_TO_BATCH,
  ADD_ALL_MANUFACTURERS_TO_BATCH,
  UPDATE_MANUFACTURERS,
  UPDATE_ALL_MANUFACTURERS,
  DELETE_MANUFACTURER,
  CLEAR_MANUFACTURERS,
  SELECT_TONER,
  SELECT_TONERS,
  CLEAR_TONER,
  SET_TONER_FILTER,
  CLEAR_TONER_BATCH,
  REMOVE_TONER_FROM_BATCH,
  ADD_TONER_TO_BATCH,
  ADD_ALL_TONERS_TO_BATCH,
  UPDATE_TONERS,
  GET_TONERS,
  CLONE_TONERS,
  CLEAR_TONERS,
  GET_SYSTEMS,
  GET_SUPPLIERS,
  SELECT_SUPPLIER,
  SET_TONER_SEARCH_TERM,
  GET_TONER_CONTAINER_METRICS,
  SET_TONER_CALCULATED_VOL,
  CLEAR_CONSUMABLE,
  SET_CONSUMABLE_FILTER,
  CLEAR_CONSUMABLE_BATCH,
  REMOVE_CONSUMABLE_FROM_BATCH,
  ADD_CONSUMABLE_TO_BATCH,
  ADD_ALL_CONSUMABLES_TO_BATCH,
  UPDATE_CONSUMABLES,
  UPDATE_ALL_CONSUMABLES,
  DELETE_CONSUMABLE,
  CLEAR_CONSUMABLES,
  SELECT_CONSUMABLE,
  GET_CONSUMABLES,
  CLEAR_ACTIVITY_CONSUMABLE,
  SET_ACTIVITY_CONSUMABLE_FILTER,
  CLEAR_ACTIVITY_CONSUMABLE_BATCH,
  REMOVE_ACTIVITY_CONSUMABLE_FROM_BATCH,
  ADD_ACTIVITY_CONSUMABLE_TO_BATCH,
  ADD_ALL_ACTIVITY_CONSUMABLES_TO_BATCH,
  UPDATE_ACTIVITY_CONSUMABLES,
  DELETE_ACTIVITY_CONSUMABLE,
  CLEAR_ACTIVITY_CONSUMABLES,
  SELECT_ACTIVITY_CONSUMABLE,
  GET_ACTIVITY_CONSUMABLES,
  CLONE_ACTIVITY_CONSUMABLES,
  CLEAR_LABOUR_ADJUSTMENT,
  SET_LABOUR_ADJUSTMENT_FILTER,
  CLEAR_LABOUR_ADJUSTMENT_BATCH,
  REMOVE_LABOUR_ADJUSTMENT_FROM_BATCH,
  ADD_LABOUR_ADJUSTMENT_TO_BATCH,
  ADD_ALL_LABOUR_ADJUSTMENTS_TO_BATCH,
  UPDATE_LABOUR_ADJUSTMENTS,
  DELETE_LABOUR_ADJUSTMENT,
  CLEAR_LABOUR_ADJUSTMENTS,
  SELECT_LABOUR_ADJUSTMENT,
  GET_LABOUR_ADJUSTMENTS,
  CLONE_LABOUR_ADJUSTMENTS,
  CLEAR_SOR,
  SET_SOR_FILTER,
  CLEAR_SOR_BATCH,
  REMOVE_SOR_FROM_BATCH,
  ADD_SOR_TO_BATCH,
  ADD_ALL_SORS_TO_BATCH,
  UPDATE_SORS,
  UPDATE_ALL_SORS,
  DELETE_SOR,
  CLEAR_SORS,
  SELECT_SOR,
  GET_SORS,
  CLEAR_STAGE,
  SET_STAGE_FILTER,
  CLEAR_STAGE_BATCH,
  REMOVE_STAGE_FROM_BATCH,
  ADD_STAGE_TO_BATCH,
  ADD_ALL_STAGES_TO_BATCH,
  UPDATE_STAGES,
  DELETE_STAGE,
  CLEAR_STAGES,
  SELECT_STAGE,
  GET_STAGES,
  GET_PAINTCOLORS,
  UPDATE_PAINTCOLORS,
  CLONE_PAINTCOLORS,
  SELECT_PAINTCOLOR,
  SET_PAINTCOLOR_SEARCH_TERM,
  SET_PAINTCOLOR_FILTER,
  SET_PAINTCOLOR_FILTER_LABEL,
  ADD_PAINTCOLOR_TO_BATCH,
  ADD_ALL_PAINTCOLORS_TO_BATCH,
  REMOVE_PAINTCOLOR_FROM_BATCH,
  CLEAR_PAINTCOLOR_BATCH,
  CLEAR_PAINTCOLOR,
  CLEAR_COLOR_TONER_BATCH,
  SET_COLOR_TONER_SEARCH_TERM,
  SET_COLOR_TONER_FILTER,
  ADD_COLOR_TONER_TO_BATCH,
  ADD_ALL_COLOR_TONERS_TO_BATCH,
  REMOVE_COLOR_TONER_FROM_BATCH,
  SELECT_COLOR_TONER,
  CLEAR_COLOR_TONER,
  SET_COLOR_TONERS,
  GET_EFFECTS,
  CLONE_EFFECTS,
  UPDATE_EFFECTS,
  UPDATE_ALL_EFFECTS,
  SELECT_EFFECT,
  SET_EFFECT_FILTER,
  SET_EFFECT_FILTER_LABEL,
  ADD_EFFECT_TO_BATCH,
  ADD_ALL_EFFECTS_TO_BATCH,
  REMOVE_EFFECT_FROM_BATCH,
  CLEAR_EFFECT_BATCH,
  CLEAR_EFFECT,
  DELETE_EFFECT,
  CLEAR_EFFECTS,
  UPDATE_ALL_PAINTCOLORS,
  CLEAR_SETUPCOST,
  SET_SETUPCOST_FILTER,
  CLEAR_SETUPCOST_BATCH,
  REMOVE_SETUPCOST_FROM_BATCH,
  ADD_SETUPCOST_TO_BATCH,
  ADD_ALL_SETUPCOSTS_TO_BATCH,
  UPDATE_SETUPCOSTS,
  UPDATE_ALL_SETUPCOSTS,
  DELETE_SETUPCOST,
  CLEAR_SETUPCOSTS,
  GET_SETUPCOSTS,
  CLONE_SETUPCOSTS,
  SELECT_SETUPCOST,
  GET_COLORGROUPS,
  CLEAR_COLORGROUP,
  SET_COLORGROUP_FILTER,
  CLEAR_COLORGROUP_BATCH,
  REMOVE_COLORGROUP_FROM_BATCH,
  ADD_COLORGROUP_TO_BATCH,
  ADD_ALL_COLORGROUPS_TO_BATCH,
  UPDATE_COLORGROUPS,
  UPDATE_ALL_COLORGROUPS,
  DELETE_COLORGROUP,
  CLEAR_COLORGROUPS,
  CLONE_COLORGROUPS,
  SELECT_COLORGROUP,
  CLEAR_FUELPRICE,
  SET_FUELPRICE_FILTER,
  CLEAR_FUELPRICE_BATCH,
  REMOVE_FUELPRICE_FROM_BATCH,
  ADD_FUELPRICE_TO_BATCH,
  ADD_ALL_FUELPRICES_TO_BATCH,
  UPDATE_FUELPRICES,
  DELETE_FUELPRICE,
  CLEAR_FUELPRICES,
  CLONE_FUELPRICES,
  SELECT_FUELPRICE,
  GET_FUELPRICES,
  CLEAR_PRIMERPRICE,
  SET_PRIMERPRICE_FILTER,
  CLEAR_PRIMERPRICE_BATCH,
  REMOVE_PRIMERPRICE_FROM_BATCH,
  ADD_PRIMERPRICE_TO_BATCH,
  ADD_ALL_PRIMERPRICES_TO_BATCH,
  UPDATE_PRIMERPRICES,
  DELETE_PRIMERPRICE,
  CLEAR_PRIMERPRICES,
  CLONE_PRIMERPRICES,
  SELECT_PRIMERPRICE,
  GET_PRIMERPRICES,
  UPDATE_ALL_MODELS,
  GET_REFERENCEPANEL,
  GET_REFERENCEQUANTITIES,
  UPDATE_ALL_REFERENCEQUANTITIES,
  SELECT_REFERENCEQUANTITY,
  CLEAR_REFERENCEQUANTITY,
  ADD_REFERENCEQUANTITY_TO_BATCH,
  ADD_ALL_REFERENCEQUANTITIES_TO_BATCH,
  REMOVE_REFERENCEQUANTITY_FROM_BATCH,
  CLEAR_REFERENCEQUANTITY_BATCH,
  SET_REFERENCEQUANTITY_SEARCH_TERM,
  SET_REFERENCEQUANTITY_FILTER,
  SET_REFERENCEQUANTITY_FILTER_LABEL,
  CLONE_REFERENCEQUANTITIES,
  GET_FUELTYPES,
  GET_SETTINGS,
  UPDATE_ALL_TONERS,
  CLONE_SORS,
  CLEAR_LOCATION,
  SET_LOCATION_FILTER,
  CLEAR_LOCATION_BATCH,
  REMOVE_LOCATION_FROM_BATCH,
  ADD_LOCATION_TO_BATCH,
  ADD_ALL_LOCATIONS_TO_BATCH,
  UPDATE_LOCATIONS,
  UPDATE_ALL_LOCATIONS,
  DELETE_LOCATION,
  CLEAR_LOCATIONS,
  SELECT_LOCATION,
  GET_LOCATIONS,
  CLONE_LOCATIONS,
  CLONE_CONSUMABLES,
  GET_PAYMENT_TRANSACTIONS,
  CLEAR_PAYMENT_TRANSACTIONS,
  SELECT_PAYMENT_TRANSACTION,
  SET_PAYMENT_TRANSACTION_FILTER,
  CLONE_PAYMENT_TRANSACTIONS,
  SET_PAYMENT_TRANSACTION_SEARCH_TERM,
  CLEAR_PAYMENT_TRANSACTION,
  SELECT_ACCOUNT_USERS
} from "./types";
import { Models } from "../apis/Models";
import { BodyTypes } from "../apis/BodyTypes";
import { Parts } from "../apis/Parts";
import { Manufacturers } from "../apis/Manufacturers";
import { Locations } from "../apis/Locations";
import { SetupCosts } from "../apis/SetupCosts";
import { Consumables } from "../apis/Consumables";
import { ActivityConsumables } from "../apis/ActivityConsumables";
import { Toners } from "../apis/Toners";
import { Stages } from "../apis/Stages";
import { Suppliers } from "../apis/Suppliers";
import { PaintSystems } from "../apis/PaintSystems";
import { TonerContainerMetrics } from "../apis/TonerContainerMetrics";
import { LabourAdjustments } from "../apis/LabourAdjustments";
import { PaintColors } from "../apis/PaintColors";
import { SORs } from "../apis/SORs";
import { Effects } from "../apis/Effects";
import { FuelPrices } from "../apis/FuelPrices";
import { FuelTypes } from "../apis/FuelTypes";
import { PrimerPrices } from "../apis/PrimerPrices";
import _ from "lodash";
import { arrayDelta } from "../utilities";
import { ColorGroups } from "../apis/ColorGroups";
import { ReferencePanel } from "../apis/ReferencePanel";
import { Settings } from "../apis/Settings";
import { PaymentTransactions, ActivateCredits, SignedURL } from "../apis/PaymentTransactions";
import { Users } from "../apis/Users";
import { replaceArrayObjectByKey } from "../utilities";

//models & model part sizes

export const updateModelsState = models => {
  return {
    type: UPDATE_MODELS,
    payload: models
  };
};

export const updateAllModelsState = models => {
  return {
    type: UPDATE_ALL_MODELS,
    payload: models
  };
};

export const getModels = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await Models(params).get();
    dispatch({ type: GET_MODELS, payload: response.data.data });
    dispatch({
      type: CLONE_MODELS,
      payload: response.data.data
    });
    dispatch({ type: DATA_LOADED });
  };
};

export const getModel = ({ modelId, panelId }) => {
  return async dispatch => {
    const model = await Models({ id: modelId }).get();
    let _panel = {};
    if (panelId) {
      _panel = _.find(model.data.parts, { id: panelId });
    }
    dispatch({
      type: SELECT_MODEL,
      payload: { model: model.data, selectedPanel: _panel }
    });
  };
};

export const patchModelInDb = model => {
  return async () => {
    let response = {};
    try {
      response = await Models({ id: model.id }).patch("", model);
    } catch (e) {
      return e;
    }
    return response;
  };
};

export const postModelToDb = model => {
  //don't save any descriptions, remove body subtype and type descriptions.
  let _model = _.cloneDeep(model); //because of nested objects, MUST use deep copy
  delete _model.body.subtypeName;
  delete _model.body.typeName;
  return async () => {
    let response = {};
    try {
      response = await Models({}).post("", _model);
    } catch (e) {
      return e;
    }
    return response;
  };
};

export const selectModel = modelData => {
  return {
    type: SELECT_MODEL,
    payload: { model: modelData, selectedPanel: {} }
  };
};

export const setModelSearchTerm = term => {
  return {
    type: SET_MODEL_SEARCH_TERM,
    payload: term
  };
};

export const setModelsFilter = filter => {
  return {
    type: SET_MODEL_FILTER,
    payload: filter
  };
};

export const setModelsFilterLabel = label => {
  return {
    type: SET_MODEL_FILTER_LABEL,
    payload: label
  };
};

export const setModelPanelSearchTerm = term => {
  return {
    type: SET_MODEL_PANEL_SEARCH_TERM,
    payload: term
  };
};

export const setModelPanelFilter = filter => {
  return {
    type: SET_MODEL_PANEL_FILTER,
    payload: filter
  };
};

export const addModelToSelectedBatch = id => {
  return {
    type: ADD_MODEL_TO_BATCH,
    payload: id
  };
};

export const addAllModelsToSelectedBatch = models => {
  return {
    type: ADD_ALL_MODELS_TO_BATCH,
    payload: models
  };
};

export const addModelPanelToSelectedBatch = panel => {
  return {
    type: ADD_MODEL_PANEL_TO_BATCH,
    payload: panel
  };
};

export const addAllModelPanelsToSelectedBatch = panels => {
  return {
    type: ADD_ALL_MODEL_PANELS_TO_BATCH,
    payload: panels
  };
};

export const removeModelFromSelectedBatch = model => {
  return {
    type: REMOVE_MODEL_FROM_BATCH,
    payload: model
  };
};

export const removeModelPanelFromSelectedBatch = panel => {
  return {
    type: REMOVE_MODEL_PANEL_FROM_BATCH,
    payload: panel
  };
};

export const clearModelBatch = () => {
  return {
    type: CLEAR_MODEL_BATCH
  };
};

export const clearPartBatch = () => {
  return {
    type: CLEAR_PART_BATCH
  };
};

export const clearModelPanelBatch = () => {
  return {
    type: CLEAR_MODEL_PANEL_BATCH
  };
};

export const clearModel = () => {
  return { type: CLEAR_MODEL };
};

export const selectModelPanel = panelData => {
  clearModelPanel();
  return {
    type: SELECT_MODEL_PANEL,
    payload: panelData
  };
};

export const clearModelPanel = () => {
  return { type: CLEAR_MODEL_PANEL };
};

export const toggleModelPanelIncludeExclude = value => {
  return {
    type: SET_MODEL_PANEL_INCLUDE_EXCLUDE,
    payload: value
  };
};

export const setSelectedModelParts = parts => {
  return {
    type: SET_MODEL_PARTS,
    payload: parts
  };
};

export const deleteModels = modelsToDelete => {
  //convert array to comma separated string
  const modelIds = modelsToDelete.map(model => {
    return model.id;
  });
  const idString = "[" + modelIds.join() + "]";
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Models({ modelIds: idString }).put();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({ type: SAVED_RECORD, payload: "Models successfully deleted." });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

//Paint Colors

export const updatePaintColorsState = colors => {
  return {
    type: UPDATE_PAINTCOLORS,
    payload: colors
  };
};

export const updateAllPaintColorsState = colors => {
  return {
    type: UPDATE_ALL_PAINTCOLORS,
    payload: colors
  };
};

export const getPaintColors = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await PaintColors(params).get();
    dispatch({ type: GET_PAINTCOLORS, payload: response.data.data });
    dispatch({
      type: CLONE_PAINTCOLORS,
      payload: response.data.data
    });
    dispatch({ type: DATA_LOADED });
  };
};

export const getPaintColor = ({ colorId, tonerId }) => {
  return async dispatch => {
    const color = await PaintColors({ id: colorId }).get();
    let _toner = {};
    if (tonerId) {
      _toner = _.find(color.data.toners, { id: tonerId });
    }
    dispatch({
      type: SELECT_PAINTCOLOR,
      payload: { paintColor: color.data, selectedToner: _toner }
    });
  };
};

export const patchPaintColorInDb = color => {
  return async () => {
    //dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await PaintColors({ id: color.id }).patch("", color);
    } catch (e) {
      return e;
    }
    return response;
  };
};

export const postPaintColorToDb = color => {
  return async () => {
    let response = {};
    try {
      response = await PaintColors({}).post("", color);
    } catch (e) {
      return e;
    }
    return response;
  };
};

export const selectPaintColor = color => {
  return dispatch => {
    dispatch({
      type: SELECT_PAINTCOLOR,
      payload: { paintColor: color, selectedToner: {} }
    });
  };
};

export const setPaintColorSearchTerm = term => {
  return {
    type: SET_PAINTCOLOR_SEARCH_TERM,
    payload: term
  };
};

export const setPaintColorsFilter = filter => {
  return {
    type: SET_PAINTCOLOR_FILTER,
    payload: filter
  };
};

export const setPaintColorsFilterLabel = label => {
  return {
    type: SET_PAINTCOLOR_FILTER_LABEL,
    payload: label
  };
};

export const addPaintColorToSelectedBatch = id => {
  return {
    type: ADD_PAINTCOLOR_TO_BATCH,
    payload: id
  };
};

export const addAllPaintColorsToSelectedBatch = colors => {
  return {
    type: ADD_ALL_PAINTCOLORS_TO_BATCH,
    payload: colors
  };
};

export const removePaintColorFromSelectedBatch = color => {
  return {
    type: REMOVE_PAINTCOLOR_FROM_BATCH,
    payload: color
  };
};

export const clearPaintColorBatch = () => {
  return {
    type: CLEAR_PAINTCOLOR_BATCH
  };
};

export const clearPaintColor = () => {
  return { type: CLEAR_PAINTCOLOR };
};

export const deletePaintColors = colorsToDelete => {
  //convert array to comma separated string
  const colorIds = colorsToDelete.map(color => {
    return color.id;
  });
  const idString = "[" + colorIds.join() + "]";
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await PaintColors({ colorIds: idString }).put();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }

    dispatch({
      type: SAVED_RECORD,
      payload: "Paint Color record(s) successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const setColorTonerSearchTerm = term => {
  return {
    type: SET_COLOR_TONER_SEARCH_TERM,
    payload: term
  };
};

export const setColorTonerFilter = filter => {
  return {
    type: SET_COLOR_TONER_FILTER,
    payload: filter
  };
};

export const addColorTonerToSelectedBatch = toner => {
  return {
    type: ADD_COLOR_TONER_TO_BATCH,
    payload: toner
  };
};

export const addAllColorTonersToSelectedBatch = toners => {
  return {
    type: ADD_ALL_COLOR_TONERS_TO_BATCH,
    payload: toners
  };
};

export const removeColorTonerFromSelectedBatch = toner => {
  return {
    type: REMOVE_COLOR_TONER_FROM_BATCH,
    payload: toner
  };
};

export const clearColorTonerBatch = () => {
  return {
    type: CLEAR_COLOR_TONER_BATCH
  };
};

export const selectColorToner = toner => {
  clearColorToner();
  return {
    type: SELECT_COLOR_TONER,
    payload: toner
  };
};

export const clearColorToner = () => {
  return { type: CLEAR_COLOR_TONER };
};

export const setSelectedColorToners = toners => {
  return {
    type: SET_COLOR_TONERS,
    payload: toners
  };
};

//Parts

export const addAllPartsToSelectedBatch = parts => {
  return {
    type: ADD_ALL_PARTS_TO_BATCH,
    payload: parts
  };
};

export const addPartToSelectedBatch = id => {
  return {
    type: ADD_PART_TO_BATCH,
    payload: id
  };
};

export const removePartFromSelectedBatch = part => {
  return {
    type: REMOVE_PART_FROM_BATCH,
    payload: part
  };
};

export const selectPart = partData => {
  return {
    type: SELECT_PART,
    payload: partData
  };
};

export const getParts = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await Parts(params).get();
    dispatch({ type: GET_PARTS, payload: response.data });
    dispatch({
      type: CLONE_PARTS,
      payload: response.data
    });
    dispatch({ type: DATA_LOADED });
  };
};

export const setPartsFilter = filter => {
  return {
    type: SET_PART_FILTER,
    payload: filter
  };
};

export const setPartsFilterLabel = label => {
  return {
    type: SET_PART_FILTER_LABEL,
    payload: label
  };
};

export const clearPart = () => {
  return { type: CLEAR_PART };
};

export const clearParts = () => {
  return { type: CLEAR_PARTS };
};

export const deletePart = part => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Parts({ id: part.id }).delete();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: DELETE_PART,
      payload: part
    });
    dispatch({ type: SAVED_RECORD, payload: "Parts successfully deleted." });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const deleteParts = (currentParts, partsToDelete, allParts) => {
  //convert array to comma separated string
  const partIds = partsToDelete.map(part => {
    return part.id;
  });
  const idString = "[" + partIds.join() + "]";
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Parts({ partIds: idString }).put();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    //update the parts set in state by removing deleted parts from current parts
    dispatch({
      type: UPDATE_PARTS,
      payload: arrayDelta(currentParts, partsToDelete)
    });
    dispatch({
      type: UPDATE_ALL_PARTS,
      payload: arrayDelta(allParts, partsToDelete)
    });
    dispatch({ type: SAVED_RECORD, payload: "Parts successfully deleted." });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const patchPartInDb = part => {
  return async () => {
    let response = {};
    try {
      response = await Parts({ id: part.id }).patch("", part);
    } catch (e) {
      return e;
    }
    return response;
  };
};

export const postPartToDb = part => {
  return async () => {
    let response = {};
    try {
      response = await Parts({}).post("", part);
    } catch (e) {
      return e;
    }
    return response;
  };
};

export const updateSelectedPartState = part => {
  return { type: SELECT_PART, payload: part };
};

export const updatePartsState = parts => {
  return {
    type: UPDATE_PARTS,
    payload: parts
  };
};

export const updateAllPartsState = parts => {
  return {
    type: UPDATE_ALL_PARTS,
    payload: parts
  };
};

export const getPart = partId => {
  return async dispatch => {
    const part = await Parts({ id: partId }).get();
    dispatch({
      type: SELECT_PART,
      payload: part.data[0]
    });
  };
};

//Manufacturers

export const getManufacturer = manufacturerId => {
  return async dispatch => {
    const response = await Manufacturers({ id: manufacturerId }).get();
    dispatch({ type: SELECT_MANUFACTURER, payload: response.data[0] });
  };
};

export const updateSelectedManufacturerState = manufacturer => {
  return { type: SELECT_MANUFACTURER, payload: manufacturer };
};

export const clearManufacturer = () => {
  return { type: CLEAR_MANUFACTURER };
};

export const setManufacturersFilter = filter => {
  return {
    type: SET_MANUFACTURER_FILTER,
    payload: filter
  };
};

export const clearManufacturerBatch = () => {
  return {
    type: CLEAR_MANUFACTURER_BATCH
  };
};

export const selectManufacturer = manufacturerData => {
  return {
    type: SELECT_MANUFACTURER,
    payload: manufacturerData
  };
};

export const removeManufacturerFromSelectedBatch = manufacturer => {
  return {
    type: REMOVE_MANUFACTURER_FROM_BATCH,
    payload: manufacturer
  };
};

export const addManufacturerToSelectedBatch = id => {
  return {
    type: ADD_MANUFACTURER_TO_BATCH,
    payload: id
  };
};

export const addAllManufacturersToSelectedBatch = manufacturers => {
  return {
    type: ADD_ALL_MANUFACTURERS_TO_BATCH,
    payload: manufacturers
  };
};

export const deleteManufacturers = manufacturersToDelete => {
  //convert array to comma separated string
  const manufacturerIds = manufacturersToDelete.map(manufacturer => {
    return manufacturer.id;
  });
  const idString = "[" + manufacturerIds.join() + "]";
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Manufacturers({ manufacturerIds: idString }).put();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    //update the manufacturers set in state by removing delete manufacturers from current manufacturers
    // dispatch({
    //   type: UPDATE_MANUFACTURERS,
    //   payload: arrayDelta(currentManufacturers, manufacturersToDelete),
    // });
    dispatch({
      type: SAVED_RECORD,
      payload: "Manufacturers successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
    return response;
  };
};

export const deleteManufacturer = manufacturer => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Manufacturers({ id: manufacturer.id }).delete();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: DELETE_MANUFACTURER,
      payload: manufacturer
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "Manufacturers successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const patchManufacturerInDb = manufacturer => {
  return async () => {
    let response = {};
    try {
      response = await Manufacturers({ id: manufacturer.id }).patch("", manufacturer);
    } catch (e) {
      return e;
    }
    return response;
  };
};

export const updateManufacturersState = manufacturers => {
  return {
    type: UPDATE_MANUFACTURERS,
    payload: manufacturers
  };
};

export const updateAllManufacturersState = manufacturers => {
  return {
    type: UPDATE_ALL_MANUFACTURERS,
    payload: manufacturers
  };
};

export const getManufacturers = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await Manufacturers(params).get();
    dispatch({ type: GET_MANUFACTURERS, payload: response.data });
    dispatch({
      type: CLONE_MANUFACTURERS,
      payload: response.data
    });

    dispatch({ type: DATA_LOADED });
  };
};

export const clearManufacturers = () => {
  return { type: CLEAR_MANUFACTURERS };
};

export const postManufacturerToDb = manufacturer => {
  return async () => {
    let response = {};
    try {
      response = await Manufacturers({}).post("", manufacturer);
    } catch (e) {
      return e;
    }
    return response;
  };
};

//*Locations
//************************************************

export const getLocation = locationId => {
  return async dispatch => {
    const response = await Locations({ id: locationId }).get();
    dispatch({ type: SELECT_LOCATION, payload: response.data[0] });
  };
};

export const updateSelectedLocationState = location => {
  return { type: SELECT_LOCATION, payload: location };
};

export const clearLocation = () => {
  return { type: CLEAR_LOCATION };
};

export const setLocationsFilter = filter => {
  return {
    type: SET_LOCATION_FILTER,
    payload: filter
  };
};

export const clearLocationBatch = () => {
  return {
    type: CLEAR_LOCATION_BATCH
  };
};

export const selectLocation = locationData => {
  return {
    type: SELECT_LOCATION,
    payload: locationData
  };
};

export const removeLocationFromSelectedBatch = location => {
  return {
    type: REMOVE_LOCATION_FROM_BATCH,
    payload: location
  };
};

export const addLocationToSelectedBatch = id => {
  return {
    type: ADD_LOCATION_TO_BATCH,
    payload: id
  };
};

export const addAllLocationsToSelectedBatch = locations => {
  return {
    type: ADD_ALL_LOCATIONS_TO_BATCH,
    payload: locations
  };
};

export const deleteLocations = locationsToDelete => {
  //convert array to comma separated string
  const locationIds = locationsToDelete.map(location => {
    return location.id;
  });
  const idString = "[" + locationIds.join() + "]";
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Locations({ locationIds: idString }).put();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Locations successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
    return response;
  };
};

export const deleteLocation = location => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Locations({ id: location.id }).delete();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: DELETE_LOCATION,
      payload: location
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "Locations successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const patchLocationInDb = location => {
  return async () => {
    let response = {};
    try {
      response = await Locations({ id: location.id }).patch("", location);
    } catch (e) {
      return e;
    }
    return response;
  };
};

export const updateLocationsState = locations => {
  return {
    type: UPDATE_LOCATIONS,
    payload: locations
  };
};

export const updateAllLocationsState = locations => {
  return {
    type: UPDATE_ALL_LOCATIONS,
    payload: locations
  };
};

export const getLocations = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await Locations(params).get();
    dispatch({ type: GET_LOCATIONS, payload: response.data });
    dispatch({
      type: CLONE_LOCATIONS,
      payload: response.data
    });

    dispatch({ type: DATA_LOADED });
  };
};

export const clearLocations = () => {
  return { type: CLEAR_LOCATIONS };
};

export const postLocationToDb = location => {
  return async () => {
    let response = {};
    try {
      response = await Locations({}).post("", location);
    } catch (e) {
      return e;
    }
    return response;
  };
};

//Toners

//************************************************

export const updateSelectedTonerState = toner => {
  return { type: SELECT_TONER, payload: toner };
};

export const clearToner = () => {
  return { type: CLEAR_TONER };
};

export const setTonersFilter = filter => {
  return {
    type: SET_TONER_FILTER,
    payload: filter
  };
};

export const setTonerSearchTerm = term => {
  return {
    type: SET_TONER_SEARCH_TERM,
    payload: term
  };
};

export const clearTonerBatch = () => {
  return {
    type: CLEAR_TONER_BATCH
  };
};

export const selectToner = tonerData => {
  return {
    type: SELECT_TONER,
    payload: tonerData
  };
};

export const removeTonerFromSelectedBatch = toner => {
  return {
    type: REMOVE_TONER_FROM_BATCH,
    payload: toner
  };
};

export const addTonerToSelectedBatch = id => {
  return {
    type: ADD_TONER_TO_BATCH,
    payload: id
  };
};

export const addAllTonersToSelectedBatch = toners => {
  return {
    type: ADD_ALL_TONERS_TO_BATCH,
    payload: toners
  };
};

export const deleteToners = tonersToDelete => {
  //convert array to comma separated string
  const tonerIds = tonersToDelete.map(toner => {
    return toner.skey;
  });
  const idString = "[" + tonerIds.join() + "]";
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Toners({ tonerIds: idString }).put();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Toners successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const deleteToner = deleteKeys => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Toners({ deleteKeys: deleteKeys }).put();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Toner successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const deleteTonerBySkey = deleteKey => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Toners({ deleteKey: deleteKey }).delete();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Toner successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const patchTonerInDb = (skey, toner) => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Toners({ skey }).patch("", toner);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Toner successfully saved."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
    return response;
  };
};

export const updateTonerPriceInDb = ({ skey, toner, existingFutureDatedTonerId, startdate }) => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Toners({
        skey: skey,
        deletekey: existingFutureDatedTonerId,
        startdate: startdate
      }).put("", toner);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Toner price updated successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
    return response;
  };
};

export const updateTonersState = toners => {
  return {
    type: UPDATE_TONERS,
    payload: toners
  };
};

export const updateAllTonersState = toners => {
  return {
    type: UPDATE_ALL_TONERS,
    payload: toners
  };
};

const CalcTonersCostPerGram = tonerData => {
  return tonerData.map(toner => {
    let _toner = { ...toner };
    if (_toner.nettweight && toner.cost) {
      _toner.costpergram = (toner.cost / toner.nettweight).toFixed(2);
    }
    return _toner;
  });
};

const CalcTonerCostPerGram = toner => {
  let _toner = { ...toner };
  if (_toner.nettweight && toner.cost) {
    _toner.costpergram = (toner.cost / toner.nettweight).toFixed(2);
  }
  return _toner;
};

export const getToners = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await Toners(params).get();
    const _toners = CalcTonersCostPerGram(response.data.data);
    dispatch({
      type: GET_TONERS,
      payload: _toners
    });
    dispatch({
      type: CLONE_TONERS,
      payload: _toners
    });
    dispatch({ type: DATA_LOADED });
  };
};

export const getToner = tonerId => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await Toners({ id: tonerId, current: 1 }).get();
    dispatch({
      type: SELECT_TONER,
      payload: CalcTonerCostPerGram(response.data[0])
    });
    dispatch({ type: DATA_LOADED });
  };
};

export const getTonersById = tonerId => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await Toners({ id: tonerId }).get();
    dispatch({ type: SELECT_TONERS, payload: CalcTonersCostPerGram(response.data) });
    dispatch({ type: DATA_LOADED });
  };
};

export const setTonersWithSelectedId = toners => {
  return { type: SELECT_TONERS, payload: toners };
};

export const getTonerByKey = key => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await Toners({ skey: key, keyget: true }).get();
    dispatch({ type: SELECT_TONER, payload: CalcTonerCostPerGram(response.data) });
    dispatch({ type: DATA_LOADED });
    return response.data;
  };
};

export const clearToners = () => {
  return { type: CLEAR_TONERS };
};

export const postTonerToDb = toner => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Toners({}).post("", toner);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Toner saved successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
    return response;
  };
};

export const getTonerContainerMetrics = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await TonerContainerMetrics(params).get();
    dispatch({ type: GET_TONER_CONTAINER_METRICS, payload: response.data });
    dispatch({ type: DATA_LOADED });
  };
};

export const setTonerCalculatedVolume = vol => {
  return {
    type: SET_TONER_CALCULATED_VOL,
    payload: vol
  };
};

export const getSuppliers = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await Suppliers(params).get();
    dispatch({ type: GET_SUPPLIERS, payload: response.data });
    dispatch({ type: DATA_LOADED });
  };
};

export const getSupplier = supplierId => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await Suppliers({ id: supplierId }).get();
    dispatch({ type: SELECT_SUPPLIER, payload: response.data[0] });
    dispatch({ type: DATA_LOADED });
  };
};

export const getPaintSystems = () => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await PaintSystems().get();
    dispatch({ type: GET_SYSTEMS, payload: response.data });
    dispatch({ type: DATA_LOADED });
  };
};

//Color groups

export const getColorGroup = colorGroupId => {
  return async dispatch => {
    const response = await ColorGroups({ id: colorGroupId }).get();
    dispatch({ type: SELECT_COLORGROUP, payload: response.data[0] });
  };
};

export const updateSelectedColorGroupState = colorGroup => {
  return { type: SELECT_COLORGROUP, payload: colorGroup };
};

export const clearColorGroup = () => {
  return { type: CLEAR_COLORGROUP };
};

export const setColorGroupsFilter = filter => {
  return {
    type: SET_COLORGROUP_FILTER,
    payload: filter
  };
};

export const clearColorGroupBatch = () => {
  return {
    type: CLEAR_COLORGROUP_BATCH
  };
};

export const selectColorGroup = colorGroupData => {
  return {
    type: SELECT_COLORGROUP,
    payload: colorGroupData
  };
};

export const removeColorGroupFromSelectedBatch = colorGroup => {
  return {
    type: REMOVE_COLORGROUP_FROM_BATCH,
    payload: colorGroup
  };
};

export const addColorGroupToSelectedBatch = id => {
  return {
    type: ADD_COLORGROUP_TO_BATCH,
    payload: id
  };
};

export const addAllColorGroupsToSelectedBatch = colorGroups => {
  return {
    type: ADD_ALL_COLORGROUPS_TO_BATCH,
    payload: colorGroups
  };
};

export const deleteColorGroups = (currentColorGroups, colorGroupsToDelete) => {
  //convert array to comma separated string
  const colorGroupIds = colorGroupsToDelete.map(colorGroup => {
    return colorGroup.id;
  });
  const idString = "[" + colorGroupIds.join() + "]";
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await ColorGroups({ colorGroupIds: idString }).put();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    //update the colorGroups set in state by removing delete colorGroups from current colorGroups
    dispatch({
      type: UPDATE_COLORGROUPS,
      payload: arrayDelta(currentColorGroups, colorGroupsToDelete)
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "Color groups successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const deleteColorGroup = colorGroup => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await ColorGroups({ id: colorGroup.id }).delete();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: DELETE_COLORGROUP,
      payload: colorGroup
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "Color Group successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const patchColorGroupInDb = colorGroup => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await ColorGroups({ id: colorGroup.id }).patch("", colorGroup);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Color group updated successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const updateColorGroupsState = colorGroups => {
  return {
    type: UPDATE_COLORGROUPS,
    payload: colorGroups
  };
};

export const updateAllColorGroupsState = colorGroups => {
  return {
    type: UPDATE_ALL_COLORGROUPS,
    payload: colorGroups
  };
};

export const getColorGroups = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await ColorGroups(params).get();
    dispatch({ type: GET_COLORGROUPS, payload: response.data });
    dispatch({
      type: CLONE_COLORGROUPS,
      payload: response.data
    });

    dispatch({ type: DATA_LOADED });
  };
};

export const clearColorGroups = () => {
  return { type: CLEAR_COLORGROUPS };
};

export const postColorGroupToDb = colorGroup => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await ColorGroups({}).post("", colorGroup);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Color group saved successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
    return response;
  };
};

//Fuel Prices

export const getFuelPrice = fuelPriceId => {
  return async dispatch => {
    const response = await FuelPrices({ id: fuelPriceId }).get();
    dispatch({ type: SELECT_FUELPRICE, payload: response.data[0] });
  };
};

export const updateSelectedFuelPriceState = fuelPrice => {
  return { type: SELECT_FUELPRICE, payload: fuelPrice };
};

export const clearFuelPrice = () => {
  return { type: CLEAR_FUELPRICE };
};

export const setFuelPricesFilter = filter => {
  return {
    type: SET_FUELPRICE_FILTER,
    payload: filter
  };
};

export const clearFuelPriceBatch = () => {
  return {
    type: CLEAR_FUELPRICE_BATCH
  };
};

export const selectFuelPrice = fuelPriceData => {
  return {
    type: SELECT_FUELPRICE,
    payload: fuelPriceData
  };
};

export const removeFuelPriceFromSelectedBatch = fuelPrice => {
  return {
    type: REMOVE_FUELPRICE_FROM_BATCH,
    payload: fuelPrice
  };
};

export const addFuelPriceToSelectedBatch = id => {
  return {
    type: ADD_FUELPRICE_TO_BATCH,
    payload: id
  };
};

export const addAllFuelPricesToSelectedBatch = fuelPrices => {
  return {
    type: ADD_ALL_FUELPRICES_TO_BATCH,
    payload: fuelPrices
  };
};

export const deleteFuelPrices = (currentFuelPrices, fuelPricesToDelete) => {
  //convert array to comma separated string
  const fuelPriceIds = fuelPricesToDelete.map(fuelPrice => {
    return fuelPrice.id;
  });
  const idString = "[" + fuelPriceIds.join() + "]";
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await FuelPrices({ fuelPriceIds: idString }).put();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    //update the fuelPrices set in state by removing delete fuelPrices from current fuelPrices
    dispatch({
      type: UPDATE_FUELPRICES,
      payload: arrayDelta(currentFuelPrices, fuelPricesToDelete)
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "Fuel prices successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const deleteFuelPrice = fuelPrice => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await FuelPrices({ id: fuelPrice.id }).delete();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: DELETE_FUELPRICE,
      payload: fuelPrice
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "Fuel price successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const patchFuelPriceInDb = fuelPrice => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await FuelPrices({ id: fuelPrice.id }).patch("", fuelPrice);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Fuel price updated successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const updateFuelPricesState = fuelPrices => {
  return {
    type: UPDATE_FUELPRICES,
    payload: fuelPrices
  };
};

export const updateAllFuelPricesState = fuelPrices => {
  return {
    type: CLONE_FUELPRICES,
    payload: fuelPrices
  };
};

export const getFuelPrices = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await FuelPrices(params).get();
    dispatch({ type: GET_FUELPRICES, payload: response.data });
    dispatch({
      type: CLONE_FUELPRICES,
      payload: response.data
    });

    dispatch({ type: DATA_LOADED });
  };
};

export const clearFuelPrices = () => {
  return { type: CLEAR_FUELPRICES };
};

export const postFuelPriceToDb = fuelPrice => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await FuelPrices({}).post("", fuelPrice);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Fuel price saved successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
    return response;
  };
};

//Fuel Types

export const getFuelTypes = () => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await FuelTypes().get();
    dispatch({ type: GET_FUELTYPES, payload: response.data });
    dispatch({ type: DATA_LOADED });
  };
};

//Primer Prices

export const getPrimerPrice = primerPriceId => {
  return async dispatch => {
    const response = await PrimerPrices({ id: primerPriceId }).get();
    dispatch({ type: SELECT_PRIMERPRICE, payload: response.data });
  };
};

export const updateSelectedPrimerPriceState = primerPrice => {
  return { type: SELECT_PRIMERPRICE, payload: primerPrice };
};

export const clearPrimerPrice = () => {
  return { type: CLEAR_PRIMERPRICE };
};

export const setPrimerPricesFilter = filter => {
  return {
    type: SET_PRIMERPRICE_FILTER,
    payload: filter
  };
};

export const clearPrimerPriceBatch = () => {
  return {
    type: CLEAR_PRIMERPRICE_BATCH
  };
};

export const selectPrimerPrice = primerPriceData => {
  return {
    type: SELECT_PRIMERPRICE,
    payload: primerPriceData
  };
};

export const removePrimerPriceFromSelectedBatch = primerPrice => {
  return {
    type: REMOVE_PRIMERPRICE_FROM_BATCH,
    payload: primerPrice
  };
};

export const addPrimerPriceToSelectedBatch = id => {
  return {
    type: ADD_PRIMERPRICE_TO_BATCH,
    payload: id
  };
};

export const addAllPrimerPricesToSelectedBatch = primerPrices => {
  return {
    type: ADD_ALL_PRIMERPRICES_TO_BATCH,
    payload: primerPrices
  };
};

export const deletePrimerPrices = (currentPrimerPrices, primerPricesToDelete) => {
  //convert array to comma separated string
  const primerPriceIds = primerPricesToDelete.map(primerPrice => {
    return primerPrice.id;
  });
  const idString = "[" + primerPriceIds.join() + "]";
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await PrimerPrices({ primerPriceIds: idString }).put();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    //update the primerPrices set in state by removing delete primerPrices from current primerPrices
    dispatch({
      type: UPDATE_PRIMERPRICES,
      payload: arrayDelta(currentPrimerPrices, primerPricesToDelete)
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "Primer prices successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const deletePrimerPrice = primerPrice => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await PrimerPrices({ id: primerPrice.id }).delete();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: DELETE_PRIMERPRICE,
      payload: primerPrice
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "Primer price successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const patchPrimerPriceInDb = primerPrice => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await PrimerPrices({ id: primerPrice.id }).patch("", primerPrice);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Primer price updated successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const updatePrimerPricesState = primerPrices => {
  return {
    type: UPDATE_PRIMERPRICES,
    payload: primerPrices
  };
};

export const updateAllPrimerPricesState = primerPrices => {
  return {
    type: CLONE_PRIMERPRICES,
    payload: primerPrices
  };
};

export const getPrimerPrices = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await PrimerPrices(params).get();
    dispatch({ type: GET_PRIMERPRICES, payload: response.data });
    dispatch({
      type: CLONE_PRIMERPRICES,
      payload: response.data
    });

    dispatch({ type: DATA_LOADED });
  };
};

export const clearPrimerPrices = () => {
  return { type: CLEAR_PRIMERPRICES };
};

export const postPrimerPriceToDb = primerPrice => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await PrimerPrices({}).post("", primerPrice);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Primer price saved successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
    return response;
  };
};

//BodyTypes

export const getBodyTypes = ({ name, all }) => {
  return async dispatch => {
    const response = await BodyTypes({ cls: 0, name: name, all: all }).get();
    dispatch({ type: GET_BODYTYPES, payload: response.data });
  };
};

export const getBodySubTypes = name => {
  return async dispatch => {
    const response = await BodyTypes({ cls: 1, name: name }).get();
    dispatch({ type: GET_BODYSUBTYPES, payload: response.data });
  };
};

//Consumables

export const getConsumable = consumableId => {
  return async dispatch => {
    const response = await Consumables({ id: consumableId }).get();
    dispatch({ type: SELECT_CONSUMABLE, payload: response.data[0] });
  };
};

export const updateSelectedConsumableState = consumable => {
  return { type: SELECT_CONSUMABLE, payload: consumable };
};

export const clearConsumable = () => {
  return { type: CLEAR_CONSUMABLE };
};

export const setConsumablesFilter = filter => {
  return {
    type: SET_CONSUMABLE_FILTER,
    payload: filter
  };
};

export const clearConsumableBatch = () => {
  return {
    type: CLEAR_CONSUMABLE_BATCH
  };
};

export const selectConsumable = consumableData => {
  return {
    type: SELECT_CONSUMABLE,
    payload: consumableData
  };
};

export const removeConsumableFromSelectedBatch = consumable => {
  return {
    type: REMOVE_CONSUMABLE_FROM_BATCH,
    payload: consumable
  };
};

export const addConsumableToSelectedBatch = id => {
  return {
    type: ADD_CONSUMABLE_TO_BATCH,
    payload: id
  };
};

export const addAllConsumablesToSelectedBatch = consumables => {
  return {
    type: ADD_ALL_CONSUMABLES_TO_BATCH,
    payload: consumables
  };
};

export const deleteConsumables = consumablesToDelete => {
  //convert array to comma separated string
  const consumableIds = consumablesToDelete.map(consumable => {
    return consumable.id;
  });
  const idString = "[" + consumableIds.join() + "]";
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Consumables({ consumableIds: idString }).put();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }

    dispatch({
      type: SAVED_RECORD,
      payload: "Consumables successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const deleteConsumable = consumable => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Consumables({ id: consumable.id }).delete();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: DELETE_CONSUMABLE,
      payload: consumable
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "Consumables successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const patchConsumableInDb = consumable => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Consumables({ id: consumable.id }).patch("", consumable);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Consumable updated successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const updateConsumablesState = consumables => {
  return {
    type: UPDATE_CONSUMABLES,
    payload: consumables
  };
};

export const updateAllConsumablesState = consumables => {
  return {
    type: UPDATE_ALL_CONSUMABLES,
    payload: consumables
  };
};

export const getConsumables = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await Consumables(params).get();
    dispatch({ type: GET_CONSUMABLES, payload: response.data });
    dispatch({
      type: CLONE_CONSUMABLES,
      payload: response.data
    });
    dispatch({ type: DATA_LOADED });
  };
};

export const clearConsumables = () => {
  return { type: CLEAR_CONSUMABLES };
};

export const postConsumableToDb = consumable => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Consumables({}).post("", consumable);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Consumable saved successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
    return response;
  };
};

//ActivityConsumables

export const getActivityConsumable = activityconsumableId => {
  return async dispatch => {
    const response = await ActivityConsumables({
      id: activityconsumableId
    }).get();
    dispatch({ type: SELECT_ACTIVITY_CONSUMABLE, payload: response.data });
  };
};

export const updateSelectedActivityConsumableState = activityconsumable => {
  return { type: SELECT_ACTIVITY_CONSUMABLE, payload: activityconsumable };
};

export const clearActivityConsumable = () => {
  return { type: CLEAR_ACTIVITY_CONSUMABLE };
};

export const setActivityConsumablesFilter = filter => {
  return {
    type: SET_ACTIVITY_CONSUMABLE_FILTER,
    payload: filter
  };
};

export const clearActivityConsumableBatch = () => {
  return {
    type: CLEAR_ACTIVITY_CONSUMABLE_BATCH
  };
};

export const selectActivityConsumable = activityconsumableData => {
  return {
    type: SELECT_ACTIVITY_CONSUMABLE,
    payload: activityconsumableData
  };
};

export const removeActivityConsumableFromSelectedBatch = activityconsumable => {
  return {
    type: REMOVE_ACTIVITY_CONSUMABLE_FROM_BATCH,
    payload: activityconsumable
  };
};

export const addActivityConsumableToSelectedBatch = id => {
  return {
    type: ADD_ACTIVITY_CONSUMABLE_TO_BATCH,
    payload: id
  };
};

export const addAllActivityConsumablesToSelectedBatch = activityConsumables => {
  return {
    type: ADD_ALL_ACTIVITY_CONSUMABLES_TO_BATCH,
    payload: activityConsumables
  };
};

export const deleteActivityConsumables = (currentActivityConsumables, activityconsumablesToDelete) => {
  //convert array to comma separated string
  const activityconsumableIds = activityconsumablesToDelete.map(activityconsumable => {
    return activityconsumable.id;
  });
  const idString = "[" + activityconsumableIds.join() + "]";
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await ActivityConsumables({
        activityconsumableIds: idString
      }).put();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    //update the activityconsumables set in state by removing delete activityconsumables from current activityconsumables
    dispatch({
      type: UPDATE_ACTIVITY_CONSUMABLES,
      payload: arrayDelta(currentActivityConsumables, activityconsumablesToDelete)
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "ActivityConsumables successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const deleteActivityConsumable = activityconsumable => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await ActivityConsumables({
        id: activityconsumable.id
      }).delete();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: DELETE_ACTIVITY_CONSUMABLE,
      payload: activityconsumable
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "ActivityConsumables successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const patchActivityConsumableInDb = activityconsumable => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await ActivityConsumables({ id: activityconsumable.id }).patch("", activityconsumable);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "ActivityConsumable updated successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const updateActivityConsumablesState = activityConsumables => {
  return {
    type: UPDATE_ACTIVITY_CONSUMABLES,
    payload: activityConsumables
  };
};

export const getActivityConsumables = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await ActivityConsumables(params).get();
    dispatch({ type: GET_ACTIVITY_CONSUMABLES, payload: response.data });
    //create clone for filter management purposes (full set used to reset filter)
    dispatch({
      type: CLONE_ACTIVITY_CONSUMABLES,
      payload: response.data
    });
    dispatch({ type: DATA_LOADED });
  };
};

export const clearActivityConsumables = () => {
  return { type: CLEAR_ACTIVITY_CONSUMABLES };
};

export const postActivityConsumableToDb = activityconsumable => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await ActivityConsumables({}).post("", activityconsumable);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Activity Consumable saved successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
    return response;
  };
};

//Labour Adjustments

export const getLabourAdjustment = labouradjustmentId => {
  return async dispatch => {
    const response = await LabourAdjustments({
      id: labouradjustmentId
    }).get();
    dispatch({ type: SELECT_LABOUR_ADJUSTMENT, payload: response.data[0] });
  };
};

export const updateSelectedLabourAdjustmentState = labouradjustment => {
  return { type: SELECT_LABOUR_ADJUSTMENT, payload: labouradjustment };
};

export const clearLabourAdjustment = () => {
  return { type: CLEAR_LABOUR_ADJUSTMENT };
};

export const setLabourAdjustmentsFilter = filter => {
  return {
    type: SET_LABOUR_ADJUSTMENT_FILTER,
    payload: filter
  };
};

export const clearLabourAdjustmentBatch = () => {
  return {
    type: CLEAR_LABOUR_ADJUSTMENT_BATCH
  };
};

export const selectLabourAdjustment = labouradjustmentData => {
  return {
    type: SELECT_LABOUR_ADJUSTMENT,
    payload: labouradjustmentData
  };
};

export const removeLabourAdjustmentFromSelectedBatch = labouradjustment => {
  return {
    type: REMOVE_LABOUR_ADJUSTMENT_FROM_BATCH,
    payload: labouradjustment
  };
};

export const addLabourAdjustmentToSelectedBatch = id => {
  return {
    type: ADD_LABOUR_ADJUSTMENT_TO_BATCH,
    payload: id
  };
};

export const addAllLabourAdjustmentsToSelectedBatch = labourAdjustments => {
  return {
    type: ADD_ALL_LABOUR_ADJUSTMENTS_TO_BATCH,
    payload: labourAdjustments
  };
};

export const deleteLabourAdjustments = (currentLabourAdjustments, labouradjustmentsToDelete) => {
  //convert array to comma separated string
  const labouradjustmentIds = labouradjustmentsToDelete.map(labouradjustment => {
    return labouradjustment.id;
  });
  const idString = "[" + labouradjustmentIds.join() + "]";
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await LabourAdjustments({
        adjustmentIds: idString
      }).put();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    //update the labouradjustments set in state by removing delete labouradjustments from current labouradjustments
    dispatch({
      type: UPDATE_LABOUR_ADJUSTMENTS,
      payload: arrayDelta(currentLabourAdjustments, labouradjustmentsToDelete)
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "LabourAdjustments successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const deleteLabourAdjustment = labouradjustment => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await LabourAdjustments({
        id: labouradjustment.id
      }).delete();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: DELETE_LABOUR_ADJUSTMENT,
      payload: labouradjustment
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "LabourAdjustments successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const patchLabourAdjustmentInDb = labouradjustment => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await LabourAdjustments({ id: labouradjustment.id }).patch("", labouradjustment);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Labour Adjustment updated successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
    return response;
  };
};

export const updateLabourAdjustmentsState = labourAdjustments => {
  return {
    type: UPDATE_LABOUR_ADJUSTMENTS,
    payload: labourAdjustments
  };
};

export const getLabourAdjustments = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await LabourAdjustments(params).get();
    dispatch({ type: GET_LABOUR_ADJUSTMENTS, payload: response.data });
    //create clone for filter management purposes (full set used to reset filter)
    dispatch({
      type: CLONE_LABOUR_ADJUSTMENTS,
      payload: response.data
    });
    dispatch({ type: DATA_LOADED });
  };
};

export const clearLabourAdjustments = () => {
  return { type: CLEAR_LABOUR_ADJUSTMENTS };
};

export const postLabourAdjustmentToDb = labouradjustment => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await LabourAdjustments({}).post("", labouradjustment);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Labour adjustment record saved successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
    return response;
  };
};

//Scope of Repair

export const getSOR = ({ sorId }) => {
  return async dispatch => {
    const response = await SORs({ id: sorId }).get();
    dispatch({ type: SELECT_SOR, payload: response.data });
  };
};

export const updateSelectedSORState = SOR => {
  return { type: SELECT_SOR, payload: SOR };
};

export const clearSOR = () => {
  return { type: CLEAR_SOR };
};

export const setSORsFilter = filter => {
  return {
    type: SET_SOR_FILTER,
    payload: filter
  };
};

export const clearSORBatch = () => {
  return {
    type: CLEAR_SOR_BATCH
  };
};

export const selectSOR = SORData => {
  return {
    type: SELECT_SOR,
    payload: SORData
  };
};

export const removeSORFromSelectedBatch = SOR => {
  return {
    type: REMOVE_SOR_FROM_BATCH,
    payload: SOR
  };
};

export const addSORToSelectedBatch = id => {
  return {
    type: ADD_SOR_TO_BATCH,
    payload: id
  };
};

export const addAllSORsToSelectedBatch = SORs => {
  return {
    type: ADD_ALL_SORS_TO_BATCH,
    payload: SORs
  };
};

export const deleteSORs = (currentSORs, SORsToDelete) => {
  //convert array to comma separated string
  const SORIds = SORsToDelete.map(SOR => {
    return SOR.id;
  });
  const idString = "[" + SORIds.join() + "]";
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await SORs({ SORIds: idString }).put();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    //update the SORs set in state by removing delete SORs from current SORs
    dispatch({
      type: UPDATE_SORS,
      payload: arrayDelta(currentSORs, SORsToDelete)
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "Scopes of Repair successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const deleteSOR = SOR => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await SORs({ id: SOR.id }).delete();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: DELETE_SOR,
      payload: SOR
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "Scope of Repair successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const patchSORInDb = SOR => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await SORs({ id: SOR.id }).patch("", SOR);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Scope of Repair updated successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const updateSORsState = SORs => {
  return {
    type: UPDATE_SORS,
    payload: SORs
  };
};

export const updateAllSORsState = SORs => {
  return {
    type: UPDATE_ALL_SORS,
    payload: SORs
  };
};

export const getSORs = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await SORs(params).get();
    dispatch({ type: GET_SORS, payload: response.data });

    dispatch({
      type: CLONE_SORS,
      payload: response.data
    });
    dispatch({ type: DATA_LOADED });
  };
};

export const clearSORs = () => {
  return { type: CLEAR_SORS };
};

export const postSORToDb = SOR => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await SORs({}).post("", SOR);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Scope of Repair saved successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
    return response;
  };
};

//Stages

export const getStage = stageId => {
  return async dispatch => {
    const response = await Stages({ id: stageId }).get();
    dispatch({ type: SELECT_STAGE, payload: response.data[0] });
  };
};

export const updateSelectedStageState = stage => {
  return { type: SELECT_STAGE, payload: stage };
};

export const clearStage = () => {
  return { type: CLEAR_STAGE };
};

export const setStagesFilter = filter => {
  return {
    type: SET_STAGE_FILTER,
    payload: filter
  };
};

export const clearStageBatch = () => {
  return {
    type: CLEAR_STAGE_BATCH
  };
};

export const selectStage = stageData => {
  return {
    type: SELECT_STAGE,
    payload: stageData
  };
};

export const removeStageFromSelectedBatch = stage => {
  return {
    type: REMOVE_STAGE_FROM_BATCH,
    payload: stage
  };
};

export const addStageToSelectedBatch = id => {
  return {
    type: ADD_STAGE_TO_BATCH,
    payload: id
  };
};

export const addAllStagesToSelectedBatch = stages => {
  return {
    type: ADD_ALL_STAGES_TO_BATCH,
    payload: stages
  };
};

export const deleteStages = (currentStages, stagesToDelete) => {
  //convert array to comma separated string
  const stageIds = stagesToDelete.map(stage => {
    return stage.id;
  });
  const idString = "[" + stageIds.join() + "]";
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Stages({ stageIds: idString }).put();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    //update the stages set in state by removing delete stages from current stages
    dispatch({
      type: UPDATE_STAGES,
      payload: arrayDelta(currentStages, stagesToDelete)
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "Stages successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const deleteStage = stage => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Stages({ id: stage.id }).delete();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: DELETE_STAGE,
      payload: stage
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "Stages successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const patchStageInDb = stage => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Stages({ id: stage.id }).patch("", stage);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Stage updated successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const updateStagesState = stages => {
  return {
    type: UPDATE_STAGES,
    payload: stages
  };
};

export const getStages = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await Stages(params).get();
    dispatch({ type: GET_STAGES, payload: response.data });
    dispatch({ type: DATA_LOADED });
  };
};

export const clearStages = () => {
  return { type: CLEAR_STAGES };
};

export const postStageToDb = stage => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Stages({}).post("", stage);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Stage saved successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
    return response;
  };
};

//Effects & Effect Stages

export const updateEffectsState = effects => {
  return {
    type: UPDATE_EFFECTS,
    payload: effects
  };
};

export const updateAllEffectsState = effects => {
  return {
    type: UPDATE_ALL_EFFECTS,
    payload: effects
  };
};

export const getEffects = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await Effects(params).get();

    dispatch({ type: GET_EFFECTS, payload: response.data });

    dispatch({
      type: CLONE_EFFECTS,
      payload: response.data
    });
    dispatch({ type: DATA_LOADED });
  };
};

export const getEffect = ({ effectId }) => {
  return async dispatch => {
    const effect = await Effects({ id: effectId }).get();

    dispatch({
      type: SELECT_EFFECT,
      payload: effect.data
    });
  };
};

//Note - used for creating new record also
export const patchEffectInDb = effect => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Effects({ id: effect.id }).patch("", effect);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Paint effect updated successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
    return response;
  };
};

export const postEffectToDb = effect => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Effects({}).post("", effect);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({ type: SAVED_RECORD, payload: "Effect saved successfully." });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
    return response;
  };
};

export const updateSelectedEffectState = effect => {
  return { type: SELECT_EFFECT, payload: effect };
};

export const selectEffect = effectData => {
  return {
    type: SELECT_EFFECT,
    payload: effectData
  };
};

export const setEffectsFilter = filter => {
  return {
    type: SET_EFFECT_FILTER,
    payload: filter
  };
};

export const setEffectsFilterLabel = label => {
  return {
    type: SET_EFFECT_FILTER_LABEL,
    payload: label
  };
};

export const addEffectToSelectedBatch = id => {
  return {
    type: ADD_EFFECT_TO_BATCH,
    payload: id
  };
};

export const addAllEffectsToSelectedBatch = effects => {
  return {
    type: ADD_ALL_EFFECTS_TO_BATCH,
    payload: effects
  };
};

export const removeEffectFromSelectedBatch = effect => {
  return {
    type: REMOVE_EFFECT_FROM_BATCH,
    payload: effect
  };
};

export const clearEffectBatch = () => {
  return {
    type: CLEAR_EFFECT_BATCH
  };
};

export const clearEffect = () => {
  return { type: CLEAR_EFFECT };
};

export const clearEffects = () => {
  return { type: CLEAR_EFFECTS };
};

export const deleteEffect = effect => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Effects({ id: effect.id }).delete();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: DELETE_EFFECT,
      payload: effect
    });
    dispatch({ type: SAVED_RECORD, payload: "Effect successfully deleted." });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const deleteEffects = (currentEffects, effectsToDelete, allEffects) => {
  //convert array to comma separated string
  const effectIds = effectsToDelete.map(effect => {
    return effect.id;
  });
  const idString = "[" + effectIds.join() + "]";
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await Effects({ effectIds: idString }).put();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    //update the effects set in state by removing deleted effects from current effects
    dispatch({
      type: UPDATE_EFFECTS,
      payload: arrayDelta(currentEffects, effectsToDelete)
    });
    dispatch({
      type: UPDATE_ALL_EFFECTS,
      payload: arrayDelta(allEffects, effectsToDelete)
    });
    dispatch({ type: SAVED_RECORD, payload: "Effects successfully deleted." });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

//authentication

export const resetSignIn = () => {
  return { type: RESET_SIGN_IN };
};

export const signedUp = email => {
  return { type: SIGNED_UP, payload: email };
};

export const signUp = () => {
  return {
    type: SIGN_UP
  };
};

export const failSignup = err => {
  return { type: FAIL_SIGNUP, payload: err };
};

export const signIn = (userId, email, authorizor, user) => {
  return {
    type: SIGN_IN,
    payload: {
      userId: userId,
      email: email,
      authorizor: authorizor,
      user: user
    }
  };
};

export const setUserFirstName = firstName => {
  return {
    type: SET_USER_FIRSTNAME,
    payload: firstName
  };
};

export const signOut = () => {
  return {
    type: SIGN_OUT
  };
};

export const failSignIn = err => {
  return { type: FAIL_SIGN_IN, payload: err };
};

export const setResetUsername = uname => {
  return {
    type: SET_RESET_USERNAME,
    payload: uname
  };
};

//API
export const busySaving = () => {
  return { type: BUSY_SAVING };
};

export const saveSuccess = message => {
  return { type: SAVED_RECORD, payload: message };
};

export const saveFailed = err => {
  return { type: SAVE_FAILED, payload: err };
};

export const resetSaveSuccess = () => {
  return { type: CANCEL_SAVE };
};

//Setup Costs

export const getSetupCost = setupCostId => {
  return async dispatch => {
    const response = await SetupCosts({ id: setupCostId }).get();
    dispatch({ type: SELECT_SETUPCOST, payload: response.data[0] });
  };
};

export const updateSelectedSetupCostState = setupCost => {
  return { type: SELECT_SETUPCOST, payload: setupCost };
};

export const clearSetupCost = () => {
  return { type: CLEAR_SETUPCOST };
};

export const setSetupCostsFilter = filter => {
  return {
    type: SET_SETUPCOST_FILTER,
    payload: filter
  };
};

export const clearSetupCostBatch = () => {
  return {
    type: CLEAR_SETUPCOST_BATCH
  };
};

export const selectSetupCost = setupCostData => {
  return {
    type: SELECT_SETUPCOST,
    payload: setupCostData
  };
};

export const removeSetupCostFromSelectedBatch = setupCost => {
  return {
    type: REMOVE_SETUPCOST_FROM_BATCH,
    payload: setupCost
  };
};

export const addSetupCostToSelectedBatch = id => {
  return {
    type: ADD_SETUPCOST_TO_BATCH,
    payload: id
  };
};

export const addAllSetupCostsToSelectedBatch = setupCosts => {
  return {
    type: ADD_ALL_SETUPCOSTS_TO_BATCH,
    payload: setupCosts
  };
};

export const deleteSetupCosts = (currentSetupCosts, setupCostsToDelete) => {
  //convert array to comma separated string
  const setupCostIds = setupCostsToDelete.map(setupCost => {
    return setupCost.id;
  });
  const idString = "[" + setupCostIds.join() + "]";
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await SetupCosts({ setupCostIds: idString }).put();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    //update the setupCosts set in state by removing delete setupCosts from current setupCosts
    dispatch({
      type: UPDATE_SETUPCOSTS,
      payload: arrayDelta(currentSetupCosts, setupCostsToDelete)
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "Setup cost records successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const deleteSetupCost = setupCost => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await SetupCosts({ id: setupCost.id }).delete();
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: DELETE_SETUPCOST,
      payload: setupCost
    });
    dispatch({
      type: SAVED_RECORD,
      payload: "Setup costs record successfully deleted."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const patchSetupCostInDb = setupCost => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await SetupCosts({ id: setupCost.id }).patch("", setupCost);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Setup cost record updated successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
  };
};

export const updateSetupCostsState = setupCosts => {
  return {
    type: UPDATE_SETUPCOSTS,
    payload: setupCosts
  };
};

export const updateAllSetupCostsState = setupCosts => {
  return {
    type: UPDATE_ALL_SETUPCOSTS,
    payload: setupCosts
  };
};

export const getSetupCosts = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await SetupCosts(params).get();
    dispatch({ type: GET_SETUPCOSTS, payload: response.data });
    dispatch({
      type: CLONE_SETUPCOSTS,
      payload: response.data
    });

    dispatch({ type: DATA_LOADED });
  };
};

export const clearSetupCosts = () => {
  return { type: CLEAR_SETUPCOSTS };
};

export const postSetupCostToDb = setupCost => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let response = {};
    try {
      response = await SetupCosts({}).post("", setupCost);
    } catch (e) {
      if (response.status !== 200) {
        console.log(e);
        dispatch({ type: SAVE_FAILED, payload: e });
      }
    }
    dispatch({
      type: SAVED_RECORD,
      payload: "Setup cost record saved successfully."
    });
    setTimeout(() => {
      dispatch({ type: CANCEL_SAVE });
    }, 2000);
    return response;
  };
};

//refPanel

export const addAllReferenceQuantitiesToSelectedBatch = quantities => {
  return {
    type: ADD_ALL_REFERENCEQUANTITIES_TO_BATCH,
    payload: quantities
  };
};

export const addReferenceQuantityToSelectedBatch = id => {
  return {
    type: ADD_REFERENCEQUANTITY_TO_BATCH,
    payload: id
  };
};

export const removeReferenceQuantityFromSelectedBatch = quantity => {
  return {
    type: REMOVE_REFERENCEQUANTITY_FROM_BATCH,
    payload: quantity
  };
};

export const selectReferenceQuantity = refQuantityData => {
  return {
    type: SELECT_REFERENCEQUANTITY,
    payload: refQuantityData
  };
};

export const getReferencePanel = () => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await ReferencePanel({}).get();
    dispatch({ type: GET_REFERENCEPANEL, payload: response.data });
    dispatch({ type: GET_REFERENCEQUANTITIES, payload: response.data.quantities });
    dispatch({
      type: CLONE_REFERENCEQUANTITIES,
      payload: response.data.quantities
    });
    dispatch({ type: DATA_LOADED });
  };
};

export const setReferenceQuantitiesFilter = filter => {
  return {
    type: SET_REFERENCEQUANTITY_FILTER,
    payload: filter
  };
};

export const setReferenceQuantityFilterLabel = label => {
  return {
    type: SET_REFERENCEQUANTITY_FILTER_LABEL,
    payload: label
  };
};

export const clearReferenceQuantity = () => {
  return { type: CLEAR_REFERENCEQUANTITY };
};

export const clearReferenceQuantityBatch = () => {
  return { type: CLEAR_REFERENCEQUANTITY_BATCH };
};

export const patchReferencePanelInDb = refPanel => {
  return async () => {
    let response = {};
    try {
      response = await ReferencePanel({ id: refPanel.id }).patch("", refPanel);
    } catch (e) {
      return e;
    }
    return response;
  };
};

export const updateReferencePanelState = refPanel => {
  return { type: GET_REFERENCEPANEL, payload: refPanel };
};

export const updateReferenceQuantitiesState = refQuantities => {
  return {
    type: GET_REFERENCEQUANTITIES,
    payload: refQuantities
  };
};

export const updateAllReferenceQuantitiesState = refQuantities => {
  return {
    type: UPDATE_ALL_REFERENCEQUANTITIES,
    payload: refQuantities
  };
};

export const setReferenceQuantitySearchTerm = term => {
  return {
    type: SET_REFERENCEQUANTITY_SEARCH_TERM,
    payload: term
  };
};

//Settings
export const getSettings = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await Settings(params).get();
    dispatch({ type: GET_SETTINGS, payload: response.data[0] });
    dispatch({ type: DATA_LOADED });
  };
};

//user payment transactions

export const getPayTransactions = email => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await PaymentTransactions(email).get();
    dispatch({ type: GET_PAYMENT_TRANSACTIONS, payload: response.data.data });
    dispatch({
      type: CLONE_PAYMENT_TRANSACTIONS,
      payload: response.data.data
    });
    dispatch({ type: DATA_LOADED });
  };
};

export const resetPopUploadAction = () => {
  return async dispatch => {
    dispatch({
      type: CANCEL_SAVE
    });
  };
};

export const getPopSignedURL = async (fileName, fileType, action) => {
  let _fileData = { s3Action: action, filename: fileName, fileType: fileType };
  let _signedUrl = await SignedURL().post("", _fileData);
  return _signedUrl.data;
};

export const getPopSignedUrlAndUpload = (fileName, file, tx, validdays, payTransactions) => {
  return async dispatch => {
    dispatch({ type: BUSY_SAVING });
    let _signedUrl = await getPopSignedURL(fileName, file.type, "putObject");
    fetch(_signedUrl.uploadUrl, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type
      }
    }).then(async result => {
      if (!result.ok) {
        const e = new Error("Could not Upload the file to the server");
        dispatch({ type: SAVE_FAILED, payload: e });
        return e;
      } else {
        await activateCreditsInDb(tx, validdays, fileName).then(result => {
          if (result.isAxiosError) {
            console.log(result);
            dispatch({
              type: SAVE_FAILED,
              payload: `${result.message}. Could not allocate transaction and update credit balance.`
            });
          } else {
            dispatch({
              type: SAVED_RECORD,
              payload: "File successfully uploaded to server."
            });
            let _payTxs = [...payTransactions];
            selectPayTransaction(result.data);
            dispatch(updatePayTransactionsState(replaceArrayObjectByKey(_payTxs, "id", result.data)));
          }
        });
      }
    });
  };
};

export const activateCreditsInDb = async (tx, validdays, fileName) => {
  let data = { validdays: validdays, balance: tx.CREDITS, popfilename: fileName };
  let response = {};
  try {
    response = await ActivateCredits(tx.id).patch("", data);
  } catch (e) {
    return e;
  }
  return response;
};

export const clearPayTransactions = () => {
  return { type: CLEAR_PAYMENT_TRANSACTIONS };
};

export const updatePayTransactionsState = payTransactions => {
  return {
    type: GET_PAYMENT_TRANSACTIONS,
    payload: payTransactions
  };
};

export const selectPayTransaction = payTransactionData => {
  return {
    type: SELECT_PAYMENT_TRANSACTION,
    payload: payTransactionData
  };
};

export const setPayTransactionsFilter = filter => {
  return {
    type: SET_PAYMENT_TRANSACTION_FILTER,
    payload: filter
  };
};

export const setPayTransactionSearchTerm = term => {
  return {
    type: SET_PAYMENT_TRANSACTION_SEARCH_TERM,
    payload: term
  };
};

export const clearPayTransaction = () => {
  return { type: CLEAR_PAYMENT_TRANSACTION };
};

export const getAccountUsers = params => {
  return async dispatch => {
    dispatch({ type: LOADING_DATA });
    const response = await Users(params).get();
    dispatch({ type: SELECT_ACCOUNT_USERS, payload: response.data });
    dispatch({ type: DATA_LOADED });
  };
};
