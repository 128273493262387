import Axios from "axios";
import { env } from "./env";

export const ActivityConsumables = ({
  id,
  activityconsumableIds,
  scopeId,
  stageId,
}) => {
  const _env = env();
  const baseURL = _env.BASEURL;
  const key = _env.API_KEY;

  if (id) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/activityconsumables/${id}`,
      headers: { "x-api-key": key },
    });
  } else if (activityconsumableIds) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/activityconsumables?ids=${activityconsumableIds}`,
      headers: { "x-api-key": key },
    });
  } else if (scopeId) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/activityconsumables?scopeId=${scopeId}`,
      headers: { "x-api-key": key },
    });
  } else if (stageId) {
    return Axios.create({
      baseURL: `${baseURL}/repairs/activityconsumables?stageId=${stageId}`,
      headers: { "x-api-key": key },
    });
  }

  return Axios.create({
    baseURL: `${baseURL}/repairs/activityconsumables`,
    headers: { "x-api-key": key },
  });
};

export const compileDataForSave = (
  { consumable, SOR, stage, price },
  id,
  state
) => {
  const activityconsumable = {};
  activityconsumable.id = id;
  activityconsumable.consumableId = consumable.value
    ? consumable.value
    : consumable;
  activityconsumable.scopeId = SOR.value ? SOR.value : SOR;
  activityconsumable.stageId = stage.value ? stage.value : stage;
  activityconsumable.price = parseFloat(price);
  activityconsumable.active = state;
  return activityconsumable;
};