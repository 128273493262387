import React from "react";
import "../components.css";
import {
  clearToner,
  setTonersFilter,
  clearTonerBatch,
  selectToner,
  removeTonerFromSelectedBatch,
  addTonerToSelectedBatch,
  addAllTonersToSelectedBatch,
  deleteToners,
  deleteToner,
  patchTonerInDb,
  updateTonersState,
  updateAllTonersState,
  getToners,
  clearToners,
  getSuppliers,
  setTonerSearchTerm,
  getManufacturers,
  deleteTonerBySkey,
  setTonersWithSelectedId,
  getTonerByKey
} from "../../actions";
import { connect } from "react-redux";
import _ from "lodash";
import { SAVE, EDIT, NEW, DELETE } from "../../actions/types";
import { Link } from "react-router-dom";
import { Button, Confirm } from "semantic-ui-react";
import history from "../../history";
import DisplayTable from "../displayTable";
import { isMobile } from "react-device-detect";
import {
  selectOptionsFromData,
  removeObjectFromArrayBySKey,
  replaceArrayObjectByKey,
  currentDateAsInt,
  removeObjectsFromArrayBySKeys,
  filterArray,
  arrayDelta
} from "../../utilities";
import { SET_TONER_FILTER_LABEL } from "../../actions/types";
import { toastr } from "react-redux-toastr";
import { Fragment } from "react";

class Toners extends React.Component {
  state = {
    confirmDeleteOpen: false
  };
  componentDidMount() {
    this.props.getSuppliers({});
    if (this.props.manufacturers.length === 0) {
      this.props.getManufacturers({});
    }
  }

  onTonersSearchTermChange = term => {
    const {
      setTonerSearchTerm,
      clearToner,
      getToners,
      tonerSearchTerms
    } = this.props;
    if (term.value !== "") {
      setTonerSearchTerm(term);
      clearToner();
      if (term.value) {
        getToners({
          supplierId: term.value,
          name: tonerSearchTerms.filter.value.toLowerCase()
        });
      }
    }
  };

  onTonersFilterChange = filter => {

    const {
      clearToner,
      tonerSearchTerms,
      setTonersFilter,
      updateTonersState,
      allToners,
    } = this.props;
  
    const _newfilter = filter === "#clear#" ? "" : tonerSearchTerms.filter.label;

    if (_newfilter !== tonerSearchTerms.filter.value) {
      clearToner();

      if (_newfilter !== "") {
        updateTonersState(filterArray(allToners, "l_name", _newfilter));
      } else {
        updateTonersState(allToners);
      }
    }
    setTonersFilter(_newfilter);
  };

  onSelectToner = skey => {
    const {
      selectToner,
      toners,
      clearTonerBatch,
      setTonersWithSelectedId
    } = this.props;
    const _toner = toners.find(toner => {
      return toner.skey === skey;
    });
    selectToner(_toner);
    //set toners with selected id - required for managing date driven records
    setTonersWithSelectedId(
      toners.filter(toner => {
        return toner.id === _toner.id;
      })
    );
    clearTonerBatch();
  };

  onAddTonerToBatch = toner => {
    if (this.props.tonerBatch.find(item => item === toner)) {
      this.props.removeTonerFromSelectedBatch(toner);
    } else {
      this.props.addTonerToSelectedBatch(toner);
    }
  };

  onAddAllTonersToSelectedBatch = () => {
    if (this.props.tonerBatch.length === this.props.toners.length) {
      this.props.clearTonerBatch();
    } else {
      this.props.addAllTonersToSelectedBatch(this.props.toners);
    }
  };

  onBatchDeleteToners = () => {
    const {
      toners,
      tonerBatch,
      deleteToners,
      clearTonerBatch,
      clearToner,
      updateTonersState,
      updateAllTonersState,
      allToners
    } = this.props;
    if (tonerBatch.length > 0) {
      deleteToners(tonerBatch);
    }
    clearTonerBatch();
    clearToner();
    updateTonersState(arrayDelta(toners, tonerBatch));
    updateAllTonersState(arrayDelta(allToners, tonerBatch));
  };

  onDeleteToner = () => {
    const {
      clearToner,
      updateTonersState,
      deleteToner,
      toners,
      deleteTonerBySkey,
      patchTonerInDb,
      getTonerByKey,
      selectedToner
    } = this.props;
    //refresh toner to get up to date data for checks.
    getTonerByKey(selectedToner.skey).then((result) => {
      console.log(result);
      const _today = currentDateAsInt();
      let _toners = [];
      let _toner = result;
      if (_toner.usedByColorCount > 0) {
        toastr.error(
          "This toner is being used in a paint formula. To delete this toner the toner must be deleted from all paint formulas first",
          { timeOut: 7500 }
        );
      } else {
        //*check of selected record future dated
        if (_toner.startdate > _today) {
          deleteTonerBySkey(_toner.skey);
          //*get the current active toner with same id.
          let _activeToner = toners.find(toner => {
            return (
              toner.id === _toner.id &&
              toner.startdate <= _today &&
              (toner.enddate === undefined ||
                toner.enddate === null ||
                toner.enddate >= _today)
            );
          });
          //*remove enddate of current record to set active
          delete _activeToner.enddate;
          patchTonerInDb(_activeToner.skey, _activeToner);

          //*remove deleted toner from set
          _toners = removeObjectFromArrayBySKey(toners, _toner);
          clearToner();
          updateTonersState(replaceArrayObjectByKey(_toners, "skey", _activeToner));
        } else {
          //* get all toner skeys for same id
          _toners = toners.filter(toner => {
            return toner.id === _toner.id;
          });
          //*get all toner ids and turn into comma separated string
          let _tonerkeys = [];
          _toners.forEach(toner => {
            _tonerkeys.push(toner.skey);
          });
          //* batch delete
          deleteToner(_tonerkeys.join(","));
          //remove all toners with id from state
          updateTonersState(removeObjectsFromArrayBySKeys(toners, _tonerkeys));
        }
      }
    });
  };

  updateToner = toner => {
    const { patchTonerInDb } = this.props;
    patchTonerInDb(toner.skey, toner);
  };

  //called from tables main menu to do ad-hoc save.
  onSaveChanges = () => {
    const _toner = { ...this.props.selectedToner };
    if (!_.isEmpty(_toner)) {
      this.updateToner(_toner);
    }
  };

  convertFormulaData = data => {
    return data.map(item => {
      let _item = {};
      _item.Color = item.name;
      _item.Code = item.code;
      _item.Manufacturer = this.props.manufacturers.find(manufacturer => {
        return manufacturer.id === item.manufacturerId;
      }).name;
      return _item;
    });
  };

  renderTonersTable() {
    const menuItems = [
      {
        name: "Save Changes",
        key: SAVE,
        icon: "save",
        onClick: this.onSaveChanges,
        type: Button,
        disabled: this.props.toners.length === 0
      },
      {
        name: "New Toner",
        key: NEW,
        icon: "add square",
        to: `/toners/create/${this.props.tonerSearchTerms.term.value}`,
        type: Link,
        disabled: this.props.tonerSearchTerms.term.value === ""
      }
    ];

    const itemMenuActions = [
      {
        key: EDIT,
        text: "Edit Toner",
        value: EDIT,
        icon: { name: "edit", color: "green" },
        idx: 0,
        executeAction: ({ itemId }) => {
          history.push(`/toners/edit/${itemId}`);
        }
      },
      {
        key: DELETE,
        text: "Delete",
        value: DELETE,
        icon: { name: "delete", color: "red" },
        idx: 1,
        executeAction: () => {
          this.setState({ confirmDeleteOpen: true });
        }
      }
    ];

    const tableMenuActions = [
      // {
      //   key: DELETE,
      //   confirmation: 1,
      //   text: "Delete",
      //   value: DELETE,
      //   icon: { name: "remove", color: "red" },
      //   idx: 0,
      //   action: this.onBatchDeleteToners,
      //   header: "Confirm Delete"
      // }
    ];

    const gridColumns = "six";
    const tableColumns = [
      {
        width: "two",
        heading: "Name",
        displayValues: [{ columnName: "name" }]
      },
      {
        width: "two",
        heading: "Price",
        displayValues: [{ columnName: "price" }],
        type: "Currency"
      },
      {
        width: "two",
        heading: "Paint Formulas Using Toner",
        displayValues: [{ columnName: "usedByColorCount" }],
        type: "Integer",
        detailField: "usedByColors",
        convertMethod: this.convertFormulaData
      },
      {
        width: "one",
        heading: "CPG",
        displayValues: [{ columnName: "costpergram" }],
        type: "Float"
      },
      {
        width: "two",
        heading: "Start",
        displayValues: [{ columnName: "startdate" }],
        type: "Date"
      },
      {
        width: "two",
        heading: "End",
        displayValues: [{ columnName: "enddate" }],
        type: "Date"
      }
    ];

    const primaryColumn = {
      heading: "Code",
      width: "two",
      displayValues: [{ columnName: "code" }]
    };

    const copyConfig = {
      active: false
    };

    return (
      <Fragment>
        <DisplayTable
          idFieldName="skey"
          primaryHeading="Toners"
          items={this.props.toners}
          selectedItemId={this.props.selectedToner.id}
          menuItems={menuItems}
          tableMenuActions={tableMenuActions}
          itemMenuActions={itemMenuActions}
          showSearch={1}
          showSearchFilter={true}
          searchTerms={this.props.tonerSearchTerms}
          onSelectItem={this.onSelectToner}
          onSearchTermChange={this.onTonersSearchTermChange}
          onFilterChange={this.onTonersFilterChange}
          filterAction={SET_TONER_FILTER_LABEL}
          searchBarOptions={selectOptionsFromData(this.props.suppliers)}
          searchBarPlaceholder="Select Supplier"
          batchItems={this.props.tonerBatch}
          onAddItemToBatch={this.onAddTonerToBatch}
          onAddAllItemsToBatch={this.onAddAllTonersToSelectedBatch}
          primaryColumn={primaryColumn}
          gridColumns={gridColumns}
          tableColumns={tableColumns}
          loading={this.props.loadingData}
          copyItemsConfig={copyConfig}
        />
      </Fragment>
    );
  }

  renderDeleteConfirm() {
    return (
      <Confirm
        open={this.state.confirmDeleteOpen}
        header="Item Actions"
        content={`Confirm Delete?`}
        onCancel={() => {
          this.setState({ confirmDeleteOpen: false });
        }}
        onConfirm={() => {
          this.setState({ confirmDeleteOpen: false });
          this.onDeleteToner();
        }}
      />
    );
  }

  render() {
    const className = `ui ${isMobile ? "" : "very padded"} segment`;
    return (
      <Fragment>
        <div className={className}>
          <div>
            {this.renderTonersTable()}
          </div>
          {this.renderDeleteConfirm()}
          <div className="spacer-very-wide" />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    suppliers: state.toners.suppliers,
    tonerSearchTerms: state.toners.tonerSearchTerms,
    toners: state.toners.toners,
    allToners: state.toners.allToners,
    tonerBatch: state.toners.tonerBatch,
    selectedToner: state.toners.selectedToner,
    loadingData: state.api.loadingData,
    manufacturers: state.manufacturers.manufacturers
  };
};

export default connect(mapStateToProps, {
  clearToner,
  clearToners,
  setTonersFilter,
  clearTonerBatch,
  selectToner,
  removeTonerFromSelectedBatch,
  addTonerToSelectedBatch,
  addAllTonersToSelectedBatch,
  deleteToners,
  deleteToner,
  patchTonerInDb,
  updateTonersState,
  updateAllTonersState,
  getToners,
  getSuppliers,
  setTonerSearchTerm,
  getManufacturers,
  deleteTonerBySkey,
  setTonersWithSelectedId,
  getTonerByKey
})(Toners);
