const _env = "prod";

const _variables = {};

export const env = () => {
  _variables.ENV = _env;
  _variables.BASEURL = process.env.REACT_APP_BASEURL;
  _variables.BASEURL_X = process.env.REACT_APP_BASEURL_X;
  _variables.API_KEY = process.env.REACT_APP_API_KEY;
  _variables.API_X_KEY = process.env.REACT_APP_API_X_KEY;
  _variables.CLIENT_API_URL = process.env.REACT_APP_CLIENT_API_URL;
  _variables.CLIENT_API_KEY = process.env.REACT_APP_CLIENT_API_KEY;
  _variables.COGNITO_APP_CLIENT_ID = process.env.REACT_APP_COGNITO_APP_CLIENT_ID;
  _variables.COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;
  _variables.TTL_MINS = process.env.REACT_APP_TTL_MINS;
  _variables.RECORD_SET_SIZE = process.env.REACT_APP_RECORD_SET_SIZE;
  _variables.REDIRECT_TIMEOUT = process.env.REACT_APP_REDIRECT_TIMEOUT;
  _variables.VERSION = process.env.REACT_APP_VERSION;
  return _variables;
};
