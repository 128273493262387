import {
  GET_PAYMENT_TRANSACTIONS,
  SELECT_PAYMENT_TRANSACTION,
  ARCHIVE_PAYMENT_TRANSACTION,
  CLEAR_PAYMENT_TRANSACTIONS,
  CLEAR_PAYMENT_TRANSACTION,
  SET_PAYMENT_TRANSACTION_FILTER_LABEL,
  SET_PAYMENT_TRANSACTION_FILTER,
  CLONE_PAYMENT_TRANSACTIONS,
  SET_PAYMENT_TRANSACTION_SEARCH_TERM
} from "../actions/types";
import { removeObjectFromArray } from "../utilities";

const INITIAL_STATE = {
  list: [],
  all: [],
  selected: {},
  payTransactionSearchTerms: {
    filter: { value: "", label: "" },
    term: {
      value: "",
      label: "Search & Select User Email"
    }
  }
};

const payTransactionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENT_TRANSACTIONS:
      return {
        ...state,
        list: action.payload
      };
    case SET_PAYMENT_TRANSACTION_FILTER:
      return {
        ...state,
        payTransactionSearchTerms: {
          ...state.payTransactionSearchTerms,
          filter: {
            ...state.payTransactionSearchTerms.filter,
            value: action.payload
          }
        }
      };
    case SET_PAYMENT_TRANSACTION_FILTER_LABEL:
      return {
        ...state,
        payTransactionSearchTerms: {
          ...state.payTransactionSearchTerms,
          filter: {
            ...state.payTransactionSearchTerms.filter,
            label: action.payload
          }
        }
      };
    case SELECT_PAYMENT_TRANSACTION:
      return {
        ...state,
        selected: action.payload
      };
    case CLEAR_PAYMENT_TRANSACTION:
      return { ...state, selected: {} };
    case CLEAR_PAYMENT_TRANSACTIONS:
      return { ...state, payTransactions: [] };
    case ARCHIVE_PAYMENT_TRANSACTION:
      return {
        ...state,
        list: removeObjectFromArray(...state.list, action.payload)
      };
    case CLONE_PAYMENT_TRANSACTIONS:
      return {
        ...state,
        all: action.payload
      };
    case SET_PAYMENT_TRANSACTION_SEARCH_TERM:
      return {
        ...state,
        payTransactionSearchTerms: { ...state.payTransactionSearchTerms, term: action.payload }
      };
    default:
      return state;
  }
};

export default payTransactionsReducer;
